import React, {
  FC,
  FocusEvent,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Popper,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  AddModeratorOutlined,
  Apple,
  MailOutline,
  RemoveModeratorOutlined,
} from '@mui/icons-material';
import { useFormik } from 'formik';
import {
  AuthError,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  linkWithPopup,
} from 'firebase/auth';
import { enqueueSnackbar } from 'notistack';
import * as R from 'ramda';
import { SubscriptionFeature } from '@house-id/houseid-types/dist/subscriptionPlans';

import HomeLayout from '../../Property/pages/Home/components/HomeLayout';
import {
  useNavigateBackOr,
  useRouteQueryParams,
} from '../../../utils/routes';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import {
  useGetCurrentUserQuery,
  useReloadTokenDataMutation,
  useUpdateUserDetailsMutation,
} from '../api/api.user';
import HIDTextField from '../../../components/HIDTextField';
import HIDButton from '../../../components/buttons/HIDButton';
import {
  UserDetails as UserDetailsType,
  UserDetailsForm,
} from '../types/types.auth';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import {
  getActiveAuth,
  useGetActiveAuthUser,
} from '../../../external-services/firebase';
import ViewEntityActions from '../../Property/modules/Content/components/actions/ViewEntityActions';
import Google from '../../../components/icons/Google';
import {
  getUserDetailsOnboardingPath,
  getUserSettingsPath,
} from '../navigation/navigation.auth';
import InfoBox from '../../../components/InfoBox';
import HIDPhoneField, { useGetPhoneFieldValidationSchema } from '../../../components/HIDPhoneField';
import { reportError } from '../../../external-services/sentry';
import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import useGetAuthErrorMessageGetter from '../hooks/useGetAuthErrorMessageGetter';
import useGetEnrollSecondFactor, { useHasSecondFactor } from '../hooks/useGetEnrollSecondFactor';
import { ENROLL_SECOND_FACTOR_URL_PARAM } from '../constants.auth';
import { useGetSignOut } from '../hooks/useGetSignOut';
import useGetBackendErrorMessage, { ServerErrorWithMessageCode } from '../hooks/userBackendErrors';
import { hidSpacing } from '../../../utils/number';
import useTryUseFeatureDialog from '../../SubscriptionPlans/hooks/useTryUseFeatureDialog';

const removeEnrollSecondFactorUrlParam = () => {
  const newUrl = new URL(document.location.href);
  newUrl.searchParams.delete(ENROLL_SECOND_FACTOR_URL_PARAM);
  window.history.replaceState({}, document.title, newUrl.toString());
};

enum AuthProviderType {
  GOOGLE = 'google.com',
  APPLE = 'apple.com',
  PASSWORD = 'password',
}

const UserDetails: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['settings', 'forms_common', 'common', 'auth_error_messages', 'api_errors']);

  const { isDownSm, isDownMd } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: user } = useGetCurrentUserQuery();

  const { user: currentAuthUser } = useGetActiveAuthUser();

  const handleGoBack = () => navigateBackOr(propertyId ? getUserSettingsPath({ propertyId }) : getUserDetailsOnboardingPath());

  const [signOut] = useGetSignOut(getActiveAuth());

  const [updateUserDetails, { isLoading }] = useUpdateUserDetailsMutation();
  const [userAuthProviders, setUserAuthProviders] = useState<Array<string>>([]);
  const [hasErrorUpdatingAuthDetails, setHasErrorUpdatingAuthDetails] = useState<AuthError | undefined>();
  const [updatingSecondFactor, setUpdatingSecondFactor] = useState(false);

  const [autoLogoutWarningAnchorEl, setAutoLogoutWarningAnchorEl] = useState<HTMLElement | null>(null);
  const [autoLogoutWarningVisible, setAutoLogoutWarningVisible] = useState(false);
  const [autoLogoutWarningOpenCount, setAutoLogoutWarningOpenCount] = useState(0);

  const [reloadTokenData] = useReloadTokenDataMutation();

  const getAuthErrorMessage = useGetAuthErrorMessageGetter();

  const hasSecondFactor = useHasSecondFactor();

  const [updateUserError, setUpdateUserError] = useState(undefined);

  const handleAuthError = (error: AuthError) => {
    setHasErrorUpdatingAuthDetails(error);
    const errorMessage = getAuthErrorMessage(error);
    reportError(error, {
      showToaster: true,
      message: {
        title: t('api_errors:default_error_title'),
        description: errorMessage || '',
      },
    });
  };

  const backendFieldsErrors = useGetBackendErrorMessage(
    updateUserError
      ? (updateUserError as { data: { error: Array<ServerErrorWithMessageCode> } }).data
      : undefined,
  );

  const handleUpdateUserDetails = (values: UserDetailsForm) => {
    if (!user) {
      return;
    }

    setUpdateUserError(undefined);

    const changedProps = R.filter(((propName) => user[propName] !== values[propName]), R.keys(values));
    const changedValues = R.fromPairs(R.map((propName) => ([propName, values[propName]]), changedProps));

    const userDetails = {
      ...changedValues,
      phoneNumber: values.phoneNumber?.nationalNumber && values.phoneNumber?.numberValue
        ? values.phoneNumber?.numberValue
        : user.phoneNumber !== values.phoneNumber?.value
          ? null
          : undefined,
    } as UserDetailsType;

    const needToSignOut = currentAuthUser && ((userDetails.email && currentAuthUser.email !== userDetails.email)
      || (hasSecondFactor && userDetails.phoneNumber !== undefined && currentAuthUser.phoneNumber !== userDetails.phoneNumber));

    updateUserDetails(userDetails)
      .unwrap()
      .then(() => needToSignOut ? signOut() : undefined)
      .then(() => currentAuthUser?.reload())
      .then(handleGoBack)
      .catch(setUpdateUserError);
  };

  const phoneInputFieldValidationSchema = useGetPhoneFieldValidationSchema();

  const schema = Yup.object({
    email: Yup.string().email(t('forms_common:email_format_wrong')).required(t('forms_common:email_required')),
    givenName: Yup.string().required(t('forms_common:field_mandatory')),
    surname: Yup.string().required(t('forms_common:field_mandatory')),
    phoneNumber: phoneInputFieldValidationSchema,
  });

  const formik = useFormik<UserDetailsForm>({
    initialValues: {
      email: user?.email || '',
      givenName: user?.givenName || '',
      surname: user?.surname || '',
      phoneNumber: {
        value: user?.phoneNumber,
      },
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => handleUpdateUserDetails(values),
  });

  const phoneInputTouched = Boolean(formik.values.phoneNumber?.numberValue);
  const phoneInputError = (formik.errors.phoneNumber as unknown as { value: string | undefined })?.value;

  const loadAuthUserProviders = () => {
    const auth = getActiveAuth();
    const { currentUser } = auth;
    if (currentUser?.email) {
      return fetchSignInMethodsForEmail(auth, currentUser.email)
        .then(setUserAuthProviders)
        .catch((error: AuthError) => handleAuthError(error));
    }
  };

  const handleSignInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getActiveAuth();
    const { currentUser } = auth;
    if (currentUser) {
      linkWithPopup(currentUser, provider)
        .then(loadAuthUserProviders)
        .then(() => {
          enqueueSnackbar(t('settings:settings_connected_google'), { variant: 'success' });
          return reloadTokenData();
        })
        .catch((error: AuthError) => handleAuthError(error));
    }
  };

  const handleSignInWithApple = () => {
    const provider = new OAuthProvider('apple.com');
    const auth = getActiveAuth();
    const { currentUser } = auth;
    if (currentUser) {
      linkWithPopup(currentUser, provider)
        .then(loadAuthUserProviders)
        .then(() => {
          enqueueSnackbar(t('settings:settings_connected_apple'), { variant: 'success' });
          return reloadTokenData();
        })
        .catch((error: AuthError) => handleAuthError(error));
    }
  };

  const handleSignInWithEmail = (email: string, password: string) => {
    const credential = EmailAuthProvider.credential(email, password);
    const auth = getActiveAuth();
    const { currentUser } = auth;
    if (currentUser) {
      linkWithCredential(currentUser, credential)
        .then(loadAuthUserProviders)
        .then(() => {
          enqueueSnackbar(t('settings:settings_connected_email'), { variant: 'success' });
          return reloadTokenData();
        })
        .catch((error: AuthError) => handleAuthError(error));
    }
  };

  const [openAddPasswordAuthToAccountDialog] = useDialog(
    DialogNames.ADD_PASSWORD_AUTH_TO_ACCOUNT_DIALOG,
  );

  const handleOpenAddPasswordAuthToAccountDialog = () => {
    openAddPasswordAuthToAccountDialog({
      onCredentialsAdded: handleSignInWithEmail,
    });
  };

  useEffect(() => {
    const auth = getActiveAuth();
    const { currentUser } = auth;
    if (currentUser?.email) {
      fetchSignInMethodsForEmail(auth, currentUser.email)
        .then(setUserAuthProviders)
        .catch((error: AuthError) => handleAuthError(error));
    }
  }, []);

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.TWO_FACTOR_AUTHENTICATION });

  const [openEnrollSecondFactorDialog] = useDialog(DialogNames.ENROLL_SECOND_FACTOR_DIALOG);
  const tryOpenEnrollSecondFactorDialog = () => proceedToFeatureOrOpenSubscriptionDialog({ onAction: openEnrollSecondFactorDialog });

  const { [ENROLL_SECOND_FACTOR_URL_PARAM]: enrollSecondFactor } = useRouteQueryParams<{ [ENROLL_SECOND_FACTOR_URL_PARAM]?: boolean }>();

  useEffect(() => {
    if (enrollSecondFactor) {
      tryOpenEnrollSecondFactorDialog();
      removeEnrollSecondFactorUrlParam();
    }
  }, [enrollSecondFactor]);

  const { removeSecondFactor } = useGetEnrollSecondFactor();

  const handleAddSecondFactor = tryOpenEnrollSecondFactorDialog;

  const handleRemoveSecondFactor = () => {
    setUpdatingSecondFactor(true);

    removeSecondFactor()
      ?.then(() => {
        enqueueSnackbar(
          t('settings:settings_the_2_step_verification_is_removed'),
          { variant: 'success' },
        );
      })
      ?.then(() => signOut())
      ?.then(() => currentAuthUser?.reload())
      ?.catch((error: AuthError) => handleAuthError(error))
      ?.finally(() => {
        currentAuthUser?.reload();
        setUpdatingSecondFactor(false);
      });
  };

  const authProviderActions = [
    {
      id: AuthProviderType.GOOGLE,
      disabled: !userAuthProviders.length,
      label: t('settings:settings_connect_google'),
      Icon: Google,
      onClick: handleSignInWithGoogle,
    },
    {
      id: AuthProviderType.APPLE,
      disabled: !userAuthProviders.length,
      label: t('settings:settings_connect_apple'),
      Icon: Apple,
      onClick: handleSignInWithApple,
    },
    {
      id: AuthProviderType.PASSWORD,
      disabled: !userAuthProviders.length,
      label: t('settings:settings_connect_email'),
      Icon: MailOutline,
      onClick: handleOpenAddPasswordAuthToAccountDialog,
    },
  ].filter(({ id }) => !userAuthProviders.includes(id));

  const customActions = [
    ...authProviderActions,
    {
      id: 'second_factor',
      disabled: updatingSecondFactor,
      label: hasSecondFactor ? t('settings:settings_remove_second_factor') : t('settings:settings_add_second_factor'),
      Icon: hasSecondFactor ? RemoveModeratorOutlined : AddModeratorOutlined,
      onClick: hasSecondFactor ? handleRemoveSecondFactor : handleAddSecondFactor,
    },
  ];

  const handleEmailInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (!autoLogoutWarningAnchorEl) {
      setAutoLogoutWarningAnchorEl(event.currentTarget);
    }
    setAutoLogoutWarningOpenCount((prev) => prev + 1);
    setAutoLogoutWarningVisible(true);
  };

  const handleEmailInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    formik.handleBlur('email')(event);
    setAutoLogoutWarningVisible(false);
  };

  return (
    <HomeLayout
      SideColumn={
        customActions.length
          ? (
            <Card sx={{ padding: 2 }}>
              <ViewEntityActions customActions={customActions} />
              {hasErrorUpdatingAuthDetails && (
                <InfoBox message={t('settings:settings_update_auth_tip')} />
              )}
            </Card>)
          : undefined
      }
      sideDrawerElements={
        [
          <ViewEntityActions customActions={customActions} key={ViewEntityActions.name} />,
          hasErrorUpdatingAuthDetails && (
            <InfoBox key={InfoBox.name} message={t('settings:settings_update_auth_tip')} />
          ),
        ].filter(Boolean)
      }
      title={t('settings:settings_my_details')}
      onBack={handleGoBack}
    >
      <Stack direction="column" spacing={3}>
        <HIDTextField
          required
          showValidationIcon
          error={Boolean((formik.touched.givenName && formik.errors.givenName) || backendFieldsErrors.givenName)}
          helperText={formik.touched.givenName ? formik.errors.givenName || backendFieldsErrors.givenName : undefined}
          id="givenName"
          label={t('forms_common:given_name_title')}
          valid={Boolean(formik.touched.givenName && !formik.errors.givenName)}
          value={formik.values.givenName}
          onBlur={formik.handleBlur('givenName')}
          onChange={({ target }) => formik.setFieldValue('givenName', target.value)}
        />
        <HIDTextField
          required
          showValidationIcon
          error={Boolean((formik.touched.surname && formik.errors.surname) || backendFieldsErrors.surname)}
          helperText={formik.touched.surname ? formik.errors.surname || backendFieldsErrors.surname : undefined}
          id="surname"
          label={t('forms_common:surname_title')}
          valid={Boolean(formik.touched.surname && !formik.errors.surname)}
          value={formik.values.surname}
          onBlur={formik.handleBlur('surname')}
          onChange={({ target }) => formik.setFieldValue('surname', target.value)}
        />
        {hasSecondFactor && (
          <InfoBox message={t('settings:settings_changing_email_or_will_remove_2fa')} />
        )}
        <HIDPhoneField
          showValidationIcon
          error={Boolean((phoneInputTouched && phoneInputError) || backendFieldsErrors.phoneNumber)}
          helperText={phoneInputTouched ? phoneInputError || backendFieldsErrors.phoneNumber : undefined}
          id="phoneNumber"
          label={t('forms_common:phone_title')}
          valid={Boolean(phoneInputTouched && !phoneInputError)}
          value={formik.values.phoneNumber}
          variant="standard"
          onBlur={formik.handleBlur('phoneNumber')}
          onChange={(value) => formik.setFieldValue('phoneNumber', value)}
        />
        <Popper
          disablePortal
          anchorEl={autoLogoutWarningAnchorEl}
          open={autoLogoutWarningVisible}
          placement="top"
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                // NOTE: for some reason popper shifts left by 24px after first opening
                options: { offset: [autoLogoutWarningOpenCount > 1 ? hidSpacing(3) : 0, hidSpacing(3)] },
              },
              {
                name: 'flip',
                enabled: false,
              },
            ],
          }}
          slotProps={{
            root: {
              style: {
                width: isDownSm ? '90%' : isDownMd ? '75%' : '50%',
                zIndex: 1,
                margin: 0,
                padding: 0,
              },
            },
          }}
        >
          <InfoBox message={t('settings:settings_warning_about_auto_log_out_after_email_change')} />
        </Popper>
        <HIDTextField
          required
          showValidationIcon
          error={Boolean(formik.touched.email && formik.errors.email) || Boolean(backendFieldsErrors?.email)}
          helperText={(formik.values.email || formik.touched.email) ? formik.errors.email || backendFieldsErrors?.email : undefined}
          id="email"
          label={t('forms_common:email_title')}
          type="email"
          valid={Boolean(formik.touched.email && !formik.errors.email)}
          value={formik.values.email}
          onBlur={handleEmailInputBlur}
          onChange={({ target }) => formik.setFieldValue('email', target.value)}
          onFocus={handleEmailInputFocus}
        />
        <Stack
          direction={isDownSm ? 'column' : 'row'}
          justifyContent={isDownSm ? 'center' : 'flex-end'}
          spacing={isDownSm ? 2.5 : 2}
        >
          <HIDButton
            color="secondary"
            disabled={isLoading}
            onClick={handleGoBack}
          >
            {t('common:cancel')}
          </HIDButton>
          <HIDButton
            disabled={!formik.isValid || isLoading}
            loading={isLoading}
            onClick={() => formik.submitForm()}
          >
            {t('common:save')}
          </HIDButton>
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default UserDetails;
