import React, { FC } from 'react';
import {
  Badge,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import HIDClickable from '../../../../../components/HIDClickable';
import HIDImage from '../../../../../components/image/HIDImage';
import { Icon } from '../../../../../types/common';

type SellingPropertyTasksListItemProps = {
  name: string;
  Icon?: Icon;
  iconUrl?: string;
  index: number;
  onClick: () => void;
};

const SellingPropertyTasksListItem: FC<SellingPropertyTasksListItemProps> = ({
  name,
  Icon,
  iconUrl,
  index,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <HIDClickable
      alignItems="center"
      direction="row"
      spacing={2.5}
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        padding: theme.spacing(2, 2, 1.5),
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        height: '100%',
      }}
      onClick={onClick}
    >
      <Stack
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.info.lightest,
          borderRadius: '5px',
          padding: 1.45,
        }}
      >
        <Badge
          badgeContent={index + 1}
          color="error"
          componentsProps={{
            badge: {
              style: {
                borderRadius: '2px',
              },
            },
          }}
          sx={{
            left: '2px',
            top: '2px',
            position: 'absolute',
          }}
        />
        {
          Icon
            ? <Icon size={32} />
            : iconUrl
              ? <HIDImage externalUrl={iconUrl} />
              : null
        }
      </Stack>
      <Typography sx={{ flex: 1 }} variant="body1">
        {name}
      </Typography>
      <ChevronRight />
    </HIDClickable>
  );
};

export default SellingPropertyTasksListItem;
