import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import {
  SubscriptionInfo,
  SubscriptionPlatform,
  SubscriptionState,
  SubscriptionType,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import { QueryHook } from '../../../types/common';
import { useGetActiveAuthUser } from '../../../external-services/firebase';
import { useGetSubscriptionQuery } from '../api/api.subscriptionPlans';

type CurrentUserSubscriptionInfo = {
  hasAnyIapOwnSubscription: boolean;
  hasActiveWebOwnSubscription: boolean;
  hasActiveIapOwnSubscription: boolean;
} & SubscriptionInfo;

const useGetCurrentUserSubscriptionInfo: QueryHook<CurrentUserSubscriptionInfo | undefined> = (_args, options) => {
  const { user: currentUser } = useGetActiveAuthUser();

  const {
    data: subscriptionInfo,
    ...rest
  } = useGetSubscriptionQuery(currentUser && !options?.skip ? undefined : skipToken);

  const data = useMemo(() => {
    if (subscriptionInfo) {
      if (subscriptionInfo.subscriptions?.length) {
        const hasActiveWebOwnSubscription = subscriptionInfo.subscriptions
          .some(({ type, platform, state }) => type === SubscriptionType.MDD
            && platform === SubscriptionPlatform.WEB
            && state === SubscriptionState.ACTIVE);

        const hasActiveIapOwnSubscription = subscriptionInfo.subscriptions
          .some(({ type, platform, state }) => type === SubscriptionType.IAP
            && platform !== SubscriptionPlatform.WEB
            && state === SubscriptionState.ACTIVE);

        const hasAnyIapOwnSubscription = subscriptionInfo.subscriptions
          .some(({ type, platform }) => type === SubscriptionType.IAP
            && platform !== SubscriptionPlatform.WEB);

        return {
          hasAnyIapOwnSubscription,
          hasActiveWebOwnSubscription,
          hasActiveIapOwnSubscription,
          ...subscriptionInfo,
        };
      }

      return {
        hasAnyIapOwnSubscription: false,
        hasActiveWebOwnSubscription: false,
        hasActiveIapOwnSubscription: false,
        ...subscriptionInfo,
      };
    }

    return undefined;
  }, [subscriptionInfo]);

  return {
    data,
    ...rest,
  };
};

export default useGetCurrentUserSubscriptionInfo;
