import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeductionCalculationResult,
} from '@house-id/houseid-types/dist/deductions';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Card,
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { useGetDeductionsTypesQuery } from '../api/api.deductions';
import { FCC } from '../../../../../../../../../types/common';
import { formatMoney } from '../../../../../../../../../utils/string';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import DeclarationTable from '../../../components/DeclarationTable';
import useGetDeductionAmountTypeLabel from '../hooks/useGetDeductionAmountTypeLabel';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDInfo from '../../../../../../../../../components/HIDInfo';

export type DeductionCalculationResultCardProps = {
  deductionTypeId: string;
  totalAmount: number;
  result?: DeductionCalculationResult;
  isLoading?: boolean;
};

const DeductionCalculationResultCard: FCC<DeductionCalculationResultCardProps> = ({
  deductionTypeId,
  totalAmount,
  result,
  isLoading,
  style,
}) => {
  const theme = useTheme();

  const { t } = useTranslation(['forms_common', 'declarations', 'deductions', 'finances']);
  const getDeductionResultTypeLabel = useGetDeductionAmountTypeLabel();

  const totalCost = totalAmount || 0;
  const totalCostWithCurrency = formatMoney(totalCost);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: deductionTypes } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const deductionType = deductionTypes?.find((type) => type.id === deductionTypeId);

  const overviewItems = result
    ? [
      deductionType && {
        id: 'deductionType',
        label: t('deductions:measure'),
        value: deductionType.name,
      },
      {
        id: 'cost',
        label: t('finances:cost'),
        value: totalCost,
        isImportant: true,
      },
      ...(result?.deductionAmounts.map((result) => ({
        id: result.type as string,
        label: t(
          'deductions:deductions_result_total_per_type',
          { type: getDeductionResultTypeLabel(result.type)?.toLowerCase() || '' },
        ),
        value: result.amount.amount || 0,
        isImportant: true,
      })) || []),
    ].filter(Boolean)
    : [];

  const resultItems = result?.deductionAmounts.map((result) => ({
    id: result.type,
    label: getDeductionResultTypeLabel(result.type),
    value: result.amount.amount || 0,
  })) || [];

  return (
    <Stack gap={2.5} style={style}>
      <DeclarationTable
        items={overviewItems}
        loading={isLoading}
      />
      <Card>
        <Stack
          gap={1}
          style={{
            padding: theme.spacing(2),
            backgroundColor: theme.palette.grey[200],
            borderBottomLeftRadius: BorderRadius.md,
            borderBottomRightRadius: BorderRadius.md,
          }}
        >
          <HIDTypography isLoading={isLoading} textAlign="center" variant="h5">
            {totalCostWithCurrency}
          </HIDTypography>
          <HIDTypography textAlign="center">
            {t('forms_common:total')}
          </HIDTypography>
        </Stack>
      </Card>
      <Card>
        <Stack
          gap={1}
          sx={{
            padding: theme.spacing(2),
            backgroundColor: theme.palette.grey[200],
            borderBottomLeftRadius: BorderRadius.md,
            borderBottomRightRadius: BorderRadius.md,
          }}
        >
          <HIDInfo
            description={t('declarations:declaration_help_summary_deduction_section_title')}
            label={t('deductions:deduction_for_part_of_cost')}
            labelVariant="subtitle1"
          />
          <HIDTypography variant="body1">
            <ReactMarkdown components={{ p: 'div' }}>
              {t('deductions:deduction_you_can_deduct', { amount: totalCostWithCurrency })}
            </ReactMarkdown>
          </HIDTypography>
          <DeclarationTable
            items={resultItems}
            loading={isLoading}
          />
          {result?.explanation && (
            <>
              <Divider />
              <HIDTypography variant="body1">
                <ReactMarkdown components={{ p: 'div' }}>
                  {result.explanation}
                </ReactMarkdown>
              </HIDTypography>
            </>
          )}
        </Stack>
      </Card>
    </Stack>

  );
};

export default DeductionCalculationResultCard;
