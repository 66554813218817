import React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { FCC } from '../../../types/common';
import { useGetSubscriptionPlanSettings } from '../hooks/useGetSubscriptionPlanSettings';
import { SubscriptionPlanId } from '@house-id/houseid-types/dist/subscriptionPlans';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import { RootState } from '../../../store/store';

type SubscriptionPlanCardHeaderProps = {
  plan: SubscriptionPlanId;
};

const IMAGE_SIZE = 136;

const SubscriptionPlanCardHeader: FCC<SubscriptionPlanCardHeaderProps> = ({
  plan,
  children,
}) => {
  const { t } = useTranslation(['subscriptions']);
  const theme = useTheme();

  const { isDownSm, isDownMd } = useBreakPointsSizes();
  const { isMenuOpened } = useSelector((state: RootState) => state.layout);

  const { data: subscriptionPlanInfo } = useGetSubscriptionPlanSettings({ subscriptionPlanId: plan });

  return subscriptionPlanInfo
    ? (
      <Stack
        sx={{
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '10px',
          borderColor: theme.palette.grey[300],
          backgroundColor: theme.palette.common.white,
          height: '100%',
          width: '100%',
          display: 'flex',
        }}
      >
        <Stack display="flex" flex={1}>
          <Stack
            padding={2}
            style={{
              backgroundImage: `url(${subscriptionPlanInfo.imageSource})`,
              backgroundSize: `${IMAGE_SIZE}px`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              backgroundColor: subscriptionPlanInfo.backgroundColor,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              minHeight: (isDownMd || (!isDownMd && isMenuOpened)) ? 180 : 160,
            }}
          >
            <Stack
              spacing={1}
              style={{
                paddingRight: IMAGE_SIZE,
              }}
            >
              <Stack
                alignItems="center"
                direction="row"
                gap={isDownSm ? 1 : 0}
                spacing={isDownSm ? 0 : 2}
              >
                <Typography color={subscriptionPlanInfo.textColor} variant="h3">
                  {subscriptionPlanInfo.name}
                </Typography>
                {subscriptionPlanInfo.isPopular && (
                  <Typography
                    color={subscriptionPlanInfo.textColor}
                    style={{
                      backgroundColor: theme.palette.warning.main,
                      borderRadius: '4px',
                      color: theme.palette.common.white,
                      padding: theme.spacing(0, 0.5),
                      textAlign: 'center',
                    }}
                    variant="subtitle1"
                  >
                    {t('subscriptions:subscription_plans_popular_subscription_plan')}
                  </Typography>
                )}
              </Stack>
              <Typography color={subscriptionPlanInfo.textColor} variant={isDownSm ? 'body2' : 'body1'}>
                {subscriptionPlanInfo.description}
              </Typography>
            </Stack>
          </Stack>
          {children}
        </Stack>
      </Stack>
    )
    : null;
};

export default SubscriptionPlanCardHeader;
