import React from 'react';
import {
  Stack,
} from '@mui/material';

import DeclarationTableRow, { DeclarationTableItem } from './DeclarationTableRow';

type DeclarationTableProps = {
  items: Array<DeclarationTableItem>;
  loading?: boolean;
};

const DeclarationTable: React.FC<DeclarationTableProps> = ({
  items,
  loading,
}) => (
  <Stack gap={0.5}>
    {items.filter(Boolean).map((item, index) => (
      <DeclarationTableRow item={item} key={index} loading={loading} />
    ))}
  </Stack>
);
export default DeclarationTable;
