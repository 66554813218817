import React from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Stack,
  useTheme,
} from '@mui/material';

import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetPropertyFinanceSaleSummaryQuery } from '../../../../../api/api.propertyFinance';
import DeclarationCalculationsSection from './DeclarationCalculationsSection';
import { FCC } from '../../../../../../../../../types/common';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import { formatMoney } from '../../../../../../../../../utils/string';
import useGetDeductionCalculationSections, { DeductionCalculationSectionType } from '../../../hooks/useGetDeductionCalculationSections';
import DeclarationGroups from '../../../components/DeclarationGroups';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';

const DeclarationCalculationsYourDeductions: FCC = ({ sx }) => {
  const theme = useTheme();
  const { t } = useTranslation(['declarations']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: financeSaleSummary,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: groups = [],
    isLoading: isDeductionCalculationSectionsLoading,
    isFetching: isDeductionCalculationSectionsFetching,
  } = useGetDeductionCalculationSections({ section: DeductionCalculationSectionType.DEDUCTIONS });

  const loading = isDeductionCalculationSectionsLoading || isDeductionCalculationSectionsFetching;

  const total = financeSaleSummary?.taxCalculation?.totalDeductionsAmount || 0;

  const [openManagePropertyEstimatedDeductionsDialog] = useDialog(DialogNames.MANAGE_PROPERTY_ESTIMATED_DEDUCTIONS_DIALOG);
  const handleManageDeductions = openManagePropertyEstimatedDeductionsDialog;

  return (
    <DeclarationCalculationsSection
      SubtitleComponent={
        <HIDInfo
          isMarkdown
          description={t('declarations:declarations_declaration_your_deductions_description')}
          label={t('declarations:declarations_declaration_your_deductions_subtitle')}
        />
      }
      sx={sx}
      title={t('declarations:declarations_declaration_your_deductions_title')}
    >
      <Stack
        alignItems="center"
        sx={{
          gap: theme.spacing(1),
          padding: theme.spacing(2),
          backgroundColor: theme.palette.grey[100],
          borderRadius: BorderRadius.md,
        }}
      >
        <HIDTypography variant="h5">{formatMoney(total)}</HIDTypography>
        <HIDTypography sx={{ textAlign: 'center' }}>{t('declarations:declarations_total_deduction_amount')}</HIDTypography>
      </Stack>
      <DeclarationGroups groups={groups} loading={loading} />
      <HIDButton
        color="secondary"
        size="medium"
        sx={{ alignSelf: 'flex-end', marginTop: theme.spacing(2) }}
        onClick={handleManageDeductions}
      >
        {t('declarations:declarations_declaration_your_deductions_manage_deductions')}
      </HIDButton>
    </DeclarationCalculationsSection>
  );
};

export default DeclarationCalculationsYourDeductions;
