import { DeductionCategory } from '@house-id/houseid-types/dist/deductions';

import RouteNames from '../../../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../../../types/types.property';

export const getBuyDeclarationHelpPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.BUY_DECLARATION_HELP,
  { propertyId },
);

export const getDeclarationHelpOverviewPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.DECLARATION_HELP_OVERVIEW,
  { propertyId },
);

type CreateRenovationDeductionOperationNavigation = { category: DeductionCategory.RENOVATION; type?: string; };
type CreateSalesExpensesDeductionOperationNavigation = { category: DeductionCategory.SALES_EXPENSE; type: string; };
type CreatePurchaseExpensesDeductionOperationNavigation = { category: DeductionCategory.PURCHASE_EXPENSE; type: string; };

export type UpdateDeductionOperationNavigation = { id: string; operation: 'update'; replace?: boolean; };
export type CreateDeductionOperationNavigation = (
  CreateRenovationDeductionOperationNavigation |
  CreateSalesExpensesDeductionOperationNavigation |
  CreatePurchaseExpensesDeductionOperationNavigation) & PropertyIdNavigation;

export const getDeductionCreatePath = ({ propertyId, category, type }: CreateDeductionOperationNavigation) => generateRoutePath(
  RouteNames.DEDUCTION_CREATE,
  { propertyId },
  { category, type },
);

type GetDeductionUpdatePath = PropertyIdNavigation & { id: string };
export const getDeductionUpdatePath = ({ propertyId, id }: GetDeductionUpdatePath) => generateRoutePath(
  RouteNames.DEDUCTION_UPDATE,
  { propertyId, id },
);
