import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import {
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CloudUploadOutlined } from '@mui/icons-material';
import * as R from 'ramda';
import { SubscriptionFeature } from '@house-id/houseid-types/dist/subscriptionPlans';

import HIDButton from '../buttons/HIDButton';
import {
  AllSupportedFileMimeTypes,
  FileMimeType,
  ImageMimeTypes,
} from '../../constants/mimeTypes';
import {
  FCC,
  FCCProps,
} from '../../types/common';
import { hidSpacing } from '../../utils/number';
import { ContentFile } from '../../modules/Property/modules/Content/modules/ContentFile/types.contentFile';
import useDialog from '../../hooks/useDialog';
import DialogNames from '../../hooks/useDialog/DialogNames';
import { getAcceptMimeTypeMap } from './utils';
import useTryUseFeatureDialog from '../../modules/SubscriptionPlans/hooks/useTryUseFeatureDialog';

export type HIDFilePickerRef = {
  open: () => void;
};

type HIDFilePickerProps = {
  mimeTypes?: Array<FileMimeType>;
  multiple?: boolean;
  loading?: boolean;
  onContentFilesSelected?: (files: Array<ContentFile>) => void;
};

type FilePickerPropsWithRef = FCCProps<HIDFilePickerProps, HIDFilePickerRef>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const ContentFilePicker: FCC<HIDFilePickerProps> = forwardRef<HIDFilePickerRef, FilePickerPropsWithRef>((
  {
    mimeTypes = AllSupportedFileMimeTypes,
    multiple = true,
    loading = false,
    onContentFilesSelected,
    sx,
  },
  ref,
) => {
  const { t } = useTranslation(['common', 'photos']);
  const theme = useTheme();

  const onlyImages = R.equals(mimeTypes, ImageMimeTypes);

  const [dropZoneSelectedFiles, setDropZoneSelectedFiles] = useState<Array<File>>([]);

  const handleFilesSelected = (files: Array<File>) => setDropZoneSelectedFiles(files);

  const onDrop = useCallback(
    handleFilesSelected,
    [handleFilesSelected],
  );

  const acceptMap = getAcceptMimeTypeMap(mimeTypes);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: acceptMap,
    multiple,
    onDrop,
  });

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.STORAGE_SPACE });

  const [openFilePickerDialog] = useDialog(DialogNames.CONTENT_FILE_PICKER_DIALOG);

  const handleOpenFilePicker = () => {
    openFilePickerDialog({
      onSelect: onContentFilesSelected,
      inputFiles: dropZoneSelectedFiles,
    });
  };

  const handleTryOpenFilePicker = () => proceedToFeatureOrOpenSubscriptionDialog({ onAction: handleOpenFilePicker });

  useImperativeHandle(ref, () => ({
    open: handleTryOpenFilePicker,
  }));

  useEffect(() => {
    if (dropZoneSelectedFiles.length) {
      handleTryOpenFilePicker();
    }
  }, [dropZoneSelectedFiles]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1.5}
      sx={{
        width: '100%',
        borderRadius: 1,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        background: isDragActive ? theme.palette.primary.lightest : theme.palette.common.white,
        textAlign: 'center',
        padding: 3,
        ...sx,
      }}
    >
      <Stack
        {...getRootProps()}
        spacing={1}

      >
        {
          loading
            ? (
              <CircularProgress
                size={hidSpacing(8)}
              />
            )
            : (
              <>
                <input {...getInputProps()} />
                <div
                  style={{
                    padding: theme.spacing(1.5),
                    borderRadius: '50%',
                    alignSelf: 'center',
                    backgroundColor: theme.palette.primary.lighter,
                  }}
                >
                  <CloudUploadOutlined
                    color="primary"
                    style={{ display: 'block' }}
                    sx={{
                      width: theme.spacing(4),
                      height: theme.spacing(4),
                    }}
                    onClick={(event) => event.preventDefault()}
                  />
                </div>
                <Typography variant="h6">
                  {onlyImages ? t('photos:upload_photos') : t('common:add_new_attachments')}
                </Typography>
                <Typography>
                  {onlyImages ? t('photos:drag_and_drop_images') : t('common:drag_and_drop_files')}
                </Typography>
                <Stack direction="row" justifyContent="center">
                  <HIDButton
                    onClick={() => { }}
                  >
                    {t('photos:select_from_file_system')}
                  </HIDButton>
                </Stack>
              </>
            )
        }
      </Stack>
      <HIDButton
        color="secondary"
        onClick={(event) => {
          event.preventDefault();
          handleTryOpenFilePicker();
        }}
      >
        {onlyImages ? t('photos:select_images') : t('photos:select_attachments')}
      </HIDButton>
    </Stack>
  );
});

ContentFilePicker.displayName = 'ContentFilePicker';

export default ContentFilePicker;
