import ReactGA from 'react-ga4';
import { HIDUser } from '@house-id/houseid-types/dist/common';
import {
  AnalyticsUserProperty,
} from '@house-id/houseid-types/dist/analytics';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';
import { SubscriptionPlanId } from '@house-id/houseid-types/dist/subscriptionPlans';

import {
  UserOrigin,
  UserTokenData,
} from '../../Auth/types/types.auth';

export const SUBSCRIPTION_PLAN = 'subscription-plan';
export const HAS_PROPERTY = 'has_property';
export const HAS_MULTIPLE_PROPERTIES = 'has_multiple_properties';
export const IS_ADMIN = 'is_admin';

export const initGoogleAnalytics = () => ReactGA.initialize(import.meta.env.VITE_APP_GA_MEASUREMENT_ID, {
  gaOptions: { send_page_view: false },
  gtagOptions: { send_page_view: false },
});

export const logCustomEvent = (
  eventName: string,
  source: PartnersSource | string,
  hidCategory: string,
  params?: object,
) => {
  const eventPrams = {
    category: hidCategory,
    label: window.location.pathname,
    source,
    ...params,
  };

  ReactGA.event(eventName, eventPrams);
};

export const setGAUserValues = (tokenData: UserTokenData, user: HIDUser) => {
  const properties: Record<string, string> = {
    [AnalyticsUserProperty.IS_ADMIN]: tokenData.isAdmin ? 'true' : 'false',
    [AnalyticsUserProperty.IS_PREMIUM]: tokenData.subscriptionPlanId !== SubscriptionPlanId.FREE ? 'true' : 'false',
    [AnalyticsUserProperty.SUBSCRIPTION_PLAN]: tokenData.subscriptionPlanId,
    [AnalyticsUserProperty.HAS_PROPERTY]: tokenData.properties.length > 0 ? 'true' : 'false',
    [AnalyticsUserProperty.HAS_MULTIPLE_PROPERTIES]: tokenData.properties.length > 1 ? 'true' : 'false',
    [AnalyticsUserProperty.WAS_PREMIUM]: tokenData.memberships.length > 0 && tokenData.subscriptionPlanId === SubscriptionPlanId.FREE
      ? 'true'
      : 'false',
    [AnalyticsUserProperty.UTM_SOURCE]: user.utm?.utm_source || UserOrigin.HOUSE_ID,
    [AnalyticsUserProperty.UTM_MEDIUM]: user.utm?.utm_medium || '',
    [AnalyticsUserProperty.UTM_CAMPAIGN]: user.utm?.utm_campaign || '',
  };

  ReactGA
    .set({
      ...properties,
      userId: tokenData.userId,
    });
};

export const clearGAUserValues = () => ReactGA.reset();
