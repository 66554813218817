import { PropertyType } from '@house-id/houseid-types/dist/property';

import { PropertyAddress } from '../types/types.property';

type PropertyAddressSettings = {
  address: PropertyAddress;
  type?: PropertyType;
  apartments?: Array<{ number: string }>
};

export const getApartmentNumber = (property: PropertyAddressSettings) => {
  if (!property?.apartments) {
    return undefined;
  }
  return property?.apartments?.length === 1 ? property?.apartments[0]?.number : undefined;
};

export const formatPropertyAddress = (address: PropertyAddress | undefined) => {
  if (!address) {
    return '';
  }
  const locality = address.postalCode ? [address.postalCode, address.addressLocality].filter(Boolean).join(' ') : address.addressLocality;

  return address?.streetAddress
    ? [address.streetAddress, locality].filter(Boolean).join(', ')
    : '';
};

export const formatPropertyAddressWithApartment = (address?: string, apartmentNumber?: string) =>
  address
    ? `${address}${apartmentNumber ? `, ${apartmentNumber}` : ''}`
    : undefined;

export const getPropertyAddress = (property: PropertyAddressSettings | undefined) => {
  if (!property?.address) {
    return '';
  }
  const address = formatPropertyAddress(property.address);
  if (property.type === PropertyType.HOUSE) {
    return address;
  }
  const apartmentNumber = getApartmentNumber(property);
  return formatPropertyAddressWithApartment(address, apartmentNumber);
};
