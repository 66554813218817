import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

import { FCC } from '../../types/common';
import HIDTypography from '../HIDTypography';

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: ownerState.active || ownerState.completed ? theme.palette.primary.dark : theme.palette.common.black,
  backgroundColor: ownerState.active || ownerState.completed ? theme.palette.primary.main : theme.palette.common.white,
}));

function ColorlibStepIcon(props: StepIconProps) {
  const {
    active, completed, className, icon,
  } = props;

  const textColor = active || completed ? 'white' : 'inherit';

  return (
    <ColorlibStepIconRoot className={className} ownerState={{ completed, active }}>
      <HIDTypography color={textColor} variant="h6">
        {icon}
      </HIDTypography>
    </ColorlibStepIconRoot>
  );
}

export type HorizontalStepperItem = {
  id: string;
  title: string;
  isActive?: boolean;
};

type HorizontalStepperProps = {
  activeStep: number;
  steps: Array<HorizontalStepperItem>;
};

const HorizontalStepper: FCC<HorizontalStepperProps> = ({ steps, activeStep }) => (
  <Stack spacing={4} sx={{ width: '100%' }}>
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
      {steps.map(({ id, title }) => (
        <Step key={id}>
          <StepLabel
            StepIconComponent={ColorlibStepIcon}
          >
            <HIDTypography variant="subtitle1">
              {title}
            </HIDTypography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Stack>
);

export default HorizontalStepper;
