import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { useGetAllDocumentsQuery } from '../../../../modules/Document/api/api.document';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import { useGetDocumentsDataGridColumns } from '../../../../modules/Document/pages/Documents';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';

const ManageDocumentsTab: FC<ManageContentConnectionsTabProps> = ({
  loading,
  selectedItems,
  onSelect,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const documentsColumns = useGetDocumentsDataGridColumns({
    isSelectionMode: true,
    categoryVisible: false,
    classificationVisible: false,
  });

  const {
    data: documents = [],
    isLoading: isDocumentsFetching,
  } = useGetAllDocumentsQuery(propertyId ? { propertyId } : skipToken);

  return (
    <ManageConnectionsCommonTab
      columns={documentsColumns}
      loading={isDocumentsFetching || loading}
      rows={documents}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManageDocumentsTab;
