import React, { FC } from 'react';
import {
  Card,
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { Remove } from '@mui/icons-material';
import {
  DeclarationStatus,
  DeductionCategory,
  DeductionChatContextType,
} from '@house-id/houseid-types/dist/deductions';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import HomeLayout from '../../../../../../../pages/Home/components/HomeLayout';
import ViewEntityActions from '../../../../../../Content/components/actions/ViewEntityActions';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import { useNavigateBackOr } from '../../../../../../../../../utils/routes';
import { getHomePath } from '../../../../../../../navigation/navigation.property';
import {
  useGetDeductionsOverviewQuery,
  useDeleteAllDeductionsMutation,
} from '../api/api.deductions';
import useGetPropertyPermissions from '../../../../../../../hooks/useGetPropertyPermissions';
import DeclarationSaleInformationSection from '../components/DeclarationSaleInformationSection';
import DeclarationHelpSummarySection from '../components/DeclarationHelpSummarySection';
import DeductionsSection from '../components/DeductionsSection';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import DeductionsSupportBox from '../components/DeductionsSupportBox';
import HorizontalStepper from '../../../../../../../../../components/steppers/HorizontalStepper';
import CostsPurchase from '../../../../../../../../../components/icons/CostsPurchase';
import Brush from '../../../../../../../../../components/icons/Brush';
import { isTestEnv } from '../../../../../../../../../utils/env';

const DeclarationHelpOverview: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['declarations', 'common']);
  const theme = useTheme();

  const { data: { canUpdate } = {} } = useGetPropertyPermissions();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: deductionsOverview,
    isLoading: isDeductionsOverviewLoading,
    // error: deductionsOverviewError,
    // refetch: refetchDeductionsOverview,
    isUninitialized,
  } = useGetDeductionsOverviewQuery(propertyId ? { propertyId } : skipToken);

  const [deleteAllDeductions, { isLoading: isDeletingAllDeductions }] = useDeleteAllDeductionsMutation();

  const activeStep = deductionsOverview?.declaration?.status || DeclarationStatus.PREPERATION;
  const steps = [
    {
      id: DeclarationStatus.PREPERATION,
      title: t('declarations:declaration_help_summary_step_1'),
    },
    {
      id: DeclarationStatus.CONFIRMED,
      title: t('declarations:declaration_help_summary_step_2'),
    },
    {
      id: DeclarationStatus.ACCEPTED,
      title: t('declarations:declaration_help_summary_step_3'),
    },
    {
      id: DeclarationStatus.SUBMITTED,
      title: t('declarations:declaration_help_summary_step_4'),
    },
  ].map((step) => ({
    ...step,
    isActive: step.id === activeStep,
  }));
  const activeStepIndex = steps.findIndex((step) => step.isActive);
  const isPreparationStep = deductionsOverview?.declaration?.status === activeStep;

  const [openSelectDeductionTypeDialog] = useDialog(DialogNames.SELECT_DEDUCTION_TYPE_DIALOG);
  const [openSelectDeductionCategoryDialog] = useDialog(DialogNames.SELECT_DEDUCTION_CATEGORY_DIALOG);
  const handleAddDeduction = (category: DeductionCategory) => openSelectDeductionTypeDialog({ category });

  const [openAddDeductionRenovationDialog] = useDialog(DialogNames.ADD_DEDUCTION_RENOVATION_DIALOG);
  const handleAddRenovationDeduction = () => openAddDeductionRenovationDialog();

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const handleDeleteAllDeductions = () => {
    if (propertyId) {
      deleteAllDeductions({ propertyId })
        .unwrap()
        .then(handleGoBack);
    }
  };

  const [openConfirmSubmitDialog] = useDialog(DialogNames.DECLARATION_HELP_SUBMISSION_RESULT_DIALOG);
  const handleSubmitAllData = openConfirmSubmitDialog;

  const actions = canUpdate
    ? [
      isTestEnv && {
        id: 'delete-all-deductions',
        Icon: Remove,
        label: 'Delete all deductions',
        onClick: handleDeleteAllDeductions,
      },
    ].filter(Boolean)
    : [];

  const loading = isDeductionsOverviewLoading || isDeletingAllDeductions || isUninitialized;

  return (
    <HomeLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              customActions={actions}
              onAdd={openSelectDeductionCategoryDialog}
            />
          </Card>
          <DeductionsSupportBox
            context={{
              type: DeductionChatContextType.DEDUCTION_CATEGORY,
              deductionCategory: DeductionCategory.PURCHASE_EXPENSE,
            }}
            linkText={t('common:read_more_and_chat')}
            title={t('common:questions')}
          />
        </>
      }
      isLoading={loading}
      sideDrawerElements={[
        <ViewEntityActions
          customActions={actions}
          key={ViewEntityActions.name}
        />,
      ]}
      title={t('declarations:declaration_help_summary_title')}
      onBack={handleGoBack}
    >
      <Stack gap={3}>
        {isPreparationStep
          ? (
            <Stack>
              <HIDTypography>
                {t('declarations:declaration_help_summary_description')}
              </HIDTypography>
            </Stack>
          ) : null}
        <Stack sx={{ gap: 3, padding: 0 }}>
          <HorizontalStepper activeStep={activeStepIndex} steps={steps} />
          <DeclarationSaleInformationSection />
          {!loading && (
            <DeclarationHelpSummarySection
              title={t('declarations:declaration_help_summary_deduction_section_title')}
              titleContainerSx={{ backgroundColor: theme.palette.grey[200] }}
            >
              <DeductionsSection
                Icon={CostsPurchase}
                deductionCategory={DeductionCategory.PURCHASE_EXPENSE}
                emptyStateDescription={t('declarations:purchase_costs_deductions_section_description')}
                emptyStateTitle={t('declarations:purchase_costs_deductions_section_title')}
                title={t('declarations:purchase_costs_deductions_section_title')}
                onAdd={() => handleAddDeduction(DeductionCategory.PURCHASE_EXPENSE)}
              />
              <Divider />
              <DeductionsSection
                Icon={CostsPurchase}
                deductionCategory={DeductionCategory.SALES_EXPENSE}
                emptyStateDescription={t('declarations:selling_expenses_deductions_section_description')}
                emptyStateTitle={t('declarations:selling_expenses_deductions_section_title')}
                title={t('declarations:selling_expenses_deductions_section_title')}
                onAdd={() => handleAddDeduction(DeductionCategory.SALES_EXPENSE)}
              />
              <Divider />
              <DeductionsSection
                Icon={Brush}
                deductionCategory={DeductionCategory.RENOVATION}
                emptyStateDescription={t('declarations:improvements_and_maintenance_deductions_section_description')}
                emptyStateTitle={t('declarations:improvements_and_maintenance_deductions_section_title')}
                title={t('declarations:improvements_and_maintenance_deductions_section_title')}
                onAdd={handleAddRenovationDeduction}
              />
              <Divider />
            </DeclarationHelpSummarySection>
          )}
        </Stack>
        <HIDButton
          sx={{ alignSelf: 'flex-end' }}
          title={t('declarations:declaration_help_summary_button')}
          onClick={handleSubmitAllData}
        />
      </Stack>
    </HomeLayout>
  );
};

export default DeclarationHelpOverview;
