import {
  FeatureStatus,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import { useGetCurrentSubscriptionPlanQuery } from '../../../../../../../../SubscriptionPlans/api/api.subscriptionPlans';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { QueryHook } from '../../../../../../../../../types/common';

type UseGetPropertyDeclarationHelpFeatureStatus = QueryHook<FeatureStatus | undefined>;
const useGetPropertyDeclarationHelpFeatureStatus: UseGetPropertyDeclarationHelpFeatureStatus = () => {
  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: subscriptionPlan, ...rest } = useGetCurrentSubscriptionPlanQuery();

  const propertyFeature = subscriptionPlan?.properties?.find(({ id }) => id === propertyId)?.declarationHelp;

  return {
    data: propertyFeature?.status,
    ...rest,
  };
};
export default useGetPropertyDeclarationHelpFeatureStatus;
