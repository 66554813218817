import React from 'react';
import { FeatureStatus } from '@house-id/houseid-types/dist/subscriptionPlans';
import { DeductionChatContext } from '@house-id/houseid-types/dist/deductions';
import { AnalyticsEvent } from '@house-id/houseid-types/dist/analytics';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';

import useGetPropertyDeclarationHelpFeatureStatus from '../hooks/useGetPropertyDeclarationHelpFeatureValue';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import useGetLogAnalyticsEvent from '../../../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { useCreateDeclarationHelpChatSessionMutation } from '../api/api.deductions';
import { openUrlNewTab } from '../../../../../../../../../utils/file';
import { FCC } from '../../../../../../../../../types/common';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import HIDLink from '../../../../../../../../../components/HIDLink';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import Robot from '../../../../../../../../../components/icons/Robot';
import CircleIcon from '../../../../../../../../../components/CircleIcon';

type DeductionsSupportBoxProps = {
  context: DeductionChatContext;
  title?: string;
  linkText: string;
};

const DeductionsSupportBox: FCC<DeductionsSupportBoxProps> = ({
  context,
  linkText,
  title,
}) => {
  const theme = useTheme();
  const { data: propertyId } = useGetCurrentPropertyId();
  const [creteNewDeductionsChatSession] = useCreateDeclarationHelpChatSessionMutation();
  const logEvent = useGetLogAnalyticsEvent();

  const handleOpenSupportWebView = () => {
    if (propertyId) {
      creteNewDeductionsChatSession({ context, propertyId })
        .unwrap()
        .then((chatSession) => {
          logEvent({
            event: AnalyticsEvent.DECLARATION_HELP_OPEN_SUPPORT_CHAT,
            hidCategory: EntityType.DECLARATION,
          });
          openUrlNewTab(chatSession.url);
        });
    }
  };

  const { data: declarationHelpFeatureStatus } = useGetPropertyDeclarationHelpFeatureStatus();

  return declarationHelpFeatureStatus === FeatureStatus.ENABLED
    ? (
      <Card
        style={{
          backgroundColor: theme.palette.common.premiumLight,
          borderRadius: BorderRadius.md,
        }}
      >
        <Stack
          display="flex"
          flex={1}
          flexDirection="row"
          gap={1.5}
          justifyContent="space-between"
          padding={2}
          sx={{
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Stack flex={1} gap={0.5}>
            {title && (
              <HIDTypography variant="subtitle1">
                {title}
              </HIDTypography>
            )}
            <HIDLink
              color="grey"
              label={linkText}
              linkColor={theme.palette.common.black}
              onClick={handleOpenSupportWebView}
            />
          </Stack>
          <HIDIconButton
            Icon={() => (
              <CircleIcon
                Icon={Robot}
                backgroundColor={theme.palette.common.premium}
                iconColor={theme.palette.common.white}
              />
            )}
            onClick={handleOpenSupportWebView}
          />
        </Stack>
      </Card>
    )
    : null;
};

export default DeductionsSupportBox;
