import React, { ReactElement } from 'react';
import {
  Card,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import {
  Icon,
  FCC,
} from '../../../../../../../../../types/common';
import HIDInfo, { HIDInfoProps } from '../../../../../../../../../components/HIDInfo';

type DeclarationHelpSummarySectionProps = {
  title: string;
  titleContainerSx?: SxProps<Theme>;
  info?: HIDInfoProps;
  Icon?: Icon;
  RightComponent?: ReactElement;
  loading?: boolean;
};

const DeclarationHelpSummarySection: FCC<DeclarationHelpSummarySectionProps> = ({
  title,
  titleContainerSx: titleContainerStyle,
  Icon,
  RightComponent,
  info,
  loading,
  children,
  sx,
}) => {
  const theme = useTheme();

  return (
    <Card sx={{
      padding: 0,
      borderRadius: BorderRadius.md,
      borderWidth: 1,
      borderColor: theme.palette.grey[200],
      ...sx,
    }}
    >
      <Stack
        flexDirection="row"
        gap={1}
        justifyContent="space-between"
        padding={1.5}
        sx={{
          borderTopLeftRadius: BorderRadius.md,
          borderTopRightRadius: BorderRadius.md,
          borderBottomWidth: 1,
          borderBottomColor: theme.palette.grey[400],
          ...titleContainerStyle,
        }}
      >
        <Stack alignItems="center" flexDirection="row" gap={1}>
          {Icon && <Icon />}
          <HIDInfo
            isLoading={loading}
            label={title}
            labelVariant="h6"
            {...info}
          />
        </Stack>
        {RightComponent}
      </Stack>
      <Stack gap={1}>
        {children}
      </Stack>
    </Card>
  );
};

export default DeclarationHelpSummarySection;
