import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Deduction,
} from '@house-id/houseid-types/dist/deductions';
import { Warning } from '@mui/icons-material';
import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import ReactMarkdown from 'react-markdown';

import HIDTypography from '../../../../../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../../../../../utils/string';

type ReceiptsMissedAmountProps = {
  deduction: Deduction | undefined,
};

const ReceiptsMissedAmount: FC<ReceiptsMissedAmountProps> = ({
  deduction,
}) => {
  const { t } = useTranslation(['deductions', 'forms_common']);

  const missedReceiptsAmount = deduction ? deduction.summary.totalAmount - deduction.summary.totalReceiptAmount : 0;

  return missedReceiptsAmount > 0
    ? (
      <Card>
        <Stack
          flex={1}
          flexDirection="row"
          gap={1}
          padding={1}
        >
          <Warning color="warning" />
          <HIDTypography
            sx={{ flex: 1 }}
            variant="body1"
          >
            <ReactMarkdown components={{ p: 'span' }}>
              {t(
                'deductions:create_edit_deductions_connected_receipts_warning',
                { amount: formatMoney(missedReceiptsAmount || 0) },
              )}
            </ReactMarkdown>
          </HIDTypography>
        </Stack>
      </Card>
    ) : null;
};

export default ReceiptsMissedAmount;
