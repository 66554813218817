import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaqCategoryType } from '@house-id/houseid-types/dist/faq';
import { useNavigate } from 'react-router';

import HIDTypography from '../../../../../../../../../../components/HIDTypography';
import SkattioDeclarationBanner from '../../components/SkattioDeclarationBanner';
import DoneAll from '../../../../../../../../../../components/icons/DoneAll';
import Forum from '../../../../../../../../../../components/icons/Forum';
import Guide from '../../../../../../../../../../components/icons/Guide';
import { Icon } from '../../../../../../../../../../types/common';
import { useGetFaqCategoryQuery } from '../../../../../../../../../Auth/modules/Settings/api/api.faq';
import FaqQuestionCard from '../../components/FaqQuestionCard';
import HomeLayout from '../../../../../../../../pages/Home/components/HomeLayout';
import Star from '../../../../../../../../../../components/icons/Star';
import { BorderRadius } from '../../../../../../../../../../constants/borderRadius';
import { range } from '../../../../../../../../../../utils/array';
import useGetIsSfSellerSellingProperty from '../../../../../../../../hooks/useGetIsSfSellerSellingProperty';
import { Currency } from '../../../../../../../../../../utils/string';
import HIDButton from '../../../../../../../../../../components/buttons/HIDButton';
import { getDeclarationHelpOverviewPath } from '../../navigation.declarationHelp';
import useGetCurrentPropertyId from '../../../../../../../../hooks/useGetCurrentPropertyId';
import HIDLink from '../../../../../../../../../../components/HIDLink';
import useDialog from '../../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../../hooks/useDialog/DialogNames';

const PROMOTION_PRICE = 995;
const REGULAR_PRICE = 1495;

const BenefitIcon: FC<{ Icon: Icon }> = ({ Icon }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: theme.spacing(3),
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      <Icon
        fillColor={theme.palette.primary.dark}
        iconColor={theme.palette.primary.dark}
        size={24}
        strokeWidth={2}
      />
    </Stack>
  );
};

const FeedbackCard: FC<{ message: string, rating: number }> = ({
  message,
  rating,
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        padding: 1.5,
        borderRadius: BorderRadius.sm,
        backgroundColor: theme.palette.grey[200],
      }}
    >
      <Stack
        sx={{
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 1.5,
          }}
        >
          {range(1, 5).map((starNumber) => (
            <Star
              fillColor={rating >= starNumber ? theme.palette.warning.light : theme.palette.common.white}
              iconColor={theme.palette.warning.light}
              key={starNumber}
            />
          ))}
        </Stack>
        <HIDTypography>
          {message}
        </HIDTypography>
      </Stack>
    </Card>
  );
};

const BuyDeclarationHelp: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['declarations']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const isSfSellerSellingProperty = useGetIsSfSellerSellingProperty();
  const { data: faqQuestions } = useGetFaqCategoryQuery({ categoryId: FaqCategoryType.DECLARATION_HELP });

  const handleOrder = () => {
    if (propertyId) {
      navigate(getDeclarationHelpOverviewPath({ propertyId }));
    }
  };

  const [openDeductionsTermsAndConditions] = useDialog(DialogNames.INFO_DIALOG);

  return (
    <HomeLayout
      SideColumn={<div />}
      title={t('declarations:declarations_the_declaration_aid')}
    >
      <Stack sx={{ gap: 4 }}>
        <Stack sx={{ gap: 2.5 }}>
          <HIDTypography variant="subtitle1">
            {t('declarations:declarations_declare_easily_and_maximize_your_refund')}
          </HIDTypography>
          <SkattioDeclarationBanner />
          <HIDTypography variant="subtitle1">
            {t('declarations:declarations_why_choose_the_declaration_help')}
          </HIDTypography>
        </Stack>
        <Stack sx={{ gap: 2.5 }}>
          <HIDTypography>
            {t('declarations:declarations_get_the_most_out_of_your_home_sale_without_the_hassle')}
          </HIDTypography>
          <HIDTypography variant="subtitle1">
            {t('declarations:declarations_get_full_power')}
          </HIDTypography>
          <Stack sx={{ flexDirection: 'row', gap: 1.5 }}>
            <BenefitIcon Icon={DoneAll} />
            <Stack sx={{ gap: 1 }}>
              <HIDTypography>
                {t('declarations:declarations_discover_all_deductions')}
              </HIDTypography>
              <HIDTypography>
                {t('declarations:declarations_we_help_you_ensure_that_all_possible_deductions_are_included')}
              </HIDTypography>
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 1.5 }}>
            <BenefitIcon Icon={Forum} />
            <Stack sx={{ gap: 1 }}>
              <HIDTypography>
                {t('declarations:declarations_all_the_help_you_need_when_you_need_it')}
              </HIDTypography>
              <HIDTypography>
                {t('declarations:declarations_questions_on_the_road_with_our_smart_chat')}
              </HIDTypography>
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 1.5 }}>
            <BenefitIcon Icon={Guide} />
            <Stack sx={{ gap: 1 }}>
              <HIDTypography>
                {t('declarations:declarations_step_by_step_guidance')}
              </HIDTypography>
              <HIDTypography>
                {t('declarations:declarations_you_will_be_guided_through_the_entire_process')}
              </HIDTypography>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ gap: 2.5 }}>
          <HIDTypography variant="subtitle1">
            {t('declarations:declarations_what_our_customers_say')}
          </HIDTypography>
          <FeedbackCard
            message={t('declarations:declarations_fantastic_service_the_declaration_help_made_my_declaration')}
            rating={5}
          />
        </Stack>
        <Stack sx={{ gap: 2.5 }}>
          <HIDTypography variant="subtitle1">
            {t('declarations:declarations_our_promise_to_you')}
          </HIDTypography>
          <HIDTypography>
            {t('declarations:declarations_we_are_dedicated_to_helping_you_declare_with_confidence')}
          </HIDTypography>
        </Stack>
        {Boolean(faqQuestions?.length) && (
          <Stack sx={{ gap: 2.5 }}>
            <HIDTypography variant="subtitle1">
              {t('declarations:declarations_frequently_asked_questions')}
            </HIDTypography>
            <Stack sx={{ gap: 2 }}>
              {faqQuestions?.map((faq, index) => (
                <FaqQuestionCard
                  key={index}
                  question={faq}
                />
              ))}
            </Stack>
          </Stack>
        )}
        <Stack sx={{ gap: 2.5 }}>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 1,
              alignItems: 'center',
            }}
          >
            {
              isSfSellerSellingProperty
                ? (
                  <>
                    <HIDTypography>
                      {t('declarations:declarations_only')}
                    </HIDTypography>
                    <HIDTypography variant="subtitle1">
                      {PROMOTION_PRICE} {Currency.label}
                    </HIDTypography>
                    <HIDTypography>
                      {`(${t('declarations:declarations_ordinary_price')} ${REGULAR_PRICE} ${Currency.label})`}
                    </HIDTypography>
                  </>
                )
                : (
                  <>
                    <HIDTypography>
                      {t('declarations:declarations_only')}
                    </HIDTypography>
                    <HIDTypography variant="subtitle1">
                      {REGULAR_PRICE} {Currency.label}
                    </HIDTypography>
                  </>
                )
            }
          </Stack>
          <HIDButton
            size="large"
            style={{ alignSelf: 'center', minWidth: 160 }}
            // loading={isConsumableBuying}
            title={t('declarations_buy_for')}
            onClick={handleOrder}
          />
          {!isSfSellerSellingProperty && (
            <Stack
              sx={{
                justifyContent: 'center',
                flexDirection: 'row',
                gap: 0.5,
              }}
            >
              <HIDTypography>
                {t('declarations:declarations_read_our_complete')}
              </HIDTypography>
              <HIDLink
                label={t('declarations:declarations_terms')}
                onClick={() => {
                  openDeductionsTermsAndConditions({
                    title: t('declarations:declarations_full_terms_and_conditions_title'),
                    description: t('declarations:declarations_full_terms_and_conditions_description'),
                  });
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default BuyDeclarationHelp;
