import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useGetEntityInfo from './useGetEntityInfo';

const useGetEntityConnectionsDescription = () => {
  const getEntityInfo = useGetEntityInfo();

  const get = (connectionCountMap: Partial<Record<EntityType, number>>) => Object.entries(connectionCountMap)
    .map(([entityType, count]) => {
      const entityInfo = getEntityInfo(entityType as EntityType);
      if (entityInfo) {
        return `${count} ${count > 1 ? entityInfo.namePlural : entityInfo.name}`;
      }
      return null;
    })
    .filter((connection) => connection !== null)
    .join(', ');

  return get;
};

export default useGetEntityConnectionsDescription;
