import {
  AnalyticsEvent,
  HIDEventCustomCategory,
} from '@house-id/houseid-types/dist/analytics';
import {
  FeatureStatus,
  SubscriptionFeature,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import useDialog from '../../../hooks/useDialog';
import DialogNames from '../../../hooks/useDialog/DialogNames';
import useGetLogAnalyticsEvent from '../../Analytics/hooks/useGetLogAnalyticsEvent';
import useGetIsSubscriptionPlanFeatureEnabled from './useGetIsSubscriptionPlanFeatureEnabled';

type UseTryUseFeatureDialogProps = {
  onAction: () => void;
};

type UseTryUseFeatureDialogParams = {
  subscriptionFeature: SubscriptionFeature;
  onClose?: (status: FeatureStatus) => void;
};

type UseTryUseFeatureDialog = (
  params: UseTryUseFeatureDialogParams,
) => [(props: UseTryUseFeatureDialogProps) => void, boolean];

const useTryUseFeatureDialog: UseTryUseFeatureDialog = ({ subscriptionFeature, onClose }) => {
  const logEvent = useGetLogAnalyticsEvent();

  const {
    getIsSubscriptionPlanFeatureEnabled,
    isLoading,
  } = useGetIsSubscriptionPlanFeatureEnabled(subscriptionFeature);

  const [openTryFeatureDialog] = useDialog(DialogNames.TRY_FEATURE_DIALOG);

  const openDialog = ({ onAction }: UseTryUseFeatureDialogProps) => {
    getIsSubscriptionPlanFeatureEnabled()
      .then(
        ({ isEnabled, featureStatus }) => {
          if (isEnabled) {
            onAction();
          } else {
            logEvent({
              event: AnalyticsEvent.OPEN_SUBSCRIPTION_FEATURE_OFFER_MODAL,
              hidCategory: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
            });
            logEvent({
              event: isEnabled
                ? AnalyticsEvent.SUBSCRIPTION_TRY_FEATURE_TRIAL
                : AnalyticsEvent.SUBSCRIPTION_FEATURE_DISABLED,
              hidCategory: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
            });
            openTryFeatureDialog({
              subscriptionFeature,
              featureIsEnabled: isEnabled,
              featureStatus,
              onPerformAction: onAction,
              onBeforeClose: onClose,
            });
          }
        },
      );
  };

  return [openDialog, isLoading];
};

export default useTryUseFeatureDialog;
