import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { useGetAllReceiptsQuery } from '../../../../modules/Receipt/api/api.receipt';
import { useGetReceiptsDataGridColumns } from '../../../../modules/Receipt/pages/Receipts/Receipts';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';

const ManageReceiptsTab: FC<ManageContentConnectionsTabProps> = ({
  loading,
  selectedItems,
  onSelect,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const receiptsColumns = useGetReceiptsDataGridColumns({
    isSelectionMode: true,
    totalAmountVisible: true,
    totalDeductibleVisible: true,
  });

  const {
    data: receipts = [],
    isLoading: isReceiptsFetching,
  } = useGetAllReceiptsQuery(propertyId ? { propertyId } : skipToken);

  return (
    <ManageConnectionsCommonTab
      columns={receiptsColumns}
      loading={isReceiptsFetching || loading}
      rows={receipts}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManageReceiptsTab;
