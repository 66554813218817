import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  DeductionCategory,
} from '@house-id/houseid-types/dist/deductions';
import {
  Stack,
} from '@mui/system';
import {
  Card,
  useTheme,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import { useGetDeductionsOverviewQuery } from '../api/api.deductions';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import { Icon } from '../../../../../../../../../types/common';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import DeductionsSectionEmptyStateBox from './DeductionsSectionEmptyStateBox';
import { formatMoney } from '../../../../../../../../../utils/string';
import DeductionItemCard from './DeductionItemCard';

type DeductionsSectionProps = {
  deductionCategory: DeductionCategory;
  title: string;
  Icon: Icon;
  emptyStateTitle: string;
  emptyStateDescription: string;
  onAdd: () => void;
};

const DeductionsSection: FC<DeductionsSectionProps> = ({
  deductionCategory,
  Icon,
  title,
  emptyStateTitle,
  emptyStateDescription,
  onAdd,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['declarations', 'deductions']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: deductionsOverview } = useGetDeductionsOverviewQuery(propertyId ? { propertyId } : skipToken);

  const category = deductionsOverview?.categories[deductionCategory];

  const handleAddDeduction = onAdd;

  const hasData = category && (category?.totalAmount > 0 || category?.types.some((type) => type.deductions.length > 0));
  const deductionItems = category?.types.flatMap((type) => type.deductions) || [];

  const isAllTypesAdded = deductionCategory === DeductionCategory.RENOVATION
    ? false
    : category?.types.every((type) => type.deductions.length > 0);

  const totalDeductableAmount = category
    ? Object.values(category.totalDeductableAmounts).reduce((acc, curr) => acc + curr, 0) || 0
    : 0;

  return (
    <Stack gap={3} padding={2.5}>
      {hasData ? (
        <Stack flex={1} gap={2.5}>
          <HIDTypography variant="subtitle1">
            {title}
          </HIDTypography>
          <Card
            sx={{
              backgroundColor: theme.palette.primary.lightest,
              borderRadius: BorderRadius.md,
              padding: theme.spacing(2),
            }}
          >
            <HIDTypography textAlign="center" variant="h4">
              {formatMoney(totalDeductableAmount || 0)}
            </HIDTypography>
            <HIDTypography textAlign="center">
              {t('declarations:total_deduction_amount')}
            </HIDTypography>
          </Card>
          {Boolean(deductionItems.length) && (
            <Stack
              gap={1.5}
            >
              {deductionItems.map((deduction) => (
                <DeductionItemCard deduction={deduction} key={deduction.id} />
              ))}
            </Stack>
          )}
          {!isAllTypesAdded && (
            <HIDButton
              Icon={Add}
              color="secondary"
              size="medium"
              sx={{ alignSelf: 'flex-end' }}
              title={t('declarations:deductions_section_add_more_deductions')}
              onClick={handleAddDeduction}
            />
          )}
        </Stack>
      ) : (
        <DeductionsSectionEmptyStateBox
          Icon={Icon}
          buttonText={t('deductions:deductions_add_deductions')}
          description={emptyStateDescription}
          title={emptyStateTitle}
          onClick={handleAddDeduction}
        />
      )}
    </Stack>

  );
};

export default DeductionsSection;
