import {
  DeclarationCalculationType,
  FinanceInformation,
  FinanceSaleSummary,
} from '@house-id/houseid-types/dist/finances/finances';
import { PropertyType } from '@house-id/houseid-types/dist/property';
import { DeductionCategory } from '@house-id/houseid-types/dist/deductions';

import { HIDApiTags } from '../../../../../api/HIDApiTags';
import { propertyApi } from '../../../api/api.property';
import { PropertyId } from '../../../types/types.property';

export type PropertyFinanceSaleDeclarationFormData = {
  propertyType: PropertyType;
  year: number;
  salesPrice: number;
  salesExpenses: number;
  purchasePrice: number;
  otherDeductions: number;
  improvementExpenditures: number;
  winOrLose: number;
  profit: number;
  loss: number;
  taxToPay: number;
  deduction: number;
};

type PatchPropertyInformationRequest = PropertyId & {
  financeInformation: Partial<FinanceInformation> & {
    nextHomePurchasePrice?: number;
    nextHomeDownPaymentAmount?: number;
    remindingCapitalGainsTaxDeferralPaybackAmount?: number;
    capitalGainsTaxDeferralAmount?: number;
    salesCalculationTemplateId?: DeclarationCalculationType;
    loanRepaymentConfig?: FinanceSaleSummary['loanRepaymentConfig'];
  }
};

type PatchPropertyDeductionRequest = PropertyId & {
  id: string;
  amount: number;
  includeReceipts: boolean;
};

type PostPropertyFinanceSaleDeclarationRequest = PropertyId & {
  data: PropertyFinanceSaleDeclarationFormData;
};

export const propertyFinanceApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyFinanceInformation: builder.query<FinanceInformation, PropertyId>({
      query: ({ propertyId }) => `/properties/${propertyId}/finance/information`,
      providesTags: [HIDApiTags.PROPERTY_FINANCE_INFORMATION],
    }),
    getPropertyFinanceSaleSummary: builder.query<FinanceSaleSummary, PropertyId>({
      query: ({ propertyId }) => `v3/properties/${propertyId}/finance/sale/summary`,
      providesTags: [HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION],
    }),
    patchPropertyFinanceInformation: builder.mutation<FinanceInformation, PatchPropertyInformationRequest>({
      query: ({ propertyId, financeInformation }) => ({
        url: `/properties/${propertyId}/finance/information`,
        method: 'PATCH',
        body: financeInformation,
      }),
      invalidatesTags: () => [
        HIDApiTags.PROPERTY_FINANCE_INFORMATION,
        HIDApiTags.LOANS_OVERVIEW,
        HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION,
        HIDApiTags.PROPERTY_FINANCE,
        HIDApiTags.PROPERTY_PROGRESS,
        HIDApiTags.FINANCE_INFORMATION,
      ],
    }),
    patchPropertyPreliminaryDeductions: builder.mutation<any, PropertyId & { deductions: Record<DeductionCategory, number> }>({
      query: ({ propertyId, deductions }) => ({
        url: `/properties/${propertyId}/finance/sale/deductions`,
        method: 'PATCH',
        body: deductions,
      }),
      invalidatesTags: () => [
        HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION,
      ],
    }),
    patchPropertyDeduction: builder.mutation<FinanceInformation, PatchPropertyDeductionRequest>({
      query: ({
        propertyId,
        id,
        amount,
        includeReceipts,
      }) => ({
        url: `/properties/${propertyId}/finance/sale/deductions/${id}`,
        method: 'PUT',
        body: { amount, includeReceipts },
      }),
      invalidatesTags: () => [HIDApiTags.PROPERTY_FINANCE_INFORMATION, HIDApiTags.PROPERTY_FINANCE_SALE_INFORMATION],
    }),
    postPropertyFinanceSaleDeclaration: builder.mutation<void, PostPropertyFinanceSaleDeclarationRequest>({
      query: ({ propertyId, data }) => ({
        url: `/properties/${propertyId}/finance/sale/declaration`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetPropertyFinanceInformationQuery,
  useGetPropertyFinanceSaleSummaryQuery,
  usePatchPropertyFinanceInformationMutation,
  usePatchPropertyDeductionMutation,
  usePostPropertyFinanceSaleDeclarationMutation,
  usePatchPropertyPreliminaryDeductionsMutation,
} = propertyFinanceApi;
