import React from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';

import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import {
  Icon,
  FCC,
} from '../../../../../../../../../types/common';
import CircleIcon from '../../../../../../../../../components/CircleIcon';

type DeductionsSectionEmptyStateBoxProps = {
  Icon: Icon;
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
};

const DeductionsSectionEmptyStateBox: FCC<DeductionsSectionEmptyStateBoxProps> = ({
  Icon,
  title,
  description,
  buttonText,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Card
      style={{
        padding: theme.spacing(2.5),
        backgroundColor: theme.palette.primary.lightest,
        borderRadius: BorderRadius.md,
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          gap: theme.spacing(2),
          flexDirection: 'column',
        }}
      >
        <CircleIcon
          Icon={Icon}
          iconColor={theme.palette.primary.main}
          iconSize={32}
          size={64}
        />
        <HIDTypography textAlign="center" variant="h5">
          {title}
        </HIDTypography>
        <HIDTypography textAlign="center" variant="body1">
          {description}
        </HIDTypography>
        <HIDButton
          size="medium"
          title={buttonText}
          onClick={onClick}
        >
          {buttonText}
        </HIDButton>
      </Stack>
    </Card>
  );
};

export default DeductionsSectionEmptyStateBox;
