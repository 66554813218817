import React, { useEffect } from 'react';
import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import ReactMarkdown from 'react-markdown';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  AnalyticsEvent,
  HIDEventCustomCategory,
} from '@house-id/houseid-types/dist/analytics';
import {
  SubscriptionPlanId,
} from '@house-id/houseid-types/dist/subscriptionPlans';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';

import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../utils/routes';
import HomeLayout from '../../Property/pages/Home/components/HomeLayout';
import { useGetSubscriptionPlanSettings } from '../hooks/useGetSubscriptionPlanSettings';
import {
  getBuySubscriptionPlanPath,
  getSubscriptionPlansPath,
} from '../navigation/navigation.subscriptionPlans';
import SubscriptionPlanCardHeader from '../components/SubscriptionPlanCardHeader';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import HIDTypography from '../../../components/HIDTypography';
import HIDButton from '../../../components/buttons/HIDButton';
import { getFeedbackOnboardingPath } from '../../Auth/navigation/navigation.auth';
import {
  useGetFormatMonth,
} from '../../../utils/translation';
import { useGetSubscriptionOrderQuery } from '../api/api.subscriptionPlans';
import { SupportTicketCategoryId } from '../../Property/types/types.support';
import useGetLogAnalyticsEvent from '../../Analytics/hooks/useGetLogAnalyticsEvent';
import { SubscriptionPlanOfferPeriodType } from '../types.subscriptionPlans';

type BackendError = {
  status: number;
  data: {
    errors: Array<{ code: string; message: string; }>;
  }
};

const SubscriptionPlanSubscriptionError = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['subscriptions', 'forms_common', 'common']);
  const logEvent = useGetLogAnalyticsEvent();

  const {
    routeParams,
    state,
  } = useNavigationParamsAndState<
  { subscriptionPlanId: SubscriptionPlanId, period: SubscriptionPlanOfferPeriodType, orderId?: string },
  undefined,
  { error?: BackendError }
  >();

  const { isDownSm } = useBreakPointsSizes();
  const formatMonth = useGetFormatMonth();

  const { subscriptionPlanId, orderId } = routeParams;

  const { data: order, error: orderOrderError, isLoading: isOrderLoading } = useGetSubscriptionOrderQuery(
    orderId ? { orderId } : skipToken,
  );
  const isCanceledByUser = order?.state === 'cancelled';

  const { data: subscriptionPlanInfo, isLoading: isSubscriptionPlanLoading } = useGetSubscriptionPlanSettings({ subscriptionPlanId });

  const handleReportIssue = () => navigate(getFeedbackOnboardingPath({
    categoryId: SupportTicketCategoryId.SUBSCRIPTIONS,
  }));

  const handleTryAgain = () => navigate(getBuySubscriptionPlanPath({ subscriptionPlanId }));

  const periodLabel = formatMonth(routeParams.period === SubscriptionPlanOfferPeriodType.YEAR ? 12 : 1);

  const errorCode = R.head((orderOrderError as BackendError || state?.error)?.data?.errors || [])?.code;

  const errorsCodes: Record<string, string> = {
    PENDING_ORDER_ALREADY_EXISTS: t('subscriptions:subscriptions_order_error_pending_order_already_exists'),
    INVALID_SUBSCRIPTION: t('subscriptions:subscriptions_order_error_invalid_subscriptions'),
    NO_PRICE: t('subscriptions:subscriptions_order_error_no_price'),
    INVALID_OFFER: t('subscriptions:subscriptions_order_error_invalid_offer'),
    MANDATE_NOT_ACTIVE: t('subscriptions:subscriptions_order_error_mandate_not_active'),
    UNHANDLED_ERROR: t('subscriptions:subscriptions_order_error_unhandled_error'),
    ACTIVE_MANDATE_ALREADY_EXISTS: t('subscriptions:subscriptions_order_error_active_mandate_already_exists'),
  };

  const errorMessage = isCanceledByUser
    ? ''
    : errorCode
      ? errorsCodes[errorCode] || t('subscriptions:subscriptions_order_error_unhandled_error')
      : t('subscriptions:subscriptions_order_error_unhandled_error');

  const handleGoBack = () => navigateBackOr(getSubscriptionPlansPath(), { replace: true });

  const isLoading = isSubscriptionPlanLoading || (orderId ? isOrderLoading : false);

  useEffect(() => {
    if (order && !isCanceledByUser) {
      logEvent({
        event: AnalyticsEvent.SUBSCRIPTIONS_ACTIVATE_PLAN_ERROR,
        source: PartnersSource.HOUSE_ID,
        hidCategory: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
        params: {
          subscriptionPlanId,
          subscriptionPlanOfferId: order?.offer?.id,
        },
      });
    }
  }, [isCanceledByUser, order]);

  return (
    <HomeLayout
      SideColumn={<div />}
      breadcrumbsLinks={[
        {
          link: getSubscriptionPlansPath(),
          name: t('subscriptions:our_subscription_plans'),
        },
      ]}
      title={t('subscriptions:subscriptions_subscription_activated_title')}
      onBack={handleGoBack}
    >
      <Stack spacing={6}>
        {!isLoading && subscriptionPlanInfo
          ? (
            <SubscriptionPlanCardHeader
              plan={subscriptionPlanId}
            >
              <Stack
                padding={4}
                spacing={4}
              >
                <Stack alignItems="center" spacing={4}>
                  {isCanceledByUser
                    ? (
                      <InfoIcon color="primary" sx={{ height: 100, width: 100 }} />
                    ) : (
                      <CancelIcon color="error" sx={{ height: 100, width: 100 }} />
                    )}
                  <HIDTypography variant={isDownSm ? 'h4' : 'h3'}>
                    {isCanceledByUser
                      ? t('subscriptions:subscriptions_subscription_activated_purchase_canceled')
                      : t('subscriptions:subscriptions_subscription_activated_purchase_error')}
                  </HIDTypography>
                  <Stack>
                    <Stack
                      alignItems="center"
                      direction="row"
                      flexWrap="wrap"
                      spacing={0.5}
                    >
                      <HIDTypography variant="body1">
                        <ReactMarkdown>
                          {t('subscriptions:subscriptions_subscription_activated_was_not_activated_for_period', {
                            planName: subscriptionPlanInfo.name,
                            periodLabel,
                          })}
                        </ReactMarkdown>
                      </HIDTypography>
                    </Stack>
                    {Boolean(errorMessage) && (
                      <HIDTypography variant="body1">
                        {errorMessage}
                      </HIDTypography>
                    )}
                  </Stack>
                  <Stack
                    alignSelf="flex-end"
                    direction="row"
                    flexWrap="wrap"
                    gap={isDownSm ? 2 : 0}
                    spacing={isDownSm ? 0 : 2}
                  >
                    {!isCanceledByUser && (
                      <HIDButton
                        color="secondary"
                        fullWidth={isDownSm}
                        onClick={handleReportIssue}
                      >
                        {t('common:report_issue')}
                      </HIDButton>
                    )}
                    <HIDButton
                      fullWidth={isDownSm}
                      onClick={handleTryAgain}
                    >
                      {t('common:try_again')}
                    </HIDButton>
                  </Stack>
                </Stack>
              </Stack>
            </SubscriptionPlanCardHeader>
          )
          : null}
      </Stack>
    </HomeLayout>
  );
};

export default SubscriptionPlanSubscriptionError;
