import React, { FC } from 'react';
import {
  Card,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import {
  DateTimeFormats,
  formatDate,
} from '../../../utils/date';
import {
  EMPTY_VALUE,
  formatMoney,
} from '../../../utils/string';
import { useNavigateBackOr } from '../../../utils/routes';
import { getPathWithPropertyIdOrInit } from '../../Auth/navigation/navigation.auth';
import PropertySettingsQuickNavigation from '../../Property/components/PropertySettingsQuickNavigation';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../Property/navigation/navigation.property';
import HomeListLayout from '../../Property/pages/Home/components/HomeListLayout';
import SubscriptionPlansQuickNavigation, { SubscriptionPlansActionId } from '../components/SubscriptionPlansQuickNavigation';
import { getSubscriptionPlansPath } from '../navigation/navigation.subscriptionPlans';
import { useGetSubscriptionPlanPaymentsHistoryQuery } from '../api/api.subscriptionPlans';
import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import { useGetSubscriptionPlansStaticSettings } from '../hooks/useGetSubscriptionPlansStaticSettings';
import useGetCurrentUserSubscriptionInfo from '../hooks/useGetCurrentUserSubscriptionInfo';
import { useGetFormatMonth } from '../../../utils/translation';
import { getSubscriptionPlanMonths } from '../utils.subscriptionPlans';
import { SubscriptionHistoryItem } from '../types.subscriptionPlans';

const SubscriptionPlanPaymentsHistory: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const theme = useTheme();
  const { t } = useTranslation(['subscriptions']);
  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: paymentsHistoryItems, isLoading } = useGetSubscriptionPlanPaymentsHistoryQuery();

  const {
    data: { hasActiveWebOwnSubscription } = {},
  } = useGetCurrentUserSubscriptionInfo();

  const { isDownSm } = useBreakPointsSizes();
  const formatMonth = useGetFormatMonth();

  const SubscriptionPlansStaticSettings = useGetSubscriptionPlansStaticSettings();

  const columns: Array<GridColDef> = [
    {
      field: 'purchaseDate',
      headerName: t('subscriptions:subscriptions_payment_history_purchase_date'),
      flex: 0.25,
      renderCell: (params: GridRenderCellParams) => {
        const { purchaseDate } = params?.row as SubscriptionHistoryItem;

        return (
          <Typography noWrap variant="subtitle1">
            {formatDate(new Date(purchaseDate), DateTimeFormats.DATE_ONLY)}
          </Typography>
        );
      },
    },
    hasActiveWebOwnSubscription && {
      field: 'amount',
      headerName: t('subscriptions:subscriptions_payment_history_purchase_amount'),
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => {
        const { amount } = params?.row as SubscriptionHistoryItem;

        return (
          <Typography color={theme.palette.grey[500]} variant="subtitle1">
            {amount !== undefined ? formatMoney(amount) : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    {
      field: 'planId',
      headerName: t('subscriptions:subscriptions_payment_history_subscription_plan'),
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => {
        const { planId, duration } = params?.row as SubscriptionHistoryItem;
        const subscriptionPlanDuration = formatMonth(getSubscriptionPlanMonths(duration));

        return (
          <Typography color={theme.palette.grey[500]} variant="subtitle1">
            {
              planId
                ? `${SubscriptionPlansStaticSettings[planId].name}, ${subscriptionPlanDuration}`
                : EMPTY_VALUE
            }
          </Typography>
        );
      },
    },
    !isDownSm && {
      field: 'platform',
      headerName: t('subscriptions:subscriptions_payment_history_purchase_platform'),
      flex: 0.25,
      renderCell: (params: GridRenderCellParams) => {
        const { platform } = params?.row as SubscriptionHistoryItem;

        return (
          <Typography color={theme.palette.grey[500]} variant="subtitle1">
            {platform}
          </Typography>
        );
      },
    },
  ].filter(Boolean);

  return (
    <HomeListLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <SubscriptionPlansQuickNavigation
            key={PropertySettingsQuickNavigation.name}
            subscriptionsActionId={SubscriptionPlansActionId.SUBSCRIPTION_HISTORY}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getSubscriptionPlansPath(),
          name: t('subscriptions:our_subscription_plans'),
        },
      ]}
      columns={columns}
      getRowId={(row: SubscriptionHistoryItem) => row.id}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'name',
              sort: 'asc',
            },
          ],
        },
      }}
      isLoading={isLoading}
      rows={paymentsHistoryItems || []}
      sideDrawerElements={[
        <SubscriptionPlansQuickNavigation
          key={PropertySettingsQuickNavigation.name}
          subscriptionsActionId={SubscriptionPlansActionId.SUBSCRIPTION_HISTORY}
        />,
      ]}
      title={t('subscriptions:subscription_history')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    />
  );
};

export default SubscriptionPlanPaymentsHistory;
