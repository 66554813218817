import {
  addDays,
} from 'date-fns';
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  FeatureStatus,
  SubscriptionFeature,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import { QueryHook } from '../../../types/common';
import { useGetCurrentSubscriptionPlanQuery } from '../api/api.subscriptionPlans';
import { getRemainingTime } from '../../../utils/date';

type RemainingTime = {
  unit: 'days' | 'hours' | 'minutes',
  value: number;
};

type SubscriptionPlanTrialPeriodInfo = {
  isEnabled: boolean;
  isExpired?: boolean;
  durationDays?: number;
  remainingTime?: RemainingTime
};

const useGetSubscriptionPlanTrialPeriodInfo: QueryHook<SubscriptionPlanTrialPeriodInfo | undefined> = (_args, options) => {
  const {
    data: subscriptionPlan,
    ...rest
  } = useGetCurrentSubscriptionPlanQuery(options?.skip ? skipToken : undefined);

  const data = useMemo(() => {
    if (subscriptionPlan) {
      const planFeature = subscriptionPlan.plan.features[SubscriptionFeature.MULTI_PLATFORMS];
      const userFeature = subscriptionPlan.me[SubscriptionFeature.MULTI_PLATFORMS];

      if (userFeature && userFeature.status === FeatureStatus.ENABLED) {
        return {
          isEnabled: false,
        };
      }

      if (planFeature && planFeature.trial && planFeature.trial.enabled && planFeature.trial.value) {
        const { trialPeriodDays } = planFeature.trial.value as { trialPeriodDays: number };
        const startDate = new Date(userFeature.startDateUsingWebApp);
        const expiredDate = addDays(startDate, trialPeriodDays);
        const remainingTime = getRemainingTime(expiredDate);

        return {
          isEnabled: true,
          isExpired: !remainingTime,
          durationDays: trialPeriodDays,
          remainingTime: remainingTime as RemainingTime | undefined,
        };
      }

      return {
        isEnabled: false,
      };
    }

    return undefined;
  }, [subscriptionPlan]);

  return {
    data,
    ...rest,
  };
};

export default useGetSubscriptionPlanTrialPeriodInfo;
