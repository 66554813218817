import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import { useGetAllProductsQuery } from '../../../../modules/Product/api/api.product';
import { useGetProductsDataGridColumns } from '../../../../modules/Product/pages/Products/Products';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';

const ManageProductsTab: FC<ManageContentConnectionsTabProps> = ({
  loading,
  selectedItems,
  onSelect,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const productsColumns = useGetProductsDataGridColumns({
    isSelectionMode: true,
  });

  const {
    data: { products = [] } = {},
    isLoading: isProductsFetching,
  } = useGetAllProductsQuery(propertyId ? { propertyId } : skipToken);

  return (
    <ManageConnectionsCommonTab
      columns={productsColumns}
      loading={isProductsFetching || loading}
      rows={products}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManageProductsTab;
