import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Brush: Icon = ({
  iconColor: colorProp,
  strokeWidth = 2,
  fillColor: fillColorProp,
  size,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;
  const fillColor = fillColorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path d="M10 13.5V17.2639C9.38625 17.8132 9 18.6115 9 19.5C9 21.1569 10.3431 22.5 12 22.5C13.6569 22.5 15 21.1569 15 19.5C15 18.6115 14.6137 17.8132 14 17.2639V13.5M6 9.5H18M6 2.5H18V11.5C18 12.6046 17.1046 13.5 16 13.5H8C6.89543 13.5 6 12.6046 6 11.5V2.5Z" stroke={color} strokeWidth={strokeWidth} />
      <ellipse
        cx="12"
        cy="19.5"
        fill={fillColor}
        rx="1"
        ry="1"
      />
    </HIDSvgIcon>
  );
};

export default Brush;
