enum DialogNames {
  ADD_CONTENT_DIALOG = 'AddContentDialog',
  ADD_DEDUCTION_RENOVATION_DIALOG = 'AddDeductionRenovationDialog',
  ADD_PASSWORD_AUTH_TO_ACCOUNT_DIALOG = 'AddPasswordAuthToAccountDialog',
  APPLY_PROMO_CODE_DIALOG = 'ApplyPromoCodeDialog',
  ASK_FORUM_QUESTION_DIALOG = 'AskForumQuestionDialog',
  BONUS_OFFER_ACTIVATED_DIALOG = 'BonusOfferActivatedDialog',
  BROKER_VALUATION_DIALOG = 'BrokerValuationDialog',
  CANCEL_SUBSCRIPTION_DIALOG = 'CancelSubscriptionDialog',
  CANCEL_SUBSCRIPTION_QUESTIONNAIRE_DIALOG = 'CancelSubscriptionQuestionnaireDialog',
  CHANGE_INBOUND_EMAIL_NAME_DIALOG = 'ChangeInboundEmailDialog',
  CHANGE_PROPERTY_DISPLAY_NAME = 'ChangePropertyDisplayNameDialog',
  CONNECT_NEW_DEVICE_DIALOG = 'ConnectNewDeviceDialog',
  CONTENT_FILE_PICKER_DIALOG = 'ContentFilePickerDialog',
  CREATE_BUDGET_FROM_EXISTING_DIALOG = 'CreateBudgetFromExistingDialog',
  CREATE_SURVEY_DIALOG = 'CreateSurveyDialog',
  DEDUCTION_TYPE_PICKER_DIALOG = 'DeductionTypePickerDialog',
  DECLARATION_HELP_DIALOG = 'DeclarationHelpDialog',
  DECLARATION_HELP_SUBMISSION_RESULT_DIALOG = 'DeclarationHelpSubmissionResultDialog',
  DELETE_ACCOUNT_DIALOG = 'DeleteAccountDialog',
  DELETE_PROPERTY_DIALOG = 'DeletePropertyDialog',
  DELETE_RECURRING_EXPENSES_BANK_ACCOUNT_DIALOG = 'DeleteRecurringExpensesBankAccountDialog',
  DELETE_RECURRING_EXPENSES_BANK_DIALOG = 'DeleteRecurringExpensesBankDialog',
  END_TRIAL_PERIOD_DIALOG = 'EndTrialPeriodDialog',
  ENROLL_SECOND_FACTOR_DIALOG = 'EnrollSecondFactorDialog',
  ENTER_INVITE_CODE_DIALOG = 'EnterInviteCodeDialog',
  EXPERTS_COMMUNICATION_FORM_DIALOG = 'ExpertsCommunicationFormDialog',
  EXTERNAL_SERVICE_INTRO_DIALOG = 'ExternalServiceIntroDialog',
  INFO_DIALOG = 'InfoDialog',
  INVITE_USER_DIALOG = 'InviteUserDialog',
  LEAVE_PROPERTY_DIALOg = 'LeavePropertyDialog',
  MANAGE_CONTENT_CONNECTIONS_DIALOG = 'ManageContentConnectionsDialog',
  MANAGE_PROPERTY_SELLING_STAGE_CONDITIONS_DIALOG = 'ManagePropertySellingStageConditionsDialog',
  MANAGE_PROPERTY_SOLD_STAGE_CONDITIONS_DIALOG = 'ManagePropertySoldStageConditionsDialog',
  MANAGE_RECURRING_EXPENSE_BANK_ACCOUNT_CONSENTS_DIALOG = 'ManageRecurringExpenseBankAccountConsentsDialog',
  MANAGE_RECURRING_EXPENSES_CANDIDATES_DIALOG = 'ManageRecurringExpensesCandidatesDialog',
  MANAGE_LOAN_REPAYMENT_DIALOG = 'ManageLoanRepaymentDialog',
  MANAGE_DECLARATION_CALCULATION_NEW_PROPERTY_DIALOG = 'ManageDeclarationCalculationNewPropertyDialog',
  MANAGE_PROPERTY_SALES_NUMBERS_DIALOG = 'ManagePropertySaleNumbersDialog',
  MANAGE_PROPERTY_ESTIMATED_DEDUCTIONS_DIALOG = 'ManagePropertyEstimatedDeductionsDialog',
  MDD_PAYMENTS_INTRO_DIALOG = 'MddPaymentsIntroDialog',
  MY_TOTAL_BONUS_POINTS_DIALOG = 'MyTotalBonusPointsDialog',
  NO_INVITED_MEMBERS_DIALOG = 'NoInvitedMembersDialog',
  OPEN_BANK_ID_DIALOG = 'OpenBankIdDialog',
  OPEN_MOBILE_APP_DIALOG = 'OpenMobileAppDialog',
  BUDGET_MANAGE_CATEGORIES_DIALOG = 'BudgetManageCategoriesDialog',
  PRODUCT_EXTERNAL_MEDIA_INFO_DIALOG = 'ProductExternalMediaInfoDialog',
  PRODUCT_TYPE_GUIDE_DIALOG = 'ProductTypeGuideDialog',
  PROMPT_DIALOG = 'PromptDialog',
  PROMPT_LINK_DIALOG = 'PromptLinkDialog',
  RATE_ACTION_DIALOG = 'RateActionDialog',
  RECURRING_EXPENSES_COLLECTION_COMPLETED_DIALOG = 'RecurringExpensesCollectionCompletedDialog',
  REWARD_OFFER_DIALOG = 'RewardOfferDialog',
  SCAN_RECEIPT_DIALOG = 'ScanReceiptDialog',
  SEARCH_PRODUCTS_DIALOG = 'SearchProductsDialog',
  SELECT_RECURRING_EXPENSES_BANK_ACCOUNTS_DIALOG = 'SelectRecurringExpensesBankAccountsDialog',
  SELECT_RECURRING_EXPENSES_DIALOG = 'SelectRecurringExpensesDialog',
  SELLING_PROPERTY_CHECKLIST_INFO_DIALOG = 'SellingPropertyChecklistInfoDialog',
  SELLING_PROPERTY_PROGRESS_DIALOG = 'SellingPropertyProgressDialog',
  SELECT_DEDUCTION_TYPE_DIALOG = 'SelectDeductionTypeDialog',
  SELECT_DEDUCTION_CATEGORY_DIALOG = 'SelectDeductionCategoryDialog',
  SET_PROPERTY_STAGE_DIALOG = 'SetPropertyStageDialog',
  SHOW_DEDUCTION_CALCULATION_RESULT_DIALOG = 'ShowDeductionCalculationResultDialog',
  START_RECURRING_EXPENSES_COLLECTION_DIALOG = 'StartRecurringExpensesCollectionDialog',
  START_TRIAL_PERIOD_DIALOG = 'StartTrialPeriodDialog',
  SUBSCRIPTIONS_SHARING_DIALOG = 'SubscriptionsSharingDialog',
  TRY_FEATURE_DIALOG = 'TryFeatureDialog',
  TUTORIAL_DIALOG = 'TutorialDialog',
  VALUATION_INDICATION_WIZARD_DIALOG = 'ValuationIndicationWizardDialog',
  WAIT_BANK_ID_COMPLETED_DIALOG = 'WaitBankIdCompletedDialog',
  WELCOME_DIALOG = 'WelcomeDialog',
  WELCOME_TO_FORUM_DIALOG = 'WelcomeToForumDialog',
  YES_NO_CANCEL_DIALOG = 'YesNoCancelDialog',
  UPDATE_DEDUCTION_AMOUNT_DIALOG = 'UpdateDeductionAmountDialog',
}

export default DialogNames;
