import React, { FC } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router';

import useBreakPointsSizes from '../../../hooks/useBreakpointsSizes';
import { useNavigationParams } from '../../../utils/routes';
import { getInitAppPath } from '../navigation/navigation.auth';
import HomeLayout from '../../Property/pages/Home/components/HomeLayout';
import HIDButton from '../../../components/buttons/HIDButton';
import InviteCodeImage from '../../../assets/images/invite_code.png';
import {
  useAcceptInviteCodeMutation,
  useDeclineInviteCodeMutation,
  useGetInviteCodeQuery,
} from '../../Property/api/api.inviteCode';
import { hidSpacing } from '../../../utils/number';
import { getTypographyHeight } from '../../../utils/style';
import {
  useGetTokenDataQuery,
  useReloadTokenDataMutation,
} from '../api/api.user';
import { getHomePath } from '../../Property/navigation/navigation.property';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import { useSetUserSettingsMutation } from '../api/api.settings';

// TODO: remove this and add loaders directly into component
const InviteCodeLoading = () => {
  const theme = useTheme();

  const lines = 3;
  const itemSpacing = 0.25;
  const sizeReduce = hidSpacing(((lines - 1) * itemSpacing) / lines);

  return (
    <Stack>
      <Stack spacing={0.75} sx={{ padding: theme.spacing(0.5, 1) }}>
        <Skeleton height={getTypographyHeight(theme.typography.h1) - sizeReduce} variant="rounded" width="100%" />
        <Skeleton height={getTypographyHeight(theme.typography.body2) - sizeReduce} variant="rounded" width="100%" />
        <Skeleton height={getTypographyHeight(theme.typography.body1) - sizeReduce} variant="rounded" width="100%" />
        <Skeleton height={getTypographyHeight(theme.typography.body1) - sizeReduce} variant="rounded" width="100%" />
        <Skeleton height={getTypographyHeight(theme.typography.body1) - sizeReduce} variant="rounded" width="100%" />
        <Skeleton height={getTypographyHeight(theme.typography.body1) - sizeReduce} variant="rounded" width="100%" />
        <Skeleton height={getTypographyHeight(theme.typography.body1) - sizeReduce} variant="rounded" width="100%" />
        <Skeleton height={getTypographyHeight(theme.typography.body1) - sizeReduce} variant="rounded" width="100%" />
        <Skeleton height={getTypographyHeight(theme.typography.h1) - sizeReduce} variant="rounded" width="100%" />
      </Stack>
    </Stack>
  );
};

const InviteCode: FC = () => {
  const { t } = useTranslation(['common', 'property']);
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    routeParams: { code },
  } = useNavigationParams<{ code: string }, undefined>();

  const { data: inviteCode, error: inviteCodeError, isLoading: isInviteCodeLoading } = useGetInviteCodeQuery(code ? { code } : skipToken);

  const [acceptInvitation, { isLoading: isInviteAccepting }] = useAcceptInviteCodeMutation();
  const [declineInvitation, { isLoading: isInviteDeclining }] = useDeclineInviteCodeMutation();
  const [reloadTokenData, { isLoading: isTokenReloading }] = useReloadTokenDataMutation();
  const [setUserSettings] = useSetUserSettingsMutation();

  const { isDownMd } = useBreakPointsSizes();

  const handleAcceptInvite = () => acceptInvitation({ code })
    .unwrap()
    .then(({ propertyId }) => setUserSettings({ currentPropertyId: propertyId }))
    .then(() => reloadTokenData())
    .then(() => navigate(getInitAppPath(), { state: { withInvitation: false } }));

  const handleDeclineInvite = () => declineInvitation({ code })
    .then(() => navigate(getInitAppPath(), { state: { withInvitation: false } }));

  const { data: tokenData } = useGetTokenDataQuery({});

  const handleGoBack = () => {
    if (tokenData?.properties?.length && propertyId) {
      navigate(getHomePath({ propertyId }));
    } else {
      navigate(getInitAppPath(), { state: { withInvitation: false } });
    }
  };

  return (
    <HomeLayout
      onBack={handleGoBack}
    >
      {isInviteCodeLoading
        ? <InviteCodeLoading />
        : (
          <Stack alignItems="center" spacing={2}>
            <Box
              component="img"
              src={InviteCodeImage}
              sx={{
                height: isDownMd ? 150 : 250,
                objectFit: 'scale-down',
                objectPosition: isDownMd ? 'top' : undefined,
                width: '100%',
              }}
            />
            {inviteCodeError ? (
              <>
                <Typography variant="h4">
                  {t('property:property_invite_code_error_title')}
                </Typography>
                <Typography textAlign="center" variant="body1">
                  {t('property:property_invite_code_error_description')}
                </Typography>
                <HIDButton
                  loading={isInviteAccepting || isTokenReloading}
                  onClick={handleGoBack}
                >
                  {t('common:move_on')}
                </HIDButton>
              </>
            ) : (
              <>
                <Typography variant="h4">
                  {t('property:property_invite_code_title')}
                </Typography>
                <Typography textAlign="center" variant="body1">
                  {t(
                    'property:property_invite_code_description_part_1',
                    {
                      userName: inviteCode?.inviterName || '',
                      propertyName: inviteCode?.propertyAddress?.streetAddress || '',
                    },
                  )}
                </Typography>
                <Typography textAlign="center" variant="body1">
                  {t('property:property_invite_code_description_part_2')}

                </Typography>
                <HIDButton
                  loading={isInviteAccepting || isTokenReloading}
                  onClick={handleAcceptInvite}
                >
                  {t('property:property_invite_code_accept')}
                </HIDButton>
                <HIDButton
                  color="secondary"
                  loading={isInviteDeclining}
                  onClick={handleDeclineInvite}
                >
                  {t('property:property_invite_code_decline')}
                </HIDButton>
              </>
            )}

          </Stack>
        )}
    </HomeLayout>
  );
};

export default InviteCode;
