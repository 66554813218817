import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { PropertyType } from '@house-id/houseid-types/dist/property';
import {
  Check,
  Warning,
} from '@mui/icons-material';
import {
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import { DeductionHelpErrorType } from '@house-id/houseid-types/dist/deductions';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import { EMPTY_VALUE } from '../../../../../../../../../utils/string';
import useGetCurrentProperty from '../../../../../../../hooks/useCurrentProperty';
import DeclarationGroups from '../../../components/DeclarationGroups';
import { DeclarationTableItem } from '../../../components/DeclarationTableRow';
import { useGetDeductionsOverviewQuery } from '../api/api.deductions';
import DeclarationHelpSummarySection from './DeclarationHelpSummarySection';
import HIDLink from '../../../../../../../../../components/HIDLink';
import { formatPropertyAddress } from '../../../../../../../utils/utils.address';

const DeclarationSaleInformationSection: FC = () => {
  const theme = useTheme();

  const { t } = useTranslation(['declarations', 'buildings', 'forms_common', 'common']);
  const { data: property } = useGetCurrentProperty();

  const {
    data: deductionsOverview,
    isLoading: isDeductionsOverviewLoading,
  } = useGetDeductionsOverviewQuery(property ? { propertyId: property.id } : skipToken);

  const addressGroup1: Array<DeclarationTableItem> = deductionsOverview?.salesInformation?.property?.type === PropertyType.CONDOMINIUM
    ? [
      {
        id: 'cooperativeOrganizationNumber',
        label: t('buildings:property_structure_cooperative_number'),
        value: deductionsOverview.salesInformation.property.cooperativeOrganizationNumber,
        isImportant: true,
      },
      {
        id: 'cooperativeName',
        label: t('buildings:property_structure_cooperative_name'),
        value: deductionsOverview.salesInformation.property.cooperativeName,
        isImportant: true,
      },
      {
        id: 'apartmentNumber',
        label: t('forms_common:apartment_number'),
        value: deductionsOverview.salesInformation.property.apartmentNumber,
        isImportant: true,
      },
    ]
    : [
      {
        id: 'propertyAddress',
        label: t('common:property_designation'),
        wrapValue: true,
        value: deductionsOverview?.salesInformation?.property?.propertyDesignation,
        isImportant: true,
      },
    ];

  const datesGroup: Array<DeclarationTableItem> = [
    {
      id: 'contractDate',
      label: t('declarations:declarations_bought_the_home'),
      value: deductionsOverview?.salesInformation?.purchase?.contractDate
        ? formatDate(new Date(deductionsOverview?.salesInformation?.purchase?.contractDate), DateTimeFormats.DATE_ONLY)
        : EMPTY_VALUE,
    },
    {
      id: 'soldDate',
      label: t('declarations:declarations_declaration_sales_dates_sold_date'),
      value: deductionsOverview?.salesInformation?.sales?.contractDate
        ? formatDate(new Date(deductionsOverview?.salesInformation?.sales?.contractDate), DateTimeFormats.DATE_ONLY)
        : EMPTY_VALUE,
    },
  ];

  const pricesGroup: Array<DeclarationTableItem> = [
    {
      id: 'sellingPrice',
      label: t('declarations:declarations_declaration_sales_number_selling_price'),
      value: deductionsOverview?.salesInformation?.sales?.amount
        ? deductionsOverview?.salesInformation?.sales?.amount?.amount
        : EMPTY_VALUE,
    },
    {
      id: 'purchasePrice',
      label: t('declarations:declarations_declaration_sales_number_purchase_price'),
      value: deductionsOverview?.salesInformation?.purchase?.amount
        ? deductionsOverview?.salesInformation?.purchase?.amount?.amount
        : EMPTY_VALUE,
    },

  ];

  const handleEditSaleInformation = () => { };

  const address = deductionsOverview?.salesInformation?.address
    ? formatPropertyAddress(deductionsOverview?.salesInformation?.address)
    : EMPTY_VALUE;

  const dataGroups: Array<DeclarationTableItem>[] = [
    addressGroup1,
    datesGroup,
    pricesGroup,
  ];

  const resultType = deductionsOverview?.result?.type;
  const deductionGroup: Array<DeclarationTableItem> = [
    {
      id: 'resultBeforeDeductions',
      labelVariant: 'subtitle1',
      isImportant: true,
      label: resultType === 'profit'
        ? t('declarations:declarations_declaration_profit_before_deductions')
        : t('declarations:declarations_declaration_loss_before_deductions'),
      value: Math.abs(deductionsOverview?.result?.grossBeforeDeductions || 0),
    },
    {
      id: 'resultWithDeductions',
      labelVariant: 'subtitle1',
      isImportant: true,
      label: resultType === 'profit'
        ? t('declarations:declarations_declaration_profit_with_deductions')
        : t('declarations:declarations_declaration_loss_with_deductions'),
      value: Math.abs(deductionsOverview?.result?.gross || 0),
    },
  ];

  const taxDeferralAmountGroup: Array<DeclarationTableItem> = [
    {
      id: 'remindingCapitalGainsTaxDeferralPaybackAmount',
      labelVariant: 'subtitle1',
      isImportant: true,
      label: t('declarations:declarations_reversal_deferred_amounts'),
      value: Math.abs(deductionsOverview?.result?.remindingCapitalGainsTaxDeferralPaybackAmount || 0),
    },
  ];

  const errorTypes = [
    DeductionHelpErrorType.PROPERTY_INFO_MISSING,
    DeductionHelpErrorType.PURCHASE_INFO_MISSING,
    DeductionHelpErrorType.SALES_INFO_MISSING,
  ];
  const hasErrors = Boolean(deductionsOverview?.errors?.filter(({ type }) => errorTypes.includes(type)).length);

  const resultGroup = [
    deductionGroup,
    taxDeferralAmountGroup,
  ];

  return (
    <DeclarationHelpSummarySection
      Icon={() => hasErrors
        ? <Warning color="warning" />
        : <Check color="primary" />}
      RightComponent={(
        <HIDLink
          label={t('forms_common:change')}
          linkColor={theme.palette.primary.dark}
          onClick={handleEditSaleInformation}
        />
      )}
      title={t('declarations:declaration_help_summary_sales_information_section_title')}
      titleContainerSx={{ backgroundColor: theme.palette.primary.lighter }}
    >
      <Stack gap={2.5} padding={2.5}>
        <Stack
          alignItems="center"
          flex={1}
          flexDirection="row"
          gap={1}
          justifyContent="space-between"
        >
          <HIDTypography flex={1} variant="subtitle2">
            {address}
          </HIDTypography>
        </Stack>
        <DeclarationGroups
          groupGap={2.5}
          groups={dataGroups}
          loading={isDeductionsOverviewLoading}
          withDivider={false}
        />
        <Divider />
        <DeclarationGroups
          groupGap={2.5}
          groups={resultGroup}
          loading={isDeductionsOverviewLoading}
        />
      </Stack>
    </DeclarationHelpSummarySection>
  );
};

export default DeclarationSaleInformationSection;
