import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import {
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../../constants/columns';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import {
  formatDate,
  DateTimeFormats,
} from '../../../../../../../../utils/date';
import { EMPTY_VALUE } from '../../../../../../../../utils/string';
import { useGetTasksQuery } from '../../../../modules/GoodToDoTask/api/api.task';
import { Task } from '../../../../modules/GoodToDoTask/types.task';

type UseGetTasksDataGridColumns = (props: {
  isSelectionMode: boolean,
}) => Array<GridColDef>;

const useGetTasksDataGridColumns: UseGetTasksDataGridColumns = ({
  isSelectionMode,
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const { isDownSm } = useBreakPointsSizes();

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownSm ? 0.6 : 0.4,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name } = params?.row as Task;
        return (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ minWidth: 0 }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ minWidth: 0 }}
            >
              <Stack direction="row" sx={{ minWidth: 0 }}>
                <Typography noWrap variant="subtitle1">
                  {name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'expiredAt',
      headerName: t('common:expired_at'),
      flex: isDownSm ? 0.3 : 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { expiration } = params?.row as Task;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {expiration?.date ? formatDate(new Date(expiration?.date), DateTimeFormats.DATE_ONLY) : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    {
      field: 'completedAt',
      headerName: t('common:completed_at'),
      valueGetter: (params: GridRenderCellParams) => (params?.row as Task)?.expiration?.date,
      flex: isDownSm ? 0.3 : 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { completedAt } = params?.row as Task;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {completedAt ? formatDate(new Date(completedAt), DateTimeFormats.DATE_ONLY) : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

const ManageTasksTab: FC<ManageContentConnectionsTabProps> = ({
  loading,
  selectedItems,
  onSelect,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const documentsColumns = useGetTasksDataGridColumns({
    isSelectionMode: true,
  });

  const {
    data: tasks = [],
    isLoading: isTasksFetching,
  } = useGetTasksQuery(propertyId ? { propertyId } : skipToken);

  return (
    <ManageConnectionsCommonTab
      columns={documentsColumns}
      loading={isTasksFetching || loading}
      rows={tasks}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManageTasksTab;
