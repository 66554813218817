import React, { FC } from 'react';
import {
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Add,
  InputOutlined,
} from '@mui/icons-material';
import { SubscriptionFeature } from '@house-id/houseid-types/dist/subscriptionPlans';

import useBreakPointsSizes from '../../../../hooks/useBreakpointsSizes';
import {
  HIDListItemProps,
} from '../../../../types/common';
import { useNavigateBackOr } from '../../../../utils/routes';
import {
  useGetPropertiesQuery,
} from '../../api/api.property';
import useGetCurrentPropertyId from '../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../navigation/navigation.property';
import HomeLayout from '../Home/components/HomeLayout';
import PropertySettingsQuickNavigation, { PropertySettingsActionId } from '../../components/PropertySettingsQuickNavigation';
import ListEntitiesActions from '../../modules/Content/components/actions/ListEntitiesActions';
import useDialog from '../../../../hooks/useDialog';
import DialogNames from '../../../../hooks/useDialog/DialogNames';
import { getPathWithPropertyIdOrInit } from '../../../Auth/navigation/navigation.auth';
import PropertySettingsLoaderSkeleton from './PropertySettingsLoaderSkeleton';
import PropertyListItem from './PropertyListItem';
import useTryUseFeatureDialog from '../../../SubscriptionPlans/hooks/useTryUseFeatureDialog';
import { UnavailableFeature } from '../../components/dialogs/OpenMobileAppDialog';

const PropertySettings: FC = () => {
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();

  const { isDownMd } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: properties = [], isLoading } = useGetPropertiesQuery();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);
  const [openEnterInviteCodeDialog] = useDialog(DialogNames.ENTER_INVITE_CODE_DIALOG);

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
    subscriptionFeaturesIsLoading,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.MULTIPLE_PROPERTIES });

  const handleAddNewProperty = () => proceedToFeatureOrOpenSubscriptionDialog({
    onAction: () => openMobileAppDialog({
      unavailableFeature: UnavailableFeature.ADD_PROPERTY,
    }),
  });

  const customActions: HIDListItemProps[] = [
    {
      id: 'add',
      label: t('property:add_property'),
      Icon: Add,
      disabled: subscriptionFeaturesIsLoading,
      onClick: handleAddNewProperty,
    },
    {
      id: 'accept_invite',
      label: t('property:fill_in_invite_code'),
      Icon: InputOutlined,
      onClick: openEnterInviteCodeDialog,
    },
  ].filter(Boolean);

  return (
    <HomeLayout
      BodyLoaderSkeleton={PropertySettingsLoaderSkeleton}
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions
              customActions={customActions}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <PropertySettingsQuickNavigation currentPropertySettingsActionId={PropertySettingsActionId.PROPERTY_SETTINGS} />
          </Card>
        </Stack>
      }
      isLoading={isLoading}
      sideDrawerElements={[
        <PropertySettingsQuickNavigation
          currentPropertySettingsActionId={PropertySettingsActionId.PROPERTY_SETTINGS}
          key={PropertySettingsQuickNavigation.name}
        />,
        <ListEntitiesActions
          customActions={customActions}
          key={ListEntitiesActions.name}
        />,
      ]}
      title={t('property:property_settings_properties_action')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <Typography>
        {t('property:property_settings_description')}
      </Typography>
      <Stack spacing={2} sx={{ marginTop: isDownMd ? 2 : 0 }}>
        <Grid container rowSpacing={2}>
          {
            properties
              .map((property) => (
                <Grid
                  item
                  key={property.id}
                  xxs={12}
                >
                  <PropertyListItem
                    property={property}
                  />
                </Grid>
              ))
          }
        </Grid>
      </Stack>
    </HomeLayout>
  );
};

export default PropertySettings;
