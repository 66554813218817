import React, { FC } from 'react';

import { useGetRecurringExpensesFiltrationQuery } from '../../../../../Finances/modules/RecurringExpenses/api/api.recurringExpenses';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';
import { useGetRecurringExpensesDataGridColumns } from '../../../../../Finances/modules/RecurringExpenses/pages/RecurringExpenses';

const ManageRecurringExpensesTab: FC<ManageContentConnectionsTabProps> = ({
  loading,
  selectedItems,
  onSelect,
}) => {
  const recurringExpensesColumns = useGetRecurringExpensesDataGridColumns({
    isSelectionMode: true,
  });

  const {
    data,
    isLoading: isExpensesFetching,
  } = useGetRecurringExpensesFiltrationQuery({
    includeIncompleteMonths: true,
  });

  return (
    <ManageConnectionsCommonTab
      columns={recurringExpensesColumns}
      loading={isExpensesFetching || loading}
      rows={data?.expenses || []}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManageRecurringExpensesTab;
