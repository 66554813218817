import { match } from 'ts-pattern';
import {
  SubscriptionPlanPeriodOption,
  SubscriptionPlanProviderId,
  SubscriptionPlanDuration,
  SubscriptionPlanOptionMddBuySettingType,
  SubscriptionPlanOptionOfferMddBuySettings,
  SubscriptionPlanOptionOfferMddRegularBuySettings,
  SubscriptionPlanOptionOfferMddExistingBuySettings,
  SubscriptionPlanOptionOfferMddFutureBuySettings,
  SubscriptionPlanOptionOfferMddUpgradeBuySettings,
  SubscriptionPlanPeriodOptionOfferType,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import {
  SubscriptionPlanInfo,
} from './hooks/useGetSubscriptionPlansSettings';
import {
  SubscriptionPlanOfferByPeriod,
  SubscriptionPlanOfferPeriodType,
  SubscriptionPlanOptionPeriodOfferPrices,
} from './types.subscriptionPlans';

export const getSubscriptionPlanPeriod = (duration?: SubscriptionPlanDuration) =>
  duration?.years && duration?.years > 0
    ? SubscriptionPlanOfferPeriodType.YEAR
    : SubscriptionPlanOfferPeriodType.MONTH;

export const getSubscriptionPlanMonths = (duration?: SubscriptionPlanDuration) => duration
  ? duration.months && duration.months > 0
    ? duration.months
    : duration.years && duration.years > 0
      ? duration.years * 12
      : 0
  : 0;

const getMonthlyPrice = (yearlyPrice: number) => yearlyPrice / 12;

const getOfferPriceFromBuySettings = (yearlySubscriptionPlanOffer: SubscriptionPlanPeriodOption) => {
  const { providerData } = yearlySubscriptionPlanOffer;

  return match(providerData.buySettings.type)
    .with(SubscriptionPlanOptionMddBuySettingType.EXISTING_PLAN, () => {
      const buySettings = providerData.buySettings as SubscriptionPlanOptionOfferMddExistingBuySettings;
      return buySettings.nextCapturePrices.SEK;
    })
    .with(SubscriptionPlanOptionMddBuySettingType.REGULAR, () => {
      const buySettings = providerData.buySettings as SubscriptionPlanOptionOfferMddRegularBuySettings;
      return buySettings.payNow.SEK;
    })
    .with(SubscriptionPlanOptionMddBuySettingType.FUTURE, () => {
      const buySettings = providerData.buySettings as SubscriptionPlanOptionOfferMddFutureBuySettings;
      return yearlySubscriptionPlanOffer.offer?.config?.type === SubscriptionPlanPeriodOptionOfferType.TRIAL
        ? providerData.providerId === SubscriptionPlanProviderId.MDD ? providerData.prices.SEK : 0
        : buySettings.nextCapturePrices?.SEK || 0;
    })
    .with(SubscriptionPlanOptionMddBuySettingType.UPGRADE, () => {
      const buySettings = providerData.buySettings as SubscriptionPlanOptionOfferMddUpgradeBuySettings;
      return buySettings.payNow.SEK;
    })
    .otherwise(() => 0);
};

const getSubscriptionPlanBasePriceWithAnnualPayment = (
  subscriptionPlanInfo: SubscriptionPlanInfo,
  yearlySubscriptionPlanOffer: SubscriptionPlanPeriodOption | undefined,
) =>
  yearlySubscriptionPlanOffer
    && yearlySubscriptionPlanOffer.providerData.providerId === SubscriptionPlanProviderId.MDD
    ? yearlySubscriptionPlanOffer.providerData.prices.SEK
    : subscriptionPlanInfo
      ? subscriptionPlanInfo.pricePerYear
      : 0;

const getSubscriptionPlanCurrentPriceWithAnnualPayment = (
  subscriptionPlanInfo: SubscriptionPlanInfo,
  yearlySubscriptionPlanOffer: SubscriptionPlanPeriodOption | undefined,
) =>
  yearlySubscriptionPlanOffer
    && yearlySubscriptionPlanOffer.providerData.providerId === SubscriptionPlanProviderId.MDD
    ? getOfferPriceFromBuySettings(yearlySubscriptionPlanOffer)
    : subscriptionPlanInfo
      ? subscriptionPlanInfo.pricePerYear
      : 0;

export const getSubscriptionPlanBasePricePerMonth = (
  subscriptionPlanInfo: SubscriptionPlanInfo,
  monthlySubscriptionPlanOffer: SubscriptionPlanPeriodOption | undefined,
) =>
  monthlySubscriptionPlanOffer
    && monthlySubscriptionPlanOffer.providerData.providerId === SubscriptionPlanProviderId.MDD
    ? monthlySubscriptionPlanOffer.providerData.prices.SEK
    : subscriptionPlanInfo?.pricePerMonth || 0;

export const getSubscriptionPlanCurrentPricePerMonth = (
  subscriptionPlanInfo: SubscriptionPlanInfo,
  monthlySubscriptionPlanOffer: SubscriptionPlanPeriodOption | undefined,
) =>
  monthlySubscriptionPlanOffer && monthlySubscriptionPlanOffer.providerData.providerId === SubscriptionPlanProviderId.MDD
    ? getOfferPriceFromBuySettings(monthlySubscriptionPlanOffer)
    : subscriptionPlanInfo?.pricePerMonth || 0;

const getCanActivateOffer = (buySettings: SubscriptionPlanOptionOfferMddBuySettings | undefined, offer: any) =>
  Boolean(buySettings
    && (
      buySettings.type === SubscriptionPlanOptionMddBuySettingType.REGULAR
      || buySettings.type === SubscriptionPlanOptionMddBuySettingType.FUTURE
      || buySettings.type === SubscriptionPlanOptionMddBuySettingType.UPGRADE
      || (buySettings.type === SubscriptionPlanOptionMddBuySettingType.EXISTING_PLAN && offer)));

export const getCurrentSubscriptionPlanPeriod = (subscriptionPlanOffers?: Record<string, SubscriptionPlanOfferByPeriod>) => {
  if (subscriptionPlanOffers) {
    const subscriptionPlanOffersValues = Object.values(subscriptionPlanOffers);
    const existingPlanOffers = subscriptionPlanOffersValues.find(({ periods }) =>
      Object.values(periods).some(
        (option) => option.providerData.buySettings.type === SubscriptionPlanOptionMddBuySettingType.EXISTING_PLAN,
      ));
    if (existingPlanOffers) {
      return getSubscriptionPlanPeriod(
        Object.values(existingPlanOffers.periods).find((option) =>
          option.providerData.buySettings.type === SubscriptionPlanOptionMddBuySettingType.EXISTING_PLAN)?.duration,
      );
    }
  }
  return undefined;
};

export const getSubscriptionPlanOptionPeriodOfferPrices = (
  subscriptionPlanInfo: SubscriptionPlanInfo,
  period: SubscriptionPlanOfferPeriodType,
  subscriptionPlanOffer?: SubscriptionPlanOfferByPeriod,
): SubscriptionPlanOptionPeriodOfferPrices => {
  const periodSubscriptionPlanOffer = subscriptionPlanOffer
    ? subscriptionPlanOffer.periods[period]
    : undefined;

  const providerData = periodSubscriptionPlanOffer?.providerData?.providerId === SubscriptionPlanProviderId.MDD
    ? periodSubscriptionPlanOffer?.providerData
    : undefined;
  const offer = periodSubscriptionPlanOffer?.offer;
  const buySettings = providerData?.buySettings as SubscriptionPlanOptionOfferMddBuySettings;

  const canActivate = providerData?.providerId === SubscriptionPlanProviderId.MDD && periodSubscriptionPlanOffer
    ? Boolean(getCanActivateOffer(buySettings, offer))
    : false;

  if (period === SubscriptionPlanOfferPeriodType.YEAR) {
    const basePricePerYear = getSubscriptionPlanBasePriceWithAnnualPayment(
      subscriptionPlanInfo,
      periodSubscriptionPlanOffer,
    );

    const currentPricePerYear = getSubscriptionPlanCurrentPriceWithAnnualPayment(
      subscriptionPlanInfo,
      periodSubscriptionPlanOffer,
    );

    const basePricePerMonth = basePricePerYear ? getMonthlyPrice(basePricePerYear) : 0;
    const currentPricePerMonth = currentPricePerYear ? getMonthlyPrice(currentPricePerYear) : 0;

    return {
      planId: subscriptionPlanInfo.id,
      period,
      offer,
      basePricePerMonth,
      currentPricePerMonth,
      basePricePerYear,
      currentPricePerYear,
      providerData,
      canActivate,
    };
  }
  const basePricePerMonth = getSubscriptionPlanBasePricePerMonth(
    subscriptionPlanInfo,
    periodSubscriptionPlanOffer,
  );

  const currentPricePerMonth = getSubscriptionPlanCurrentPricePerMonth(
    subscriptionPlanInfo,
    periodSubscriptionPlanOffer,
  );

  const basePricePerYear = basePricePerMonth ? basePricePerMonth * 12 : 0;
  const currentPricePerYear = currentPricePerMonth ? currentPricePerMonth * 12 : 0;

  return {
    planId: subscriptionPlanInfo.id,
    period,
    offer,
    basePricePerMonth,
    currentPricePerMonth,
    basePricePerYear,
    currentPricePerYear,
    providerData,
    canActivate,
  };
};

export const hasSubscriptionPlanPeriodOptionOfferTrial = (
  type: SubscriptionPlanPeriodOptionOfferType | undefined,
): type is SubscriptionPlanPeriodOptionOfferType.TRIAL =>
  type ? [SubscriptionPlanPeriodOptionOfferType.TRIAL, SubscriptionPlanPeriodOptionOfferType.TRIAL_AND_DISCOUNT].includes(type) : false;

export const hasSubscriptionPlanPeriodOptionOfferDiscount = (
  type: SubscriptionPlanPeriodOptionOfferType | undefined,
): type is SubscriptionPlanPeriodOptionOfferType.DISCOUNT =>
  type ? [SubscriptionPlanPeriodOptionOfferType.DISCOUNT, SubscriptionPlanPeriodOptionOfferType.TRIAL_AND_DISCOUNT].includes(type) : false;
