import { PropertyProgressCategorySection } from '@house-id/houseid-types/dist/propertyProgress';

import {
  Archive,
  Blueprints,
  CheckMarkRect,
  DocMoney,
  Document,
  HandOver,
  HomeIcon,
  HomeSettings,
  HomeSuccess,
  Offer,
  PieChart,
  Product,
  Prospect,
  Shield,
  Suggestion,
  Task,
  Timeline,
  Truck,
} from '../../../../../components/icons/Icons';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { Icon } from '../../../../../types/common';
import {
  getPropertySettingsPath,
  useNavigateToMove,
} from '../../../navigation/navigation.property';
import {
  getSellingPropertyArchivePath,
  getSellingPropertyHandoverPath,
  getSellingPropertyInformationPath,
  getSellingPropertyChecklistPath,
} from '../../SellingProperty/navigation.sellingProperty';
import {
  getPropertyPurchasePath,
  getPropertySpecificationPath,
  getPropertyBlueprintsPath,
  getPropertyDocumentationPath,
  getPropertyPlanningPath,
  getPropertyFinancePath,
  getPropertyInsuranceAndSafetyPath,
  getPropertyProductsPath,
  getPropertyToDoPath,
} from '../navigation.propertyProgress';
import HomeProfile from '../../../../../components/icons/HomeProfile';
import { getSuggestionCategoriesPath } from '../../Content/modules/Suggestion/navigation.suggestion';
import { UnavailableFeature } from '../../../components/dialogs/OpenMobileAppDialog';
import { getBonusOffersPath } from '../../BonusOffers/navigation.bonusOffers';
import { getDeclarationCalculationPath } from '../../Finances/modules/Declaration/navigation.declaration';

type PropertyProgressCategoryMapDetails = {
  getActionPath?: ({ propertyId }: { propertyId: string }) => string;
  onClick?: ({ propertyId }: { propertyId: string }) => void;
  Icon: Icon;
};

type UseGetPropertyProgressCategoryMap = () => Record<string, PropertyProgressCategoryMapDetails>;

const useGetPropertyProgressCategoryMap: UseGetPropertyProgressCategoryMap = () => {
  const navigateToMove = useNavigateToMove();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  return {
    [PropertyProgressCategorySection.SUGGESTION]: {
      getActionPath: getSuggestionCategoriesPath,
      Icon: Suggestion,
    },
    [PropertyProgressCategorySection.PURCHASE]: {
      getActionPath: getPropertyPurchasePath,
      Icon: Prospect,
    },
    [PropertyProgressCategorySection.SPECIFICATIONS]: {
      getActionPath: getPropertySpecificationPath,
      Icon: HomeIcon,
    },
    [PropertyProgressCategorySection.BLUEPRINTS]: {
      getActionPath: getPropertyBlueprintsPath,
      Icon: Blueprints,
    },
    [PropertyProgressCategorySection.DOCUMENTS]: {
      getActionPath: getPropertyDocumentationPath,
      Icon: Document,
    },
    [PropertyProgressCategorySection.SETTINGS]: {
      getActionPath: getPropertySettingsPath,
      Icon: HomeSettings,
    },
    [PropertyProgressCategorySection.TODO]: {
      getActionPath: getPropertyToDoPath,
      Icon: Task,
    },
    [PropertyProgressCategorySection.PLANNING]: {
      getActionPath: getPropertyPlanningPath,
      Icon: Timeline,
    },
    [PropertyProgressCategorySection.FINANCE]: {
      getActionPath: getPropertyFinancePath,
      Icon: PieChart,
    },
    [PropertyProgressCategorySection.INSURANCE_AND_SAFETY]: {
      getActionPath: getPropertyInsuranceAndSafetyPath,
      Icon: Shield,
    },
    [PropertyProgressCategorySection.OFFERS]: {
      onClick: getBonusOffersPath,
      getActionPath: undefined,
      Icon: Offer,
    },
    [PropertyProgressCategorySection.PRODUCTS]: {
      getActionPath: getPropertyProductsPath,
      Icon: Product,
    },
    [PropertyProgressCategorySection.SALE_CHECKLIST]: {
      getActionPath: getSellingPropertyChecklistPath,
      Icon: CheckMarkRect,
    },
    [PropertyProgressCategorySection.DECLARATION]: {
      getActionPath: getDeclarationCalculationPath,
      Icon: DocMoney,
    },
    [PropertyProgressCategorySection.REALTOR_PROPERTY_INFORMATION]: {
      getActionPath: getSellingPropertyInformationPath,
      Icon: HomeSuccess,
    },
    [PropertyProgressCategorySection.MOVING_SERVICES]: {
      onClick: navigateToMove,
      Icon: Truck,
    },
    [PropertyProgressCategorySection.HANDOVER]: {
      getActionPath: getSellingPropertyHandoverPath,
      Icon: HandOver,
    },
    [PropertyProgressCategorySection.ARCHIVE]: {
      getActionPath: getSellingPropertyArchivePath,
      Icon: Archive,
    },
    [PropertyProgressCategorySection.HOME_PROFILE]: {
      onClick: () => openMobileAppDialog({ unavailableFeature: UnavailableFeature.HOME_PROFILE }),
      getActionPath: undefined,
      Icon: HomeProfile,
    },
  };
};

export default useGetPropertyProgressCategoryMap;
