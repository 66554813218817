import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Deduction,
  AmountSpecificationType,
} from '@house-id/houseid-types/dist/deductions';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { FormikProps } from 'formik';
import { EntityContentConnection } from '@house-id/houseid-types/dist/content/entityContentConnection';

import DeclarationTableRow from '../../../../../components/DeclarationTableRow';
import HIDTypography from '../../../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../../../constants/borderRadius';
import useGetCurrentPropertyId from '../../../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetDeductionsTypesQuery } from '../../../api/api.deductions';
import { CreateEditDeductionValues } from '../types';
import ReceiptsMissedAmount from './ReceiptsMissedAmount';
import HIDTextField, { CurrencyAdornment } from '../../../../../../../../../../../components/HIDTextField';
import HIDInfo from '../../../../../../../../../../../components/HIDInfo';
import DeductionCardContainer from './DeductionCardContainer';
import EntityConnectionsGroup from '../../../../../../../../Content/components/EntityConnectionsGroup';

type DeductionReceiptsAndAmountsProps = {
  type: string | undefined;
  formik: FormikProps<CreateEditDeductionValues>;
  deduction: Deduction | undefined,
  onValueChanged: (field: string, value: any) => void,
  onConnectedReceiptsChange: (receipts: string[]) => void,
};

const DeductionReceiptsAndAmounts: FC<DeductionReceiptsAndAmountsProps> = ({
  type,
  formik,
  deduction,
  onValueChanged,
  onConnectedReceiptsChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['deductions', 'forms_common', 'finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const receiptEntityContentConnections: Array<EntityContentConnection> = deduction?.receipts?.map((receiptConnection) => ({
    type: EntityType.RECEIPT,
    entity: {
      ...receiptConnection.receipt,
      image: receiptConnection.receipt.mainImage as unknown as EntityContentConnection['entity']['image'],
    },
    details: { amount: receiptConnection.amount },
    connectionType: '',
  } as unknown as EntityContentConnection)) || [];

  const receiptsMap = receiptEntityContentConnections.length > 0 ? { [EntityType.RECEIPT]: receiptEntityContentConnections } : {};

  const {
    data: deductionTypes,
  } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const currentType = deductionTypes?.find((deductionType) => deductionType.id === type || deductionType.id === formik.values.type);
  const hasType = Boolean(formik.values.type);

  const showReceiptsSummary = hasType && deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR
    ? Object.values(deduction.yearSummary).some((yearSummary) => yearSummary.totalAmount || yearSummary.totalReceiptAmount)
    : Boolean(deduction && (deduction.summary.totalAmount || deduction.summary.totalReceiptAmount));

  const costPerDeductionTypeName = t('deductions:create_edit_deductions_cost_per_type', { typeName: currentType?.name?.toLowerCase() });

  const handleReceiptsChange = (receipt: Record<string, string[]>) => {
    const receiptIds = receipt[EntityType.RECEIPT] || [];
    onConnectedReceiptsChange(receiptIds);
  };

  return (
    <DeductionCardContainer
      sx={
        !showReceiptsSummary ? { paddingY: theme.spacing(2.5) } : undefined
      }
    >
      <Stack gap={2} sx={[{ paddingX: theme.spacing(2.5) }, showReceiptsSummary && { paddingTop: theme.spacing(2.5) }]}>
        <HIDInfo
          description={t('deductions:deduction_how_much_it_cost_description')}
          label={costPerDeductionTypeName}
          labelVariant="subtitle1"
        />
        <HIDTextField
          required
          endAdornment={<CurrencyAdornment />}
          helperText={formik.touched.amount && formik.errors.amount ? formik.errors.amount : undefined}
          inputProps={{ min: 0 }}
          label={costPerDeductionTypeName}
          value={formik.values.amount}
          onBlur={formik.handleBlur('amount')}
          onChange={(value) => onValueChanged('amount', value.target.value)}
        />
        <Stack gap={2}>
          <Stack gap={0.5}>
            <HIDTypography variant="subtitle1">
              {t('deductions:deduction_sales_expenses_receipts_associated_with_action')}
            </HIDTypography>
            <HIDTypography variant="body1">
              {t('deductions:deduction_sales_expenses_receipts_associated_with_action_description')}
            </HIDTypography>
          </Stack>
          <EntityConnectionsGroup
            addButtonText={t('deductions:add_receipt')}
            connectionsMap={receiptsMap}
            entityId={undefined}
            entityType={EntityType.DEDUCTION}
            sections={[EntityType.RECEIPT]}
            onChange={(data) => handleReceiptsChange(data)}
          />
        </Stack>
      </Stack>
      {showReceiptsSummary && (
        <Stack
          gap={1.5}
          padding={2.5}
          style={{
            backgroundColor: theme.palette.grey[200],
            borderBottomLeftRadius: BorderRadius.md,
            borderBottomRightRadius: BorderRadius.md,
          }}
        >
          <Stack gap={1}>
            <HIDTypography variant="subtitle1">
              {costPerDeductionTypeName}
            </HIDTypography>
            <DeclarationTableRow
              item={{
                id: 'summary',
                label: t('finances:amount'),
                value: Number(deduction?.summary.totalAmount || 0),
              }}
            />
          </Stack>
          <Stack gap={1}>
            <HIDTypography variant="subtitle1">
              {t('deductions:create_edit_deductions_connected_receipts_amount')}
            </HIDTypography>
            <DeclarationTableRow
              item={{
                id: 'summary',
                label: t('finances:amount'),
                value: deduction?.summary?.totalReceiptAmount || 0,
              }}
            />
          </Stack>
          <ReceiptsMissedAmount deduction={deduction} />
        </Stack>
      )}
    </DeductionCardContainer>
  );
};

export default DeductionReceiptsAndAmounts;
