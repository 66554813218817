import React from 'react';
import {
  Divider,
  Stack,
} from '@mui/material';
import { StackProps } from '@mui/system';

import DeclarationTable from './DeclarationTable';
import { DeclarationTableItem } from './DeclarationTableRow';

type DeclarationGroupsProps = {
  groups: DeclarationTableItem[][];
  loading?: boolean;
  withDivider?: boolean;
  groupGap?: StackProps['gap']
};

const DeclarationGroups: React.FC<DeclarationGroupsProps> = ({
  groups,
  loading,
  withDivider = true,
  groupGap,
}) => (
  <Stack gap={groupGap || 1.5}>
    {groups.map((items, index) => (
      <React.Fragment key={index}>
        <DeclarationTable
          items={items}
          loading={loading}
        />
        {(withDivider && groups.length !== (index + 1)) && (<Divider />)}
      </React.Fragment>
    ))}
  </Stack>
);
export default DeclarationGroups;
