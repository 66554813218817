enum RouteNames {
  ANY_PAGE = '*',
  ROOT = '/',
  INIT = '/init',

  PARTNERS = '/partners',
  PARTNERS_LOGIN = '/partners/login',
  PARTNERS_HOME = '/partners/home',

  PARTNERS_FORUM = '/partners/forum',
  PARTNERS_FORUM_TOPIC = '/partners/forum/:topicId',
  PARTNERS_FORUM_THREAD = '/partners/forum/:topicId/thread/:threadId',
  PARTNERS_FORUM_EXPERTS = '/partners/forum/experts',

  LOGIN = '/login',
  LOGIN_SECOND_FACTOR = '/login-2-fa',
  SIGN_UP = '/sign-up',
  ONBOARDING = '/onboarding',
  INVITE_CODE = '/onboarding/invite-code/:code',
  FORGOT_PASSWORD = '/forgot-password',
  ABOUT = '/about',

  ADMIN_HOME = '/admin',

  HOME = '/properties/:propertyId',
  PROPERTY_HOME = '/properties/:propertyId/home',

  PROPERTY_PROGRESS = '/properties/:propertyId/progress',
  PROPERTY_PROGRESS_SPECIFICATION = '/properties/:propertyId/progress/property-specification',
  PROPERTY_BLUEPRINTS = '/properties/:propertyId/progress/property-blueprints',
  PROPERTY_PURCHASE = '/properties/:propertyId/progress/property-purchase',
  PROPERTY_TODO = '/properties/:propertyId/progress/to-do',
  PROPERTY_PLANNING = '/properties/:propertyId/progress/property-planning',
  PROPERTY_DOCUMENTATION = '/properties/:propertyId/progress/property-documentation',
  PROPERTY_PRODUCTS = '/properties/:propertyId/progress/property-products',
  PROPERTY_INSURANCE_AND_SAFETY = '/properties/:propertyId/progress/insurance-and-safety',
  PROPERTY_FINANCE = '/properties/:propertyId/progress/finance',

  PROPERTY_SETTINGS = '/properties/:propertyId/settings',
  PROPERTY_STORAGE = '/properties/:propertyId/storage',
  USER_STORAGE = '/storage',

  EXTERNAL_SERVICES = '/properties/:propertyId/external-services',

  SELLING_PROPERTY_CHECKLIST = '/properties/:propertyId/progress/sales-checklist',
  SELLING_PROPERTY_INFO = '/properties/:propertyId/progress/information-from-sf',
  SELLING_PROPERTY_ARCHIVE = '/properties/:propertyId/progress/archive',
  SELLING_PROPERTY_TRANSFER = '/properties/:propertyId/progress/handover',

  BONUS_OFFERS = '/properties/:propertyId/bonus-offers',
  BONUS_OFFERS_MY_BONUS_POINTS = '/properties/:propertyId/my-bonus-points',
  BONUS_OFFERS_LOGS = '/properties/:propertyId/bonus-offers-logs',
  BONUS_OFFERS_FROM_MOVE_IN = '/properties/:propertyId/move-in-bonus-offers',
  BONUS_OFFER_DETAILS = '/properties/:propertyId/bonus-offer-details/:bonusOfferId/:categoryId',

  SEARCH_RESULTS = '/properties/:propertyId/search',

  RECEIPTS_CATEGORIES = '/properties/:propertyId/receipts',
  RECEIPTS = '/properties/:propertyId/receipts/:category',
  RECEIPT = '/properties/:propertyId/receipts/:category/receipt/:id',
  RECEIPT_CREATE = '/properties/:propertyId/receipts/:category/create',
  RECEIPT_UPDATE = '/properties/:propertyId/receipts/:category/update/:id',

  DOCUMENTS_CATEGORIES = '/properties/:propertyId/documents',
  DOCUMENTS = '/properties/:propertyId/documents/:category',
  DOCUMENT = '/properties/:propertyId/documents/:category/document/:id',
  DOCUMENT_CREATE = '/properties/:propertyId/documents/:category/create',
  DOCUMENT_UPDATE = '/properties/:propertyId/documents/:category/update/:id',

  PHOTOS = '/properties/:propertyId/photos',
  PHOTOS_CREATE = '/properties/:propertyId/photos/create',
  PHOTOS_UPDATE = '/properties/:propertyId/photos/update',

  CONTENT_FILES = '/properties/:propertyId/files',

  NOTES = '/properties/:propertyId/notes',
  NOTE = '/properties/:propertyId/notes/:id',
  NOTE_CREATE = '/properties/:propertyId/notes/create',
  NOTE_UPDATE = '/properties/:propertyId/notes/update/:id',

  PRODUCTS = '/properties/:propertyId/products',
  PRODUCT = '/properties/:propertyId/products/:id',
  PRODUCT_CREATE = '/properties/:propertyId/products/create',
  PRODUCT_UPDATE = '/properties/:propertyId/products/update/:id',

  TIMELINE = '/properties/:propertyId/timeline',
  TIMELINE_EVENT = '/properties/:propertyId/timeline/:id',
  TIMELINE_CREATE_EVENT = '/properties/:propertyId/timeline/create',
  TIMELINE_UPDATE_EVENT = '/properties/:propertyId/timeline/update/:id',

  SUGGESTION_CATEGORIES = '/properties/:propertyId/suggestions',
  SUGGESTIONS = '/properties/:propertyId/suggestions/:entityType',
  SUGGESTION_SETTINGS = '/properties/:propertyId/suggestions/settings',

  MESSAGES = '/properties/:propertyId/messages',
  MESSAGE = '/properties/:propertyId/messages/:id',

  USER_SETTINGS = '/properties/:propertyId/user-settings',
  USER_SETTINGS_ONBOARDING = '/onboarding/user-settings',

  FEEDBACK = '/properties/:propertyId/feedback',
  FEEDBACK_ONBOARDING = '/onboarding/feedback',

  RESET_PASSWORD = '/properties/:propertyId/reset-password',
  RESET_PASSWORD_ONBOARDING = '/onboarding/reset-password',

  USER_DETAILS = '/properties/:propertyId/user-details',
  USER_DETAILS_ONBOARDING = '/onboarding/user-details',

  FORUM = '/forum',
  FORUM_TOPIC = '/forum/:topicId',
  FORUM_THREAD = '/forum/:topicId/thread/:threadId',
  FORUM_EXPERTS = '/forum/experts',
  FORUM_MY_QUESTIONS = '/forum/my-questions',
  FORUM_MY_BOOKMARKS = '/forum/bookmarks',

  SUBSCRIPTION_PLANS = '/subscription-plans',
  SUBSCRIPTION_PLAN = '/subscription-plans/:subscriptionPlanId',
  SUBSCRIPTION_PLAN_BUY_SUBSCRIPTION = '/subscription-plans/:subscriptionPlanId/subscription/buy',
  SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER = '/subscription-plans/subscription/orders/:orderId',
  SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER_ERROR = '/subscription-plans/:subscriptionPlanId/:period/subscription/orders/:orderId/error',
  SUBSCRIPTION_PLAN_SUBSCRIPTION_ERROR = '/subscription-plans/:subscriptionPlanId/:period/subscription/error',
  SUBSCRIPTION_PLANS_SUBSCRIPTION_PAYMENTS_HISTORY = '/subscription-plans/subscription/payments-history',

  MOVE = '/move',

  FINANCES = '/properties/:propertyId/finances',

  BUDGETS_OVERVIEW = '/properties/:propertyId/budgets-overview',
  BUDGET_CATEGORY = '/properties/:propertyId/budgets/:id',

  BUDGETS_CREATE = '/properties/:propertyId/budgets/create',
  BUDGETS_UPDATE = '/properties/:propertyId/budgets/update/:id',

  RECURRING_EXPENSES_CATEGORIES = '/properties/:propertyId/recurring-expenses-categories',
  RECURRING_EXPENSES_CATEGORY = '/properties/:propertyId/recurring-expenses-categories/:categoryId',
  RECURRING_EXPENSES_BANK_ACCOUNTS = '/properties/:propertyId/recurring-expenses-bank-accounts',
  RECURRING_EXPENSE = '/properties/:propertyId/recurring-expenses/:id',
  RECURRING_EXPENSE_UPDATE = '/properties/:propertyId/recurring-expenses/update/:id',

  VALUATIONS = '/properties/:propertyId/valuations',
  VALUATION_OVERVIEW = '/properties/:propertyId/valuation-overview',
  VALUATION_CREATE = '/properties/:propertyId/valuations/create',
  VALUATION_UPDATE = '/properties/:propertyId/valuations/update/:id',
  VALUATION_HISTORY = '/properties/:propertyId/valuations/history',
  VALUATION_BOOK_MEETING = '/properties/:propertyId/valuations/meeting',

  DECLARATION_CALCULATIONS = '/properties/:propertyId/declaration-calculations',
  DECLARATION_HELP_OVERVIEW = '/properties/:propertyId/declaration-help-overview',
  BUY_DECLARATION_HELP = '/properties/:propertyId/buy-declaration-help',
  DEDUCTION_CREATE = '/properties/:propertyId/deduction/create',
  DEDUCTION_UPDATE = '/properties/:propertyId/deduction/update/:id',
}

export default RouteNames;
