import {
  FaqOverview,
  FaqQuestion,
} from '@house-id/houseid-types/dist/faq';

import { userApi } from '../../../api/api.user';

export const faqApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaqCategories: builder.query<FaqOverview, void>({
      query: () => '/faq/categories',
    }),
    getFaqCategory: builder.query<Array<FaqQuestion>, { categoryId: string }>({
      query: ({ categoryId }) => `/faq/categories/${categoryId}`,
    }),
  }),
});

export const {
  useGetFaqCategoriesQuery,
  useGetFaqCategoryQuery,
} = faqApi;
