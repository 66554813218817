import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTheme,
} from '@mui/material';

import DeclarationCalculationsSection from './DeclarationCalculationsSection';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../../../../../types/common';
import DeclarationGroups from '../../../components/DeclarationGroups';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import useGetDeductionCalculationSections, { DeductionCalculationSectionType } from '../../../hooks/useGetDeductionCalculationSections';

type DeclarationCalculationsIncomeStatementProps = {
  hideActions?: boolean;
};

const DeclarationCalculationsIncomeStatement: FCC<DeclarationCalculationsIncomeStatementProps> = ({
  sx,
  hideActions = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['declarations']);

  const {
    data: groups = [],
    isLoading: isDeductionCalculationSectionsLoading,
    isFetching: isDeductionCalculationSectionsFetching,
  } = useGetDeductionCalculationSections({ section: DeductionCalculationSectionType.INCOME_STATEMENT });

  const loading = isDeductionCalculationSectionsLoading || isDeductionCalculationSectionsFetching;

  const [openManageLoanRepaymentModal] = useDialog(DialogNames.MANAGE_LOAN_REPAYMENT_DIALOG);

  const handleManageLoanRepayment = openManageLoanRepaymentModal;

  return (
    <DeclarationCalculationsSection
      sx={sx}
      title={t('declarations:declarations_declaration_income_statement_title')}
    >
      <DeclarationGroups groups={groups} loading={loading} />
      {!hideActions && (
        <HIDButton
          color="secondary"
          size="medium"
          sx={{ alignSelf: 'flex-end', marginTop: theme.spacing(2) }}
          onClick={handleManageLoanRepayment}
        >
          {t('declarations:declarations_manage_numbers')}
        </HIDButton>
      )}
    </DeclarationCalculationsSection>
  );
};

export default DeclarationCalculationsIncomeStatement;
