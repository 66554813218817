import React, {
  memo,
  useMemo,
  useState,
} from 'react';
import * as R from 'ramda';
import {
  useDebounce,
  useUpdateEffect,
} from 'usehooks-ts';

import { FCC } from '../../../../../types/common';
import { getObjDiff } from '../../../../../utils/object';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import DynamicContentInputForm, {
  getDynamicFormInitialValues,
  getDynamicComponentFormEditableValues,
} from '../../Content/modules/DynamicContent/components/dynamicInputForm/DynamicContentInputForm';
import {
  PropertyProgressCategorySectionProps,
  PropertyProgressDynamicContentSectionData,
} from '../types.propertyProgress';
import PropertyProgressCategorySectionContainer from './PropertyProgressCategorySectionContainer';

type PropertyProgressDynamicContentSectionProps = PropertyProgressCategorySectionProps<PropertyProgressDynamicContentSectionData> & {
  loading?: boolean;
  onUpdate?: (values: Record<string, unknown>) => void;
};

const PropertySpecificationSectionCardPropsComparer = (
  prev: PropertyProgressDynamicContentSectionProps,
  next: PropertyProgressDynamicContentSectionProps,
) =>
  prev.section.id === next.section.id
  && R.equals(prev.section.data.components, next.section.data.components)
  && R.equals(prev.section.progress, next.section.progress);

const PropertyProgressDynamicContentSection: FCC<PropertyProgressDynamicContentSectionProps> = ({
  loading,
  section,
  onUpdate,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const [initialValues, setInitialValues] = useState(getDynamicFormInitialValues(section?.data?.components));
  const [formValues, setFormValues] = useState(initialValues);
  const debouncedValues = useDebounce(formValues, 500);

  const handleUpdate = (difference: Record<string, unknown>) => {
    onUpdate?.(difference);
  };

  useUpdateEffect(() => {
    if (propertyId && !R.equals(debouncedValues, initialValues)) {
      setInitialValues(debouncedValues);

      const difference = getObjDiff(
        getDynamicComponentFormEditableValues(section?.data?.components, initialValues),

        debouncedValues,
      );
      if (!R.isEmpty(difference)) {
        handleUpdate(difference);
      }
    }
  }, [debouncedValues, propertyId]);

  const Form = useMemo(() => (
    <DynamicContentInputForm
      enableReinitializeValues={false}
      fields={section?.data?.components}
      onValuesChanged={(data) => setFormValues(data)}
    />
  ), [section]);

  return (
    <PropertyProgressCategorySectionContainer
      Content={Form}
      isLoading={loading}
      key={section.id}
      section={section}
    />
  );
};

export default memo(PropertyProgressDynamicContentSection, PropertySpecificationSectionCardPropsComparer);
