import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const Robot: Icon = ({
  iconColor: colorProp,
  size,
  strokeWidth = 2,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <rect
        height={13}
        rx={2}
        stroke={color}
        strokeWidth={strokeWidth}
        width={14}
        x={5}
        y={9}
      />
      <path
        d="M2 13H5V18H2z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M19 13H22V18H19z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={9.5}
        cy={13.5}
        fill={color}
        r={1.5}
      />
      <circle
        cx={12}
        cy={4}
        r={2}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={14.5}
        cy={13.5}
        fill={color}
        r={1.5}
      />
      <path
        d="M10 18.5h4M12 6v3"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth * 0.83}
      />
    </HIDSvgIcon>
  );
};

export default Robot;
