import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FeatureStatus } from '@house-id/houseid-types/dist/subscriptionPlans';

import { BorderRadius } from '../../../../../../constants/borderRadius';
import HIDTypography from '../../../../../../components/HIDTypography';
import useGetPropertyDeclarationHelpFeatureAvailable
  from '../../../Finances/modules/Declaration/modules/DeclarationHelp/hooks/useGetPropertyDeclarationHelpFeatureAvailable';
import HIDButton from '../../../../../../components/buttons/HIDButton';
import HIDImage from '../../../../../../components/image/HIDImage';
import SalesDocuments from '../../../../../../assets/images/sales_documents.png';

const DeclarationHelpCard: FC = () => {
  const { t } = useTranslation(['declarations']);
  const theme = useTheme();

  const { data: declarationHelpFeature } = useGetPropertyDeclarationHelpFeatureAvailable();

  const handleNavigateToDeclarationHelp = () => {
    // TODO: implement
  };

  return (
    <Card
      sx={{
        width: '100%',
        padding: 3,
        paddingBottom: 0,
        borderRadius: BorderRadius.md,
        backgroundColor: theme.palette.common.skattio,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
        }}
      >
        <Stack sx={{ gap: 2.5, paddingBottom: 3 }}>
          <HIDTypography
            variant="h3"
          >
            {
              declarationHelpFeature?.status === FeatureStatus.ENABLED || declarationHelpFeature?.unlock?.consumableId
                ? t('declarations:declarations_declaration_assistance_for_you_who_have_sold_the_home')
                : t('declarations:declarations_buy_the_declaration_help_with_a_unique_price_from_your_broker')
            }
          </HIDTypography>
          <HIDButton
            color="yellow"
            sx={{ alignSelf: 'flex-start' }}
            title={t('declarations:declarations_the_declaration_aid')}
            onClick={handleNavigateToDeclarationHelp}
          />
        </Stack>
        <Stack
          sx={{
            flex: 1,
            alignItems: 'center',
          }}
        >
          <HIDImage
            externalUrl={SalesDocuments}
            style={{
              width: 85,
              height: 114,
              objectFit: 'cover',
              objectPosition: '100% 0',
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default DeclarationHelpCard;
