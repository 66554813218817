import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';
import { ICON_SIZE } from '../../constants/layout';

const Skattio: Icon = ({
  iconColor: colorProp,
  size = ICON_SIZE,
  fillColor: fillColorProp,
}) => {
  const theme = useTheme();
  const color = colorProp || '#F2B75F';
  const fillColor = fillColorProp || theme.palette.common.white;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="m23.95,11.85l-11.79,-11.79l-12.1,12.1l11.79,11.79l12.1,-12.1zm-6.14,0.08l-5.75,0l-5.9,0l5.75,5.82l5.9,-5.82z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="m6.16,11.92l5.75,5.82l5.9,-5.82l-11.64,0z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </HIDSvgIcon>
  );
};

export default Skattio;
