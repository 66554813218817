import * as R from 'ramda';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  EntityContentConnection,
  ModificationType,
} from '@house-id/houseid-types/dist/content/entityContentConnection';

import {
  EntityContentConnectionAction,
  EntityContentConnectionsMap,
} from '../types/types.content';

export const generateEntityContentConnectionsPatchActionData = (
  previousData: Record<string, Array<string>>,
  newData: Record<string, Array<string>>,
): Array<EntityContentConnectionAction> => {
  const actions: EntityContentConnectionAction[] = R.keys(newData)
    .flatMap((key) => {
      const previousItems = previousData[key] || [];
      const newItems = newData[key] || [];
      const noActionItems = R.intersection(previousItems, newItems);

      const addActions: Array<EntityContentConnectionAction> = R.pipe(
        R.without(noActionItems),
        R.uniq,
        R.map((id: string) => ({
          action: ModificationType.CREATE,
          type: key,
          entityId: id,
        } as EntityContentConnectionAction)),
      )(newItems);

      const removeActions: Array<EntityContentConnectionAction> = R.pipe(
        R.without(noActionItems),
        R.uniq,
        R.map((id: string) => ({
          action: ModificationType.DELETE,
          type: key,
          entityId: id,
        } as EntityContentConnectionAction)),
      )(previousItems);

      return [...addActions, ...removeActions];
    });
  return actions;
};

export const getInitialConnections = (connectionsMap?: EntityContentConnectionsMap) => R.pipe(
  R.toPairs,
  R.map(([entityType, connections]: [string, Array<EntityContentConnection>]) =>
    ([entityType, connections.map((c) => c.entity.id)] as [EntityType, string[]])),
  R.fromPairs,
)(connectionsMap || {});

export const mapConnectionsFullModelToIds = (connections: Partial<Record<EntityType, EntityContentConnection[]>> | undefined) => {
  if (!connections) {
    return {};
  }
  return Object.keys(connections).reduce((acc, key) => {
    acc[key] = connections[key as EntityType]?.map((connection: EntityContentConnection) => connection.entity.id) || [];
    return acc;
  }, {} as Record<string, string[]>);
};
