import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { Receipt } from '@house-id/houseid-types/dist/content/receipt';
import { useTranslation } from 'react-i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { skipToken } from '@reduxjs/toolkit/query';

import { formatMoney } from '../../../../../../../../../utils/string';
import { useGetEntityConnectionFullModelsQuery } from '../../../../../api/api.content';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDTypography from '../../../../../../../../../components/HIDTypography';

type ReceiptSummarySectionProps = {
  receipt: Receipt;
};

const ReceiptSummarySection: FC<ReceiptSummarySectionProps> = ({
  receipt,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'receipts']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: receiptConnections,
  } = useGetEntityConnectionFullModelsQuery(
    propertyId
      ? {
        propertyId,
        entityId: receipt.id,
        entityType: EntityType.RECEIPT,
      }
      : skipToken,
  );

  const connectedDeductions = receiptConnections
    ? receiptConnections[EntityType.DEDUCTION] || []
    : [];

  return (
    <Stack
      sx={{
        padding: 2.5,
        backgroundColor: theme.palette.grey[100],
        borderRadius: BorderRadius.md,
      }}
    >
      <HIDTypography variant="subtitle1">
        {t('receipts:receipts_view_receipt_summary')}
      </HIDTypography>
      <Stack sx={{ gap: 1.5 }}>
        {
          connectedDeductions.map((connectedDeduction) => {
            const { id, name } = connectedDeduction.entity;
            // @ts-ignore
            const { amount = 0 } = connectedDeduction.details;

            return (
              <Stack key={id}>
                <HIDTypography>
                  {name}
                </HIDTypography>
                <HIDTypography>
                  {formatMoney(amount as number, false)}
                </HIDTypography>
              </Stack>
            );
          })
        }
      </Stack>
      <HIDTypography sx={{ marginTop: 1.5 }} variant="subtitle1">
        {t('receipts:receipts_price_label')}
      </HIDTypography>
      <HIDTypography sx={{ marginTop: 0.5 }} variant="body1">
        {formatMoney(receipt?.amount)}
      </HIDTypography>
    </Stack>
  );
};

export default ReceiptSummarySection;
