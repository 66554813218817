import React from 'react';
import { useTheme } from '@mui/material';

import { Icon } from '../../types/common';
import HIDSvgIcon from './HIDSvgIcon';

const DoneAll: Icon = ({
  iconColor: colorProp,
  size,
}) => {
  const theme = useTheme();

  const color = colorProp || theme.palette.common.black;

  return (
    <HIDSvgIcon size={size}>
      <path
        d="M16.5 2.5V1.5V2.5ZM17.5 3.5L16.5 3.5V3.5H17.5ZM2.5 3.5L1.5 3.5L2.5 3.5ZM3.5 2.5L3.5 3.5L3.5 2.5ZM3.5 17.5L3.5 18.5H3.5V17.5ZM2.5 16.5H3.5H2.5ZM16.5 17.5V16.5V17.5ZM17.5 16.5H18.5H17.5ZM20 21.5V22.5V21.5ZM10 21.5V20.5V21.5ZM21.5 9H20.5H21.5ZM21.5 20H22.5H21.5ZM14.6727 8.23994C15.0813 7.86843 15.1114 7.23598 14.7399 6.82733C14.3684 6.41867 13.736 6.38855 13.3273 6.76006L14.6727 8.23994ZM8.5 12.5L7.82733 13.2399C8.20875 13.5867 8.79125 13.5867 9.17267 13.2399L8.5 12.5ZM6.67267 9.48734C6.26401 9.11583 5.63157 9.14595 5.26006 9.55461C4.88855 9.96326 4.91867 10.5957 5.32733 10.9672L6.67267 9.48734ZM16.5 16.5H3.5V18.5H16.5V16.5ZM3.5 16.5L3.5 3.5L1.5 3.5L1.5 16.5H3.5ZM3.5 3.5L16.5 3.5V1.5L3.5 1.5L3.5 3.5ZM16.5 3.5V16.5H18.5V3.5H16.5ZM16.5 3.5H16.5L18.5 3.5C18.5 2.39543 17.6046 1.5 16.5 1.5V3.5ZM3.5 3.5H3.5L3.5 1.5C2.39543 1.5 1.5 2.39543 1.5 3.5L3.5 3.5ZM3.5 16.5H3.5H1.5C1.5 17.6046 2.39543 18.5 3.5 18.5L3.5 16.5ZM16.5 18.5C17.6046 18.5 18.5 17.6046 18.5 16.5H16.5V16.5V18.5ZM20 20.5L10 20.5V22.5L20 22.5V20.5ZM20.5 9V20H22.5V9H20.5ZM22.5 9C22.5 7.61929 21.3807 6.5 20 6.5V8.5C20.2761 8.5 20.5 8.72386 20.5 9H22.5ZM10 20.5C9.72386 20.5 9.5 20.2761 9.5 20H7.5C7.5 21.3807 8.61929 22.5 10 22.5V20.5ZM20 22.5C21.3807 22.5 22.5 21.3807 22.5 20H20.5C20.5 20.2761 20.2761 20.5 20 20.5V22.5ZM13.3273 6.76006L7.82733 11.7601L9.17267 13.2399L14.6727 8.23994L13.3273 6.76006ZM9.17267 11.7601L6.67267 9.48734L5.32733 10.9672L7.82733 13.2399L9.17267 11.7601Z"
        fill={color}
      />
    </HIDSvgIcon>
  );
};

export default DoneAll;
