import React, { FC } from 'react';
import {
  Card,
  Grid,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { PropertyProgressCategorySection } from '@house-id/houseid-types/dist/propertyProgress';

import useBreakPointsSizes from '../../../../../../hooks/useBreakpointsSizes';
import { useNavigateBackOr } from '../../../../../../utils/routes';
import { useGetPropertyUsersQuery } from '../../../../api/api.property';
import useGetCurrentPropertyId from '../../../../hooks/useGetCurrentPropertyId';
import { getHomePath } from '../../../../navigation/navigation.property';
import HomeLayout from '../../../../pages/Home/components/HomeLayout';
import PropertyHeader from '../../../../pages/Home/components/PropertyHeader';
import ListCategoriesActions from '../../../Content/components/actions/ListCategoriesActions';
import ListEntitiesActions from '../../../Content/components/actions/ListEntitiesActions';
import { useGetPropertyProgressQuery } from '../../api/api.propertyProgress';
import { getPropertyProgressPercent } from '../../utils/utils.propertyProgress';
import PropertyUsersSection from '../../../../components/PropertyUsersSection';
import {
  useGetPropertyActions,
  PropertyActionId,
} from '../../../../hooks/useGetPropertyActions';
import { getPathWithPropertyIdOrInit } from '../../../../../Auth/navigation/navigation.auth';
import useShowRewardOfferDialog from '../../../../../SubscriptionPlans/hooks/useShowRewardOfferDialog';
import SubscriptionPlanBanner from '../../../../pages/PropertyHome/components/SubscriptionPlanBanner';
import useGetCurrentUserSubscriptionPlanId from '../../../../../SubscriptionPlans/hooks/useGetCurrentUserSubscriptionPlanId';
import PropertyProgressListItem from './PropertyProgressListItem';
import useGetHandlePropertyProgressCardPress from '../../hooks/useGetHandlePropertyProgressCardPress';
import useIsSFProperty from '../../../../hooks/useIsSfProperty';
import DeclarationHelpCard from './DeclarationHelpCard';

// TODO: Remove when section is implemented fully
const HIDDEN_SECTION_IDS = [PropertyProgressCategorySection.SUGGESTION];

const PropertyProgress: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['property']);
  const navigateBackOr = useNavigateBackOr();
  useShowRewardOfferDialog();

  const { isDownMd } = useBreakPointsSizes();

  const isSfProperty = useIsSFProperty();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: { isPlus } = {} } = useGetCurrentUserSubscriptionPlanId();

  const { data: propertyProgressSummary } = useGetPropertyProgressQuery(propertyId ? { propertyId } : skipToken);
  const { data: propertyUsers = [], isLoading: isPropertyUsersLoading } = useGetPropertyUsersQuery(propertyId ? { propertyId } : skipToken);

  const propertyProgressCategories = propertyProgressSummary?.categories?.filter(({ id }) => !HIDDEN_SECTION_IDS.includes(id)) || [];
  const propertyProgressPercent = getPropertyProgressPercent(propertyProgressSummary?.progress);

  const handlePropertyProgressCardPress = useGetHandlePropertyProgressCardPress();

  const propertyActions = useGetPropertyActions({
    propertyId,
    excludeActions: [PropertyActionId.SET_CURRENT_PROPERTY],
  });

  return (
    <HomeLayout
      Header={({ ManageButton }) => (
        <PropertyHeader
          ManageButton={ManageButton}
          progressPercent={propertyProgressPercent}
        />
      )}
      SideColumn={
        <Stack spacing={2}>
          <Card sx={{ padding: 2 }}>
            <ListEntitiesActions
              customActions={propertyActions}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <PropertyUsersSection
              loading={isPropertyUsersLoading}
              propertyId={propertyId}
              users={propertyUsers}
            />
          </Card>
          {!isPlus && (
            <SubscriptionPlanBanner isSidebarBanner />
          )}
        </Stack>
      }
      sideDrawerElements={[
        <ListEntitiesActions
          customActions={propertyActions}
          key={ListCategoriesActions.name}
        />,
        <PropertyUsersSection
          key={PropertyUsersSection.name}
          loading={isPropertyUsersLoading}
          propertyId={propertyId}
          users={propertyUsers}
        />,
      ]}
      title={t('property:property_progress_title')}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getHomePath, { propertyId }))}
    >
      <Stack sx={{ marginTop: theme.spacing(2.5) }}>
        <Grid container spacing={2.5}>
          {isSfProperty && (
            <Grid item alignItems="stretch" xs={12}>
              <DeclarationHelpCard />
            </Grid>
          )}
          {
            propertyProgressCategories
              .map((category) => (
                <Grid
                  item
                  alignItems="stretch"
                  key={category.id}
                  sm={6}
                  xs={12}
                >
                  <PropertyProgressListItem
                    category={category}
                    sx={{
                      height: '100%',
                    }}
                    onClick={() => handlePropertyProgressCardPress(category)}
                  />
                </Grid>
              ))
          }
        </Grid>
        {isSfProperty && !isPlus && isDownMd && (
          <Stack sx={{ marginTop: theme.spacing(2) }}>
            <SubscriptionPlanBanner />
          </Stack>
        )}
      </Stack>
    </HomeLayout>
  );
};

export default PropertyProgress;
