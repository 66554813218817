import {
  SubscriptionPlanId,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import RouteNames from '../../../routes/RouteNames';
import { generateRoutePath } from '../../../utils/routes';
import { SubscriptionPlanOfferPeriodType } from '../types.subscriptionPlans';

export const getSubscriptionPlansPath = () => generateRoutePath(RouteNames.SUBSCRIPTION_PLANS);

export const getBuySubscriptionPlanPath = (params: { subscriptionPlanId: SubscriptionPlanId }) =>
  generateRoutePath(RouteNames.SUBSCRIPTION_PLAN_BUY_SUBSCRIPTION, params);

export const getSubscriptionOrderPath = (
  params: { orderId: string, },
) => generateRoutePath(RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER, params);

export const getSubscriptionOrderErrorPath = (
  params: { orderId?: string, subscriptionPlanId: SubscriptionPlanId, period: SubscriptionPlanOfferPeriodType },
) =>
  generateRoutePath(RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER_ERROR, params);

export const getSubscriptionErrorPath = (
  params: { subscriptionPlanId: SubscriptionPlanId, period: SubscriptionPlanOfferPeriodType },
) =>
  generateRoutePath(RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ERROR, params);

export const getSubscriptionPlanPaymentHistoryPath = () => generateRoutePath(RouteNames.SUBSCRIPTION_PLANS_SUBSCRIPTION_PAYMENTS_HISTORY);
