import React, {
  FC,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  AmountSpecificationType,
  DeductionCategory,
  DeductionChatContext,
  DeductionChatContextType,
  DeductionReceiptAction,
  WellKnownDeductionTypes,
} from '@house-id/houseid-types/dist/deductions';
import { Receipt } from '@house-id/houseid-types/dist/content/receipt';
import { FeatureStatus } from '@house-id/houseid-types/dist/subscriptionPlans';
import {
  Add,
  Check,
  Remove,
} from '@mui/icons-material';
import { useDebounce } from 'usehooks-ts';
import * as R from 'ramda';
import {
  useTheme,
  Card,
  Stack,
} from '@mui/material';

import useGetPropertyDeclarationHelpFeatureStatus from '../../hooks/useGetPropertyDeclarationHelpFeatureValue';
import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../../../../../../../../utils/routes';
import HIDTypography from '../../../../../../../../../../components/HIDTypography';
import useDialog from '../../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../../hooks/useDialog/DialogNames';
import useGetCurrentPropertyId from '../../../../../../../../hooks/useGetCurrentPropertyId';
import DynamicContentInputForm, { getDynamicFormInitialValues }
  from '../../../../../../../Content/modules/DynamicContent/components/dynamicInputForm/DynamicContentInputForm';
import { DynamicInputField } from '../../../../../../../Content/modules/DynamicContent/types/types.dynamicContent';
import { useGetAllReceiptsQuery } from '../../../../../../../Content/modules/Receipt/api/api.receipt';
import {
  generateEntityContentConnectionsPatchActionData,
  mapConnectionsFullModelToIds,
} from '../../../../../../../Content/utils/contentConnections';
import {
  useGetDeductionQuery,
  useCreateDeductionMutation,
  useUpdateDeductionMutation,
  useGetDeductionsTypesQuery,
  useDeleteDeductionMutation,
} from '../../api/api.deductions';
import DeclarationHelpSummarySection from '../../components/DeclarationHelpSummarySection';
import DeductionCalculationResultCard from '../../components/DeductionCalculationResultCard';
import DeductionsSupportBox from '../../components/DeductionsSupportBox';
import DeductionReceiptsAndAmounts from './components/DeductionReceiptsAndAmounts';
import RenovationDeductionReceiptsAndAmounts from './components/RenovationDeductionReceiptsAndAmounts';
import { CreateEditDeductionValues } from './types';
import { Currency } from '../../../../../../../../../../utils/string';
import HomeLayout from '../../../../../../../../pages/Home/components/HomeLayout';
import HIDTextField from '../../../../../../../../../../components/HIDTextField';
import HIDIconButton from '../../../../../../../../../../components/buttons/HIDIconButton';
import HIDButton from '../../../../../../../../../../components/buttons/HIDButton';
import { getObjDiff } from '../../../../../../../../../../utils/object';
import HIDFormSelect from '../../../../../../../../../../components/HIDFormSelect';
import HIDFormDatePicker from '../../../../../../../../../../components/datePicker/HIDFormDatePicker';
import { DateTimeFormats } from '../../../../../../../../../../utils/date';
import ViewEntityActions from '../../../../../../../Content/components/actions/ViewEntityActions';
import DeductionCardContainer from './components/DeductionCardContainer';
import { getDeclarationHelpOverviewPath } from '../../navigation.declarationHelp';
import EntityConnectionsGroup from '../../../../../../../Content/components/EntityConnectionsGroup';

const generateReceiptsActionData = (newReceipts: Array<string>, oldReceipts: Array<string>, allReceipts: Array<Receipt>) => {
  const noActionItems = R.intersection(newReceipts, oldReceipts);
  const allReceiptsMap = allReceipts.reduce((acc, receipt) => {
    acc[receipt.id] = receipt;
    return acc;
  }, {} as Record<string, Receipt>);

  const addActions: Array<DeductionReceiptAction> = R.pipe(
    R.without(noActionItems),
    R.uniq,
    R.map((id: string) => ({
      action: 'create',
      amount: allReceiptsMap[id].readonlyDeductibleAmount || 0,
      id,
    } as DeductionReceiptAction)),
  )(newReceipts);

  const removeActions: Array<DeductionReceiptAction> = R.pipe(
    R.without(noActionItems),
    R.uniq,
    R.map((id: string) => ({
      action: 'delete',
      id,
    } as DeductionReceiptAction)),
  )(oldReceipts);

  return [...addActions, ...removeActions];
};

const renovationFastTrackTypes: Array<string> = [
  WellKnownDeductionTypes.GENERIC_IMPROVEMENT, WellKnownDeductionTypes.GENERIC_REPAIR_AND_MAINTENANCE,
];

const currentYear = new Date().getFullYear();

enum CreateDeductionMode {
  SALES_PURCHASE_EXPENSES = 'sales_purchase_expenses',
  RENOVATION = 'renovation',
  RENOVATION_FAST_TRACK = 'renovation_fast_track',
}

type CreateRenovationDeductionOperationNavigation = { category: DeductionCategory.RENOVATION; type?: string; };
type CreateSalesExpensesDeductionOperationNavigation = { category: DeductionCategory.SALES_EXPENSE; type: string; };
type CreatePurchaseExpensesDeductionOperationNavigation = { category: DeductionCategory.PURCHASE_EXPENSE; type: string; };

export type UpdateDeductionOperationNavigation = { id: string; operation: 'update'; replace?: boolean; };
export type CreateDeductionOperationNavigation = (
  CreateRenovationDeductionOperationNavigation |
  CreateSalesExpensesDeductionOperationNavigation |
  CreatePurchaseExpensesDeductionOperationNavigation) & ({ operation: 'create'; replace?: boolean; });

type CreateEditDeductionNavigationOperation = 'create' | 'update';
type CreateEditDeductionQueryParams = {
  category?: DeductionCategory;
  type?: string;
};

export type CreateEditDeductionNavigation = {
  operation: 'create' | 'update';
  id?: string;
  category?: DeductionCategory;
  type?: string;
};

const CreateEditDeduction: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['declarations', 'deductions', 'forms_common', 'common']);
  const {
    routeParams,
    queryParams,
  } = useNavigationParamsAndState<{ id?: string }, CreateEditDeductionQueryParams, any>();

  const operation: CreateEditDeductionNavigationOperation = routeParams.id ? 'update' : 'create';
  const isCreate = operation === 'create';
  const routeCategory = operation === 'create' ? queryParams?.category : undefined;

  const [createdDeductionId, setCreatedDeductionId] = React.useState<string | undefined>(undefined);
  const id = operation === 'update' ? routeParams.id : createdDeductionId;

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: deduction,
    isLoading: isDeductionLoading,
    isFetching: isDeductionFetching,
  } = useGetDeductionQuery(id && propertyId ? { propertyId, id } : skipToken);

  const [createDeduction, { isLoading: isCreatingDeduction }] = useCreateDeductionMutation();
  const [updateDeduction, { isLoading: isUpdatingDeduction }] = useUpdateDeductionMutation();

  const {
    data: deductionTypes,
    isLoading: isDeductionTypesLoading,
  } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: allReceipts = [],
    isLoading: isReceiptsLoading,
  } = useGetAllReceiptsQuery(propertyId ? { propertyId } : skipToken);

  const type = operation === 'create' ? queryParams?.type : deduction?.type;
  const category = routeCategory || deduction?.categoryId;

  const createDeductionMode = category === DeductionCategory.RENOVATION
    ? type && renovationFastTrackTypes.includes(type)
      ? CreateDeductionMode.RENOVATION_FAST_TRACK
      : CreateDeductionMode.RENOVATION
    : CreateDeductionMode.SALES_PURCHASE_EXPENSES;

  const schema = Yup.object().shape({
    type: Yup.string().required(t('forms_common:forms_common_required_field_generic')),
  });

  const mapInitValues = () => ({
    id: deduction?.id,
    category: category || DeductionCategory.RENOVATION,
    amount: deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR ? '' : deduction?.amount?.amount.toString() || '',
    amountSpecificationType: deduction?.amountSpecificationType || AmountSpecificationType.TOTAL,
    type: deduction?.type || type || '',
    name: deduction?.name || '',
    receiptConnections: {
      [EntityType.RECEIPT]: deduction?.receipts?.map((connection) => connection.receipt.id) || [],
    },
    amounts: category === DeductionCategory.RENOVATION
      ? deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR
        ? Object.entries(deduction?.amounts).map(([key, value]) => ({
          year: key,
          number: value.amount.toString(),
        }))
        : [{ year: currentYear.toString(), number: '' }]
      : [],
    otherConnections: deduction?.connections
      ? mapConnectionsFullModelToIds(deduction?.connections || {})
      : {},
    answers: deduction?.calculation?.questions
      ? getDynamicFormInitialValues(deduction?.calculation?.questions as unknown as DynamicInputField[])
      : {},
  });

  const formik = useFormik<CreateEditDeductionValues>({
    initialValues: mapInitValues(),
    validationSchema: schema,
    onSubmit: () => { },
  });

  const currentType = deductionTypes?.find((deductionType) => deductionType.id === type || deductionType.id === formik.values.type);
  const hasType = Boolean(formik.values.type);
  const isRenovationEditMode = createDeductionMode === CreateDeductionMode.RENOVATION;

  const showQuestions = hasType && isRenovationEditMode && Boolean(deduction?.calculation?.questions?.length);

  const showResult = isRenovationEditMode && Boolean(deduction?.calculation?.result);
  const showAmounts = isRenovationEditMode && category === DeductionCategory.RENOVATION
    ? hasType && formik.values.amounts?.some((year) => year)
    : hasType;

  const showTypePicker = isRenovationEditMode;

  const isShowLoading = isCreate ? isDeductionTypesLoading : isDeductionLoading || isDeductionTypesLoading || isReceiptsLoading;
  const isUpdating = isUpdatingDeduction || isCreatingDeduction;
  const typeName = currentType?.name?.toLowerCase() || t('deductions:measure').toLowerCase();

  const supportContext: DeductionChatContext = useMemo(
    () => id
      ? {
        type: DeductionChatContextType.DEDUCTION_ENTITY,
        id,
      } : currentType
        ? {
          type: DeductionChatContextType.DEDUCTION_TYPE,
          deductionType: currentType.id,
        } : {
          type: DeductionChatContextType.DEDUCTION_CATEGORY,
          deductionCategory: category || DeductionCategory.RENOVATION,
        },
    [currentType, category, id],
  );

  const title = useMemo(
    () => isCreate
      ? category === DeductionCategory.RENOVATION
        ? t('deductions:create_renovation_deductions_title', { typeName })
        : t('deductions:create_deductions_title', { typeName })
      : t('deductions:edit_deductions_title', { typeName }),
    [typeName],
  );

  const renovationTypes: Array<{ id: string; name: string }> = deductionTypes?.length
    ? deductionTypes
      .filter((deductionType) => deductionType.categoryId === category && deductionType.treePath)
      .map((deductionType) => ({ id: deductionType.id, name: deductionType.name }))
    : [];

  const setValues = (fieldName: string, value: any) => formik.setFieldValue(fieldName, value);
  const debouncedValues = useDebounce(formik.values, 500);
  const handleValueChanged = (fieldName: string, value: any) => setValues(fieldName, value);

  const handleCreateValues = (values: CreateEditDeductionValues) => {
    if (propertyId) {
      if (isCreate && !id) {
        const receiptsActionData = values.receiptConnections[EntityType.RECEIPT]?.map((id) => ({
          id,
          action: 'create',
          amount: 0,
        } as DeductionReceiptAction));
        const otherConnectionsActionData = generateEntityContentConnectionsPatchActionData({}, values.otherConnections);

        const body = {
          propertyId,
          name: values.name,
          type: values.type,
          amount: values.amountSpecificationType === AmountSpecificationType.TOTAL
            ? {
              amount: Number(values.amount),
              currency: 'SEK',
            } : undefined,
          amounts: values.amountSpecificationType === AmountSpecificationType.PER_YEAR
            ? values.amounts.reduce((acc, { year, number }) => {
              acc[Number(year)] = {
                amount: Number(number),
                currency: 'SEK',
              };
              return acc;
            }, {} as Record<string, { amount: number; currency: string }>)
            : undefined,
          receipts: receiptsActionData,
          connections: otherConnectionsActionData,
        };

        return createDeduction(body)
          .unwrap()
          .then((deduction) => {
            setCreatedDeductionId(deduction.id);
          });
      }
    }
  };

  const handleUpdateValues = (values: CreateEditDeductionValues) => {
    if (propertyId) {
      if (deduction) {
        const receiptsOld = deduction?.receipts?.map((connection) => connection.receipt.id) || [];
        const receiptsNew = values.receiptConnections[EntityType.RECEIPT] || [];

        const receiptsActionData = generateReceiptsActionData(receiptsNew, receiptsOld, allReceipts);

        const otherConnectionsActionData = generateEntityContentConnectionsPatchActionData(
          mapConnectionsFullModelToIds(deduction?.connections || {}),
          values.otherConnections,
        );

        const body = {
          id: deduction.id,
          propertyId,
          name: values.name,
          type: values.type,
          amount: values.amountSpecificationType === AmountSpecificationType.TOTAL
            ? {
              amount: Number(values.amount),
              currency: Currency.code,
            } : undefined,
          amounts: values.amountSpecificationType === AmountSpecificationType.PER_YEAR
            ? values.amounts.reduce((acc, { year, number }) => {
              acc[Number(year)] = {
                amount: Number(number),
                currency: Currency.code,
              };
              return acc;
            }, {} as Record<string, { amount: number; currency: string }>)
            : undefined,
          receipts: receiptsActionData,
          connections: otherConnectionsActionData,
          answers: values.answers,
        };
        return updateDeduction(body)
          .unwrap();
      }
    }
  };

  const [openUpdateDeductionAmountDialog] = useDialog(DialogNames.UPDATE_DEDUCTION_AMOUNT_DIALOG);

  const handleReceiptsChange = (receiptIds: string[]) => {
    const addedReceiptIds = receiptIds.filter((id) => !formik.values.receiptConnections[EntityType.RECEIPT]?.includes(id));
    const removedReceiptIds = formik.values.receiptConnections[EntityType.RECEIPT]?.filter((id) => !receiptIds.includes(id));

    formik.setFieldValue('receiptConnections', { [EntityType.RECEIPT]: receiptIds });

    const addedReceipts = allReceipts.filter((receipt) => addedReceiptIds.includes(receipt.id));
    const removedReceipts = allReceipts.filter((receipt) => removedReceiptIds.includes(receipt.id));
    if (category) {
      const years = R.uniq(addedReceipts
        .filter((receipt) => receipt.date)
        .map((receipt) => new Date(receipt.date).getFullYear()));

      const addedReceiptsYearsWithDeductable = addedReceipts
        .filter((receipt) => receipt.readonlyDeductibleAmount && receipt.readonlyDeductibleAmount > 0)
        .map((receipt) => [new Date(receipt.date).getFullYear(), receipt.readonlyDeductibleAmount || 0]);

      const removedReceiptsYearsWithDeductable = removedReceipts
        .filter((receipt) => receipt.readonlyDeductibleAmount && receipt.readonlyDeductibleAmount > 0)
        .map((receipt) => [new Date(receipt.date).getFullYear(), -(receipt.readonlyDeductibleAmount || 0)]);

      const receiptsPerYear = [...addedReceiptsYearsWithDeductable, ...removedReceiptsYearsWithDeductable].reduce((acc, [year, amount]) => {
        acc[year] = (acc[year] || 0) + amount;
        return acc;
      }, {} as Record<number, number>);

      if (formik.values?.amountSpecificationType === AmountSpecificationType.PER_YEAR) {
        const amounts = years
          .filter((year) => !formik.values.amounts.some((amount) => amount.year === String(year)))
          .map((year) => ({ year: String(year), number: '' }));
        formik.setFieldValue('amounts', [...formik.values.amounts, ...amounts]);

        if (Object.keys(receiptsPerYear).length) {
          setTimeout(() => openUpdateDeductionAmountDialog({
            onUpdate: () => {
              formik.setFieldValue(
                'amounts',
                formik.values.amounts.map(({ year, number }) => ({
                  year,
                  number: String(Number(number) + (receiptsPerYear[Number(year)] || 0)),
                })),
              );
            },
            data: {
              amountSpecificationType: AmountSpecificationType.PER_YEAR,
              currentAmounts: Object.fromEntries(
                formik.values.amounts.map(({ year, number }) => [year, Number(number)]),
              ),
              newAmounts: receiptsPerYear,
            },
          }), 500);
        }
      } else {
        const changedReceiptAmount = R.sum(Object.values(receiptsPerYear));
        if (Object.keys(receiptsPerYear).length) {
          setTimeout(() => openUpdateDeductionAmountDialog({
            data: {
              amountSpecificationType: AmountSpecificationType.TOTAL,
              currentAmount: Number(formik.values.amount),
              newAmount: changedReceiptAmount,
            },
            onUpdate: () => {
              const amount = Number(formik.values.amount) + changedReceiptAmount;
              formik.setFieldValue('amount', amount);
            },
          }), 500);
        }
      }
    }
  };

  const handleAddYear = () => {
    handleValueChanged('amounts', [...formik.values.amounts, { year: '', number: '' }]);
  };

  const handleRemoveYear = (index: number) => {
    const newAmounts = formik.values.amounts.filter((_, i) => i !== index);
    formik.setValues({ ...formik.values, amounts: newAmounts });
  };

  const [deleteDeduction] = useDeleteDeductionMutation();

  const navigateBackOr = useNavigateBackOr();
  const handleGoBack = () => navigateBackOr(propertyId ? getDeclarationHelpOverviewPath({ propertyId }) : undefined);

  const handleDelete = () => {
    if (propertyId && deduction) {
      deleteDeduction({ id: deduction?.id, propertyId })
        .unwrap()
        .then(handleGoBack);
    }
  };

  useEffect(() => {
    if (id) {
      const deductionValues = mapInitValues();
      const newValues = debouncedValues;

      if (!R.equals(newValues, deductionValues)) {
        const difference = getObjDiff(deductionValues, newValues);
        if (!R.isEmpty(difference)) {
          handleUpdateValues(formik.values);
        }
      }
    } else if (formik.values.type && formik.values.name) {
      handleCreateValues(formik.values);
    }
  }, [debouncedValues]);

  useEffect(() => {
    if (!isCreate && !formik.values.id) {
      // Initial values for the form when is update flow
      formik.setValues(mapInitValues());
    }
  }, [deduction]);

  useEffect(() => {
    if (!formik.values.name) {
      formik.setFieldValue('name', currentType?.name || '');
    }
    formik.setFieldValue('amountSpecificationType', currentType?.amountSpecificationType || AmountSpecificationType.TOTAL);
  }, [currentType]);

  const { data: declarationHelpFeatureStatus } = useGetPropertyDeclarationHelpFeatureStatus();
  const isDeclarationHelpFeatureEnabled = declarationHelpFeatureStatus === FeatureStatus.ENABLED;

  const DynamicContentForm = useMemo(() => (
    <DynamicContentInputForm
      enableReinitializeValues
      showElementDivider
      fields={(deduction?.calculation?.questions || []) as unknown as Array<DynamicInputField>}
      onValuesChanged={(values) => handleValueChanged('answers', values)}
    />
  ), [deduction?.calculation?.questions]);

  return (
    <HomeLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              onDelete={handleDelete}
            />
          </Card>
          <DeductionsSupportBox
            context={supportContext}
            linkText={t('common:read_more_and_chat')}
            title={t('deductions:questions_per_type', { typeName })}
          />
          {deduction !== undefined && showResult && (
            <DeclarationHelpSummarySection
              Icon={Check}
              title={t('deductions:deductions_result')}
              titleContainerSx={{ backgroundColor: theme.palette.grey[200] }}
            >
              <DeductionCalculationResultCard
                deductionTypeId={deduction.type}
                isLoading={isDeductionFetching || isUpdating}
                result={deduction.calculation?.result}
                style={{ padding: theme.spacing(2.5) }}
                totalAmount={deduction.summary.totalAmount}
              />
            </DeclarationHelpSummarySection>
          )}
        </>
      }
      sideDrawerElements={[
        <ViewEntityActions
          key={ViewEntityActions.name}
          onDelete={handleDelete}
        />,
      ]}
      title={title}
      onBack={handleGoBack}
    >
      {!isShowLoading
        ? (
          <Stack gap={2.5}>
            {createDeductionMode === CreateDeductionMode.RENOVATION
              ? (
                <DeductionCardContainer sx={{ padding: theme.spacing(2.5) }}>
                  <HIDTypography variant="subtitle1">
                    {t('deductions:deduction_select_deduction')}
                  </HIDTypography>
                  {showTypePicker && (
                    <HIDFormSelect
                      items={renovationTypes}
                      label={t('deductions:deduction_select_deduction')}
                      placeholder={t('deductions:deduction_select_deduction')}
                      showHelperText={false}
                      value={formik.values.type}
                      onChange={(value) => handleValueChanged('type', value.target.value)}
                    />
                  )}
                  <HIDTextField
                    required
                    label={t('deductions:deduction_your_custom_name')}
                    value={formik.values.name}
                    onChange={({ target: { value } }) =>
                      handleValueChanged('name', value)}
                  />
                  {hasType && (
                    <Stack gap={1.5}>
                      {formik.values.amounts?.length
                        ? formik.values.amounts.map(({ year }, index) => (
                          <Stack
                            alignItems="center"
                            flexDirection="row"
                            gap={1}
                            justifyContent="space-between"
                            key={index}
                          >
                            <HIDFormDatePicker
                              required
                              dateTimeFormat={DateTimeFormats.YEAR_ONLY}
                              label={t('deductions:deduction_year_of_activation')}
                              value={year ? new Date(String(year)) : undefined}
                              views={['year']}
                              onChange={(value) =>
                                handleValueChanged(`amounts[${index}]`, { ...formik.values.amounts[index], year: value?.getFullYear() })}
                            />
                            {index > 0 && (
                              <HIDIconButton
                                Icon={Remove}
                                onClick={() => handleRemoveYear(index)}
                              />
                            )}
                          </Stack>
                        ))
                        : null}
                      <HIDButton
                        Icon={Add}
                        sx={{ alignSelf: 'flex-end' }}
                        title={t('deductions:deduction_add_one_more_year')}
                        onClick={handleAddYear}
                      />
                    </Stack>
                  )}
                </DeductionCardContainer>
              ) : undefined}
            {createDeductionMode === CreateDeductionMode.RENOVATION_FAST_TRACK
              ? (
                <HIDTextField
                  required
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                  label={t('deductions:deduction_your_custom_name')}
                  value={formik.values.name}
                  onBlur={formik.handleBlur('name')}
                  onChange={(value) => handleValueChanged('name', value.target.value)}
                />
              ) : undefined}
            {showAmounts
              ? createDeductionMode === CreateDeductionMode.RENOVATION
                ? (
                  <RenovationDeductionReceiptsAndAmounts
                    deduction={deduction}
                    formik={formik}
                    type={type}
                    onConnectedReceiptsChange={handleReceiptsChange}
                    onValueChanged={handleValueChanged}
                  />
                )
                : (
                  <DeductionReceiptsAndAmounts
                    deduction={deduction}
                    formik={formik}
                    type={type}
                    onConnectedReceiptsChange={handleReceiptsChange}
                    onValueChanged={handleValueChanged}
                  />
                )
              : undefined}
            {hasType && (
              <DeductionCardContainer sx={{ padding: theme.spacing(2.5) }}>
                <Stack gap={1}>
                  <HIDTypography variant="subtitle1">
                    {t('deductions:create_edit_deductions_add_other_connections_section_title')}
                  </HIDTypography>
                  <HIDTypography variant="body1">
                    {isDeclarationHelpFeatureEnabled
                      ? t('deductions:create_edit_deductions_add_other_connections_section_description')
                      : t('deductions:create_edit_deductions_add_other_connections_section_description_feature_disabled')}
                  </HIDTypography>
                </Stack>
                <EntityConnectionsGroup
                  addButtonText={t('deductions:create_edit_deductions_add_other_connections')}
                  connectionsMap={deduction?.connections || {}}
                  entityId={undefined}
                  entityType={EntityType.DEDUCTION}
                  sections={[EntityType.DOCUMENT, EntityType.PHOTO, EntityType.TIMELINE_ENTRY, EntityType.NOTE]}
                  onChange={(data) => handleValueChanged('otherConnections', data)}
                />
              </DeductionCardContainer>

            )}
            {showQuestions && (
              <DeductionCardContainer sx={{ padding: theme.spacing(2.5) }}>
                {DynamicContentForm}
              </DeductionCardContainer>
            )}
            {deduction !== undefined && showResult && (
              <DeclarationHelpSummarySection
                Icon={Check}
                title={t('deductions:deductions_result')}
                titleContainerSx={{ backgroundColor: theme.palette.grey[200] }}
              >
                <DeductionCalculationResultCard
                  deductionTypeId={deduction.type}
                  isLoading={isDeductionFetching || isUpdating}
                  result={deduction.calculation?.result}
                  style={{ padding: theme.spacing(2.5) }}
                  totalAmount={deduction.summary.totalAmount}
                />
              </DeclarationHelpSummarySection>
            )}
          </Stack>
        )
        : null}
    </HomeLayout>
  );
};

export default CreateEditDeduction;
