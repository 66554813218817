import React, { FC } from 'react';
import {
  Card,
  useTheme,
} from '@mui/material';
import { FaqQuestion } from '@house-id/houseid-types/dist/faq';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';

type FaqQuestionCardProps = {
  question: FaqQuestion,
};

const FaqQuestionCard: FC<FaqQuestionCardProps> = ({
  question: faq,
}) => {
  const theme = useTheme();
  const { question, answer } = faq;

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.lighter,
        borderRadius: BorderRadius.sm,
        padding: 2,
        gap: 1.5,
      }}
    >
      <HIDTypography variant="subtitle1">
        {question}
      </HIDTypography>
      <HIDTypography>
        {answer}
      </HIDTypography>
    </Card>
  );
};

export default FaqQuestionCard;
