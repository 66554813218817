import React from 'react';
import {
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import DeclarationCalculationsSection from './DeclarationCalculationsSection';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import { FCC } from '../../../../../../../../../types/common';
import DeclarationGroups from '../../../components/DeclarationGroups';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import useGetDeductionCalculationSections, { DeductionCalculationSectionType } from '../../../hooks/useGetDeductionCalculationSections';

const DeclarationCalculationsWithNewHousePurchase: FCC = ({ sx }) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances', 'declarations']);

  const {
    data: groups = [],
    isLoading: isDeductionCalculationSectionsLoading,
    isFetching: isDeductionCalculationSectionsFetching,
  } = useGetDeductionCalculationSections({ section: DeductionCalculationSectionType.NEW_PROPERTY });

  const loading = isDeductionCalculationSectionsLoading || isDeductionCalculationSectionsFetching;

  const [openManageDeclarationCalculationNewPropertyDialog] = useDialog(DialogNames.MANAGE_DECLARATION_CALCULATION_NEW_PROPERTY_DIALOG);
  const handleManageValues = openManageDeclarationCalculationNewPropertyDialog;

  return (
    <DeclarationCalculationsSection
      sx={sx}
      title={t('declarations:declarations_new_home')}
    >
      <DeclarationGroups groups={groups} loading={loading} />
      <HIDButton
        color="secondary"
        size="medium"
        sx={{ alignSelf: 'flex-end', marginTop: theme.spacing(2) }}
        onClick={handleManageValues}
      >
        {t('declarations:declarations_manage_numbers')}
      </HIDButton>
    </DeclarationCalculationsSection>
  );
};

export default DeclarationCalculationsWithNewHousePurchase;
