import { useMemo } from 'react';
import {
  SubscriptionPlanId,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import { QueryHook } from '../../../types/common';
import useGetSubscriptionPlanOffers from './useGetSubscriptionPlanOffers';
import { useGetSubscriptionPlanSettings } from './useGetSubscriptionPlanSettings';
import useGetCurrentUserSubscriptionPlanId from './useGetCurrentUserSubscriptionPlanId';
import useGetSubscriptionPlanOfferPeriodTextsGetter, {
  SubscriptionPlanOfferPeriodTexts,
} from './useGetSubscriptionPlanOfferPeriodTextsGetter';
import { getCurrentSubscriptionPlanPeriod } from '../utils.subscriptionPlans';
import { SubscriptionPlanOfferPeriodType } from '../types.subscriptionPlans';

type UseGetSubscriptionPlanOfferPeriodTextsArgs = { subscriptionPlanId: SubscriptionPlanId, period?: SubscriptionPlanOfferPeriodType };

type UseGetSubscriptionPlanOffers =
  QueryHook<SubscriptionPlanOfferPeriodTexts | undefined, UseGetSubscriptionPlanOfferPeriodTextsArgs>;

const useGetSubscriptionPlanOfferPeriodTexts: UseGetSubscriptionPlanOffers = (args) => {
  const subscriptionPlanId = args?.subscriptionPlanId;

  const { data: { subscriptionPlanId: currentSubscriptionPlanId } = {} } = useGetCurrentUserSubscriptionPlanId();

  const { data: subscriptionPlanInfo } = useGetSubscriptionPlanSettings(subscriptionPlanId ? { subscriptionPlanId } : undefined);
  const { data: subscriptionPlanOffers = {}, ...rest } = useGetSubscriptionPlanOffers();

  const period = args?.period || getCurrentSubscriptionPlanPeriod(subscriptionPlanOffers) || SubscriptionPlanOfferPeriodType.YEAR;

  const getSubscriptionPlanOfferPeriodTexts = useGetSubscriptionPlanOfferPeriodTextsGetter();

  const paymentOfferTexts = useMemo(() => {
    if (subscriptionPlanInfo && currentSubscriptionPlanId && subscriptionPlanId) {
      const result = getSubscriptionPlanOfferPeriodTexts(
        subscriptionPlanInfo,
        period,
        subscriptionPlanOffers[subscriptionPlanId],
      );

      return result;
    }

    return undefined;
  }, [subscriptionPlanInfo, subscriptionPlanOffers, subscriptionPlanId, period, currentSubscriptionPlanId]);

  return {
    data: paymentOfferTexts,
    ...rest,
  };
};

export default useGetSubscriptionPlanOfferPeriodTexts;
