import React from 'react';
import {
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import {
  DeleteOutline,
} from '@mui/icons-material';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDBlob } from '@house-id/houseid-types/dist/common';

import {
  FCC,
} from '../../../../../types/common';
import ContentImage from './ContentImage';
import { FileMimeType } from '../../../../../constants/mimeTypes';
import { hidSpacing } from '../../../../../utils/number';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import useGetEntityInfo from '../hooks/useGetEntityInfo';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import { getNoWrapStyle } from '../../../../../utils/style';

const StyledStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

type EntityConnectionsGroupItemProps = {
  name: string;
  date?: string,
  thumbnailUrl?: string;
  entityType: EntityType;
  isSelectionMode?: boolean;
  isSelected?: boolean;
  onClick?: () => void
  onDelete?: () => void
};

const EntityConnectionsGroupItem: FCC<EntityConnectionsGroupItemProps> = ({
  name,
  date,
  thumbnailUrl,
  entityType,
  sx,
  style,
  onClick,
  onDelete,
}) => {
  const theme = useTheme();

  const formattedDate = date ? formatDate(new Date(date), DateTimeFormats.DATE_AND_TIME) : undefined;

  const getEntityInfo = useGetEntityInfo();
  const entityInfo = getEntityInfo(entityType);

  return (
    <StyledStack
      alignItems="center"
      direction="row"
      style={style}
      sx={{
        backgroundColor: theme.palette.grey[100],
        borderRadius: '5px',
        padding: 1.5,
        ...sx,
      }}
    >
      <Stack flex={1} flexDirection="row" onClick={onClick}>
        {
          thumbnailUrl || !entityInfo?.Icon
            ? (
              <ContentImage
                blobs={thumbnailUrl ? [{ thumbnailUrl, mime: FileMimeType.JPEG } as HIDBlob] : undefined}
                size={hidSpacing(6)}
                sx={{
                  marginRight: 1.5,
                  minHeight: hidSpacing(6),
                  justifyContent: 'center',
                }}
              />
            )
            : (
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  aspectRatio: 1,
                  height: hidSpacing(6),
                  marginRight: 1.5,
                }}
              >
                <entityInfo.Icon />
              </Stack>
            )
        }
        <Stack flex={1} justifyContent="center" sx={{ minWidth: 0 }}>
          <Typography
            sx={getNoWrapStyle(1)}
            variant="subtitle1"
          >
            {name}
          </Typography>
          {formattedDate && (
            <Typography noWrap color={theme.palette.grey[500]} variant="body2">
              {formattedDate}
            </Typography>
          )}
        </Stack>
      </Stack>
      <HIDIconButton
        Icon={DeleteOutline}
        onClick={onDelete}
      />
    </StyledStack>
  );
};

export default EntityConnectionsGroupItem;
