import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { useGetEventsQuery } from '../../../../modules/Timeline/api/api.timeline';
import useGetTimelineEntriesDataGridColumns from '../../../../modules/Timeline/hooks/useGetTimelineEntriesDataGridColumns';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';
import { TimelineEvent } from '../../../../modules/Timeline/types.timeline';

const ManageTimelineEntriesTab: FC<ManageContentConnectionsTabProps> = ({
  loading,
  selectedItems,
  onSelect,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const columns = useGetTimelineEntriesDataGridColumns({ isSelectionMode: true });

  const {
    data,
    isLoading: isEventsFetching,
  } = useGetEventsQuery(
    propertyId ? { propertyId, types: [EntityType.TIMELINE_ENTRY] } : skipToken,
  );

  return (
    <ManageConnectionsCommonTab
      columns={columns}
      getRowId={(entity: TimelineEvent) => entity.key}
      loading={isEventsFetching || loading}
      rows={data?.events || []}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManageTimelineEntriesTab;
