import React, {
  useEffect,
  useState,
} from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from '@mui/material';
import { Deduction } from '@house-id/houseid-types/dist/deductions';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import {
  DeleteOutline,
  EditOutlined,
} from '@mui/icons-material';

import { FCC } from '../../../../../../../../../types/common';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { getNoWrapStyle } from '../../../../../../../../../utils/style';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetDeductionsTypesQuery } from '../../../../../../Finances/modules/Declaration/modules/DeclarationHelp/api/api.deductions';
import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import HIDTextField, { CurrencyAdornment } from '../../../../../../../../../components/HIDTextField';
import HIDFormMultiSelect from '../../../../../../../../../components/HIDFormMultiSelect';
import { Currency } from '../../../../../../../../../utils/string';

export enum ReceiptDeductionMode {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  LINES = 'LINES',
}

type ReceiptDeductionCardProps = {
  deduction: Partial<Deduction>;
  amount?: number;
  receiptAmount?: number;
  linesModeEnabled: boolean;
  lines: Array<{ id?: string, index: number, name: string, amount?: number }>;
  selectedLinesIndexes?: Array<number>;
  onChange: (deduction: Partial<Deduction>) => void;
  onChangeAmount: (amount?: number) => void;
  onChangeSelectedLinesIndexes: (lines: Array<number>) => void;
  onEdit: () => void;
  onDelete: () => void;
};

const ReceiptDeductionCard: FCC<ReceiptDeductionCardProps> = ({
  deduction,
  amount,
  receiptAmount,
  linesModeEnabled,
  lines,
  selectedLinesIndexes,
  style,
  sx,
  onChange,
  onChangeAmount,
  onChangeSelectedLinesIndexes,
  onEdit,
  onDelete,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'receipts', 'finances']);

  const ReceiptDeductionModeNameMap: Record<ReceiptDeductionMode, string> = {
    [ReceiptDeductionMode.FULL]: t('receipts:receipts_full_amount'),
    [ReceiptDeductionMode.PARTIAL]: t('receipts:receipts_subtotal'),
    [ReceiptDeductionMode.LINES]: t('receipts:receipts_amounts_from_receipt_lines'),
  };

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: deductionTypes } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const getDeductionNameByType = (deductionTypeId: string) => deductionTypes?.find((deductionType) => deductionType.id === deductionTypeId)?.name;

  const [receiptDeductionMode, setReceiptDeductionMode] = useState<ReceiptDeductionMode>();

  useEffect(() => {
    if (!receiptDeductionMode) {
      if (Boolean(selectedLinesIndexes?.length) && linesModeEnabled) {
        setReceiptDeductionMode(ReceiptDeductionMode.LINES);
      } else if (receiptAmount !== undefined && amount !== undefined && receiptAmount === amount) {
        setReceiptDeductionMode(ReceiptDeductionMode.FULL);
      } else {
        setReceiptDeductionMode(ReceiptDeductionMode.PARTIAL);
      }
    }
  }, [deduction]);

  const handleChangeReceiptDeductionType = (deductionMode: ReceiptDeductionMode) => {
    setReceiptDeductionMode(deductionMode);

    if (deductionMode === ReceiptDeductionMode.FULL) {
      onChangeAmount(receiptAmount);
    }
  };

  useEffect(() => {
    if (receiptDeductionMode === ReceiptDeductionMode.FULL) {
      onChangeAmount(receiptAmount);
    } else if (receiptDeductionMode === ReceiptDeductionMode.LINES) {
      const linesAmount = R.sum(
        lines
          .filter((_line, index) => selectedLinesIndexes?.includes(index))
          .map((line) => line.amount || 0),
      );

      onChangeAmount(linesAmount);
    }
  }, [receiptDeductionMode, receiptAmount, selectedLinesIndexes]);

  const linesSelectedIndexList = lines
    .map((line) => ({
      id: line.index.toString(),
      name: `${line.name} (${line.amount} ${Currency.label})`
        || `${t('receipts:receipts_receipt_line')} ${line.index + 1} (${line.amount} ${Currency.label})`,
    }));

  const parseIndexes = (indexes: Array<string>) => indexes.map((index) => Number.parseInt(index, 10));

  return (
    <Stack
      style={style}
      sx={{
        padding: 2.5,
        borderRadius: BorderRadius.sm,
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        gap: 2.5,
        ...sx,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <HIDTypography
          key={deduction.id}
          sx={getNoWrapStyle(1)}
          variant="subtitle1"
        >
          {getDeductionNameByType(deduction.type as string)}
        </HIDTypography>
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <HIDIconButton
            Icon={EditOutlined}
            onClick={onEdit}
          />
          <HIDIconButton
            Icon={DeleteOutline}
            onClick={onDelete}
          />
        </Stack>
      </Stack>
      <HIDTextField
        label={t('common:name')}
        showHelperText={false}
        value={deduction.name || ''}
        onChange={({ target: { value } }) => onChange({ ...deduction, name: value })}
      />
      <Stack>
        <RadioGroup
          sx={{ alignItems: 'flex-start' }}
          value={receiptDeductionMode}
          onChange={(_event, value) => handleChangeReceiptDeductionType(value as ReceiptDeductionMode)}
        >
          {Object.keys(ReceiptDeductionMode)
            .filter((deductionMode) => deductionMode !== ReceiptDeductionMode.LINES || linesModeEnabled)
            .map((deductionMode) => (
              <FormControlLabel
                control={<Radio />}
                key={deductionMode}
                label={ReceiptDeductionModeNameMap[deductionMode as ReceiptDeductionMode]}
                sx={{ marginRight: 1 }}
                value={deductionMode}
              />
            ))}
        </RadioGroup>
      </Stack>
      {receiptDeductionMode === ReceiptDeductionMode.LINES && (
        <HIDFormMultiSelect
          items={linesSelectedIndexList || []}
          label={t('receipts:receipts_select_lines')}
          value={selectedLinesIndexes?.map((index) => index.toString()) || []}
          onChange={({ target: { value } }) => onChangeSelectedLinesIndexes(parseIndexes(value as Array<string>))}
        />
      )}
      <HIDTextField
        disabled={receiptDeductionMode !== ReceiptDeductionMode.FULL}
        endAdornment={<CurrencyAdornment />}
        label={t('finances:amount')}
        value={amount?.toString() || ''}
        onChange={({ target: { value } }) => value ? onChangeAmount(Number.parseFloat(value)) : undefined}
      />
    </Stack>
  );
};

export default ReceiptDeductionCard;
