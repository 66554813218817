import { SubscriptionPlanId } from '@house-id/houseid-types/dist/subscriptionPlans';

import { QueryHook } from '../../../types/common';
import {
  SubscriptionPlanInfo,
  useGetSubscriptionPlansSettings,
} from './useGetSubscriptionPlansSettings';

type UseGetSubscriptionPlanSettings = QueryHook<SubscriptionPlanInfo | undefined, { subscriptionPlanId: SubscriptionPlanId }>;

export const useGetSubscriptionPlanSettings: UseGetSubscriptionPlanSettings = (args, options) => {
  const { data: plans, ...rest } = useGetSubscriptionPlansSettings(undefined, options);

  return {
    data: args?.subscriptionPlanId && plans ? plans[args.subscriptionPlanId] : undefined,
    ...rest,
  };
};
