import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { useGetAllPhotosQuery } from '../../../../modules/Photo/api/api.photo';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import { useGetPhotosDataGridColumns } from '../../../../modules/Photo/pages/Photos';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';

const ManagePhotosTab: FC<ManageContentConnectionsTabProps> = ({
  loading,
  selectedItems,
  onSelect,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const photosColumns = useGetPhotosDataGridColumns({
    isSelectionMode: true,
    categoryVisible: false,
    classificationVisible: false,
  });

  const {
    data: photos = [],
    isLoading: isPhotosFetching,
  } = useGetAllPhotosQuery(propertyId ? { propertyId } : skipToken);

  return (
    <ManageConnectionsCommonTab
      columns={photosColumns}
      loading={isPhotosFetching || loading}
      rows={photos}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManagePhotosTab;
