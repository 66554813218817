import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  useDeleteNotesMutation,
  useGetNoteWithCache,
} from '../api/api.note';
import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import BasicInfoSection from '../../../components/sections/BasicInfoSection';
import { EMPTY_VALUE } from '../../../../../../../utils/string';
import ViewEntityActions from '../../../components/actions/ViewEntityActions';
import {
  getCreateNotePath,
  getNotesPath,
  getUpdateNotePath,
} from '../navigation.note';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../utils/routes';
import ContentConnections from '../../../components/ContentConnections';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import ContentConnectionsTabContext from '../../../components/ContentConnectionsTabContext';
import { OVERVIEW_TAB_ID } from '../../../constants/constants.content';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import { getMutationFixedCacheKey } from '../../../utils/cacheKeys';
import EntityDetailsLoaderSkeleton from '../../../../../../../components/skeletonLoaders/EntityDetailsLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import HIDTypography from '../../../../../../../components/HIDTypography';
import { ManageContentConnectionsMode } from '../../../components/dialogs/manageContentConnectionsDialog/ManageContentConnectionsDialog';

const ViewNote: FC = () => {
  const { t } = useTranslation(['common', 'notes']);
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();

  const { id: noteId } = useRouteParams<HIDEntityId>();

  const { data: propertyId } = useGetCurrentPropertyId();

  const { note, isLoading } = useGetNoteWithCache({ propertyId, noteId });

  const [deleteNotes] = useDeleteNotesMutation({
    fixedCacheKey: getMutationFixedCacheKey(noteId),
  });

  const noteCreatedAtFormatted = note?.createdAt
    ? formatDate(new Date(note.createdAt), DateTimeFormats.DATE_ONLY)
    : undefined;

  const fields = [
    { label: t('common:name'), value: note?.name || EMPTY_VALUE },
    { label: t('notes:notes_annotation'), value: note?.annotation || EMPTY_VALUE },
    {
      label: t('notes:notes_link'),
      value: note?.url || EMPTY_VALUE,
      isLink: true,
      isLong: true,
    },
    { label: t('common:date'), value: noteCreatedAtFormatted || EMPTY_VALUE },

  ];

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateNotePath, { propertyId }));

  const handleUpdate = () => navigate(getPathWithPropertyIdOrInit(getUpdateNotePath, { propertyId, id: noteId }));

  const handleDelete = () => {
    if (propertyId) {
      deleteNotes({ propertyId, ids: [noteId] })
        .then(() => navigateBackOr(getNotesPath({ propertyId })));
    }
  };

  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);
  const handleManageConnections = () => {
    if (note) {
      openManageConnectionsDialog({
        entityType: EntityType.NOTE,
        mode: {
          type: ManageContentConnectionsMode.ADD,
          entity: note,
        },
      });
    }
  };

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ViewEntityActions
            onAdd={handleAdd}
            onDelete={handleDelete}
            onManageConnections={handleManageConnections}
            onUpdate={handleUpdate}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getNotesPath, { propertyId }),
          name: t('notes:notes_tile'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={[
        <ViewEntityActions
          key={ViewEntityActions.name}
          onAdd={handleAdd}
          onDelete={handleDelete}
          onManageConnections={handleManageConnections}
          onUpdate={handleUpdate}
        />,
      ]}
      title={note?.name}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getNotesPath, { propertyId }))}
    >
      <EntityDetailsLoaderSkeleton EntityPreviewLoader={null} isLoading={isLoading}>
        {note && (
          <ContentConnectionsTabContext
            sourceEntity={note}
            sourceEntityType={EntityType.NOTE}
            tabs={[
              {
                id: OVERVIEW_TAB_ID,
                label: t('common:overview'),
                TabComponent: (
                  <Stack>
                    <HIDTypography variant="h6">
                      {t('notes:notes_data')}
                    </HIDTypography>
                    <BasicInfoSection items={fields} />
                    {note && (
                      <ContentConnections
                        entity={note}
                        entityType={EntityType.NOTE}
                      />
                    )}
                  </Stack>
                ),
              },
            ]}
          />
        )}
      </EntityDetailsLoaderSkeleton>
    </HomeLayout>
  );
};

export default ViewNote;
