import React from 'react';
import { useNavigate } from 'react-router';
import {
  Card,
  Stack,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { CopyAllOutlined } from '@mui/icons-material';
import { ModifyActionType } from '@house-id/houseid-types/dist/common';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import {
  useDeleteDocumentsMutation,
  useGetAllDocumentCategoriesQuery,
  useGetDocumentWithCache,
  useUpdateDocumentMutation,
} from '../../api/api.document';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import DocumentBasicInfoSection from './components/DocumentBasicInfoSection';
import DocumentsQuickNavigation from '../../components/DocumentsQuickNavigation';
import {
  getCreateDocumentPath,
  getDocumentCategoriesPath,
  getDocumentCategoryPath,
  getUpdateDocumentPath,
} from '../../navigation.document';
import { useGetDocumentCategoryName } from '../../utils.document';
import AnnotationSection from '../../../../components/sections/AnnotationSection';
import ViewEntityActions from '../../../../components/actions/ViewEntityActions';
import ContentFileViewer from '../../../../components/ContentFileViewer';
import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../../utils/routes';
import { ContentFile } from '../../../ContentFile/types.contentFile';
import ContentConnections from '../../../../components/ContentConnections';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import ContentConnectionsTabContext from '../../../../components/ContentConnectionsTabContext';
import { OVERVIEW_TAB_ID } from '../../../../constants/constants.content';
import { getCreateReceiptPath } from '../../../Receipt/navigation.receipt';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import { getMutationFixedCacheKey } from '../../../../utils/cacheKeys';
import EntityDetailsLoaderSkeleton from '../../../../../../../../components/skeletonLoaders/EntityDetailsLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import useGetPropertyPermissions from '../../../../../../hooks/useGetPropertyPermissions';
import { ManageContentConnectionsMode } from '../../../../components/dialogs/manageContentConnectionsDialog/ManageContentConnectionsDialog';

const ViewDocument = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();

  const { t } = useTranslation(['common', 'documents']);

  const { isDownMd } = useBreakPointsSizes();

  const {
    id: documentId,
    category: categoryId,
  } = useRouteParams<{ id: string, category: string }>();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [deleteDocuments] = useDeleteDocumentsMutation({
    fixedCacheKey: getMutationFixedCacheKey(documentId),
  });

  const { document, isLoading } = useGetDocumentWithCache({ propertyId, documentId });

  const { data: categories = [] } = useGetAllDocumentCategoriesQuery(propertyId ? { propertyId } : skipToken);

  const categoryName = useGetDocumentCategoryName(categoryId, categories);

  const { data: { canUpdate } = {} } = useGetPropertyPermissions();

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateDocumentPath, { propertyId, categoryId }));
  const handleUpdate = () => navigate(getPathWithPropertyIdOrInit(getUpdateDocumentPath, { propertyId, id: documentId, categoryId }));

  const handleDelete = () => {
    if (propertyId) {
      deleteDocuments({ propertyId, ids: [documentId] })
        .then(() => navigateBackOr(getDocumentCategoryPath({ propertyId, categoryId }), { replace: true }));
    }
  };

  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);
  const handleManageConnections = () => {
    if (document) {
      openManageConnectionsDialog({
        entityType: EntityType.DOCUMENT,
        mode: {
          type: ManageContentConnectionsMode.ADD,
          entity: document,
        },
      });
    }
  };

  const [updateDocument, { isLoading: isUpdatingDocument }] = useUpdateDocumentMutation();

  const handleContentFilesSelected = (contentFiles: Array<ContentFile>) => {
    const newBlobs = contentFiles.map((f) => f.blob);
    if (propertyId && documentId) {
      updateDocument({
        id: documentId,
        propertyId,
        blobs: newBlobs.map((blob) => ({ ...blob, action: ModifyActionType.CREATE })),
      });
    }
  };

  const handleDeleteBlob = (blobId: string) => {
    const blobToDelete = document?.blobs?.find((blob) => blob.id === blobId);

    if (propertyId && blobToDelete) {
      updateDocument({
        id: documentId,
        propertyId,
        blobs: [{ ...blobToDelete, action: ModifyActionType.DELETE }],
      });
    }
  };

  const handleCopyAsReceipt = () => navigate(
    getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId }),
    {
      state: {
        initialData: {
          name: document?.name,
          blobs: document?.blobs,
        },
        connections: [{
          entityType: EntityType.DOCUMENT,
          entityId: document?.id,
        }],
      },
    },
  );

  const customActions = [
    canUpdate && {
      id: 'copy',
      Icon: CopyAllOutlined,
      label: t('documents:documents_view_document_copy_as_receipt'),
      onClick: handleCopyAsReceipt,
    },
  ].filter(Boolean);

  return (
    <HomeLayout
      BodyLoaderSkeleton={EntityDetailsLoaderSkeleton}
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              customActions={customActions}
              onAdd={handleAdd}
              onDelete={handleDelete}
              onManageConnections={handleManageConnections}
              onUpdate={handleUpdate}
            />
          </Card>
          <ContentFileViewer
            showFiles
            blobs={document?.blobs || []}
            isLoading={isLoading}
            isUpdating={isUpdatingDocument}
            variant="side_column"
            onContentFilesSelected={handleContentFilesSelected}
            onDeleteBlob={handleDeleteBlob}
          />
          <Card sx={{ padding: 2 }}>
            <DocumentsQuickNavigation />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getDocumentCategoriesPath, { propertyId }),
          name: t('documents:documents_documents_title'),
        },
        {
          link: getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId, categoryId }),
          name: categoryName,
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={[
        <ViewEntityActions
          customActions={customActions}
          key={ViewEntityActions.name}
          onAdd={handleAdd}
          onDelete={handleDelete}
          onManageConnections={handleManageConnections}
          onUpdate={handleUpdate}
        />,
        <ContentFileViewer
          showFiles
          blobs={document?.blobs || []}
          isLoading={isLoading}
          isUpdating={isUpdatingDocument}
          key={ContentFileViewer.name}
          variant="side_drawer"
          onContentFilesSelected={handleContentFilesSelected}
          onDeleteBlob={handleDeleteBlob}
        />,
      ]}
      title={document?.name}
      onBack={() => navigateBackOr(getPathWithPropertyIdOrInit(getDocumentCategoryPath, { propertyId, categoryId }), { replace: true })}
    >
      {document && (
        <ContentConnectionsTabContext
          sourceEntity={document}
          sourceEntityType={EntityType.DOCUMENT}
          tabs={[
            {
              id: OVERVIEW_TAB_ID,
              label: t('common:overview'),
              TabComponent: (
                <Stack>
                  <ContentFileViewer
                    showImages
                    blobs={document?.blobs || []}
                    isLoading={isLoading}
                    isUpdating={isUpdatingDocument}
                    showFiles={isDownMd}
                    variant="inline"
                    onContentFilesSelected={handleContentFilesSelected}
                    onDeleteBlob={handleDeleteBlob}
                  />
                  <Typography sx={{ marginTop: 4 }} variant="h6">
                    {t('documents:documents_documents_info_label')}
                  </Typography>
                  <Stack spacing={2.5}>
                    <DocumentBasicInfoSection document={document} />
                    <AnnotationSection entity={document} />
                  </Stack>
                  <ContentConnections
                    entity={document}
                    entityType={EntityType.DOCUMENT}
                  />
                </Stack>
              ),
            },
          ]}
        />
      )}
    </HomeLayout>
  );
};

export default ViewDocument;
