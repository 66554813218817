import React, { FC } from 'react';
import {
  Badge,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FeatureStatus } from '@house-id/houseid-types/dist/subscriptionPlans';

import SalesDocuments from '../../../../../assets/images/sales_documents.png';
import HIDClickable from '../../../../../components/HIDClickable';
import HIDImage from '../../../../../components/image/HIDImage';
import Skattio from '../../../../../components/icons/Skattio';
import useGetPropertyDeclarationHelpFeatureAvailable
  from '../../../modules/Finances/modules/Declaration/modules/DeclarationHelp/hooks/useGetPropertyDeclarationHelpFeatureAvailable';

type SellingPropertyTasksListDeductionItemProps = {
  index: number;
  onClick: () => void;
};

const SellingPropertyTasksListDeductionItem: FC<SellingPropertyTasksListDeductionItemProps> = ({
  index,
  onClick,
}) => {
  const { t } = useTranslation(['declarations']);
  const theme = useTheme();

  const { data: declarationHelpFeature } = useGetPropertyDeclarationHelpFeatureAvailable();

  return (
    <HIDClickable
      alignItems="center"
      direction="row"
      spacing={2.5}
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        padding: theme.spacing(2, 2, 1.5),
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.skattio,
        height: '100%',
      }}
      onClick={onClick}
    >
      <Stack
        style={{
          position: 'relative',
          marginBottom: theme.spacing(-1.5),
        }}
      >
        <Badge
          badgeContent={index + 1}
          color="error"
          componentsProps={{
            badge: {
              style: {
                borderRadius: '2px',
              },
            },
          }}
          sx={{
            left: '2px',
            top: '2px',
            position: 'absolute',
          }}
        />
        <Stack
          style={{
            zIndex: 1,
            position: 'absolute',
            top: theme.spacing(-1.25),
            right: theme.spacing(-1.5),
          }}
        >
          <Skattio size={24} />
        </Stack>
        <HIDImage
          externalUrl={SalesDocuments}
          style={{
            width: 48,
            height: 76,
            objectFit: 'cover',
            objectPosition: '100% 0',
          }}
        />
      </Stack>
      <Typography sx={{ flex: 1 }} variant="body1">
        {
          declarationHelpFeature?.status === FeatureStatus.ENABLED || declarationHelpFeature?.unlock?.consumableId
            ? t('declarations:declarations_the_declaration_aid_in_collaboration_with_skattio')
            : t('declarations:declarations_buy_the_declaration_help_with_a_unique_price_from_your_broker')
        }
      </Typography>
      <ChevronRight />
    </HIDClickable>
  );
};

export default SellingPropertyTasksListDeductionItem;
