import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';
import { useGetBuildingsQuery } from '../../../../modules/Building/api/api.building';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../../constants/columns';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import {
  formatDate,
  DateTimeFormats,
} from '../../../../../../../../utils/date';
import { EMPTY_VALUE } from '../../../../../../../../utils/string';
import ContentImage from '../../../ContentImage';
import { BuildingEntity } from '../../../../modules/Building/types.building';

type UseGetBuildingsDataGridColumns = (props: {
  isSelectionMode: boolean,
}) => Array<GridColDef>;

const useGetBuildingsDataGridColumns: UseGetBuildingsDataGridColumns = ({
  isSelectionMode,
}) => {
  const { t } = useTranslation(['common', 'documents']);
  const theme = useTheme();
  const { isDownSm, isDownMd } = useBreakPointsSizes();

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownSm ? 0.6 : 0.4,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name, image } = params?.row as BuildingEntity;
        return (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ minWidth: 0 }}
          >
            <ContentImage
              blobs={image ? [image] : []}
              imageSx={{ maxHeight: theme.spacing(8) }}
              sx={{ marginRight: theme.spacing(1.5) }}
            />
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ minWidth: 0 }}
            >
              <Stack direction="row" sx={{ minWidth: 0 }}>
                <Typography noWrap variant="subtitle1">
                  {name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'area',
      headerName: t('common:area'),
      flex: isDownSm ? 0.3 : 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { area } = params?.row as BuildingEntity;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {area ? `${area} ${t('common:m2')}` : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isDownMd && {
      field: 'updatedAt',
      headerName: t('documents:documents_documents_updated_at_column_name'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { updatedAt } = params?.row as BuildingEntity;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {updatedAt ? formatDate(new Date(updatedAt), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

type ManageBuildingsTabProps = ManageContentConnectionsTabProps & { buildingEntityType: EntityType };

const ManageBuildingsTab: FC<ManageBuildingsTabProps> = ({
  loading,
  selectedItems,
  buildingEntityType,
  onSelect,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const documentsColumns = useGetBuildingsDataGridColumns({
    isSelectionMode: true,
  });

  const {
    data: buildings = [],
    isLoading: isBuildingsFetching,
  } = useGetBuildingsQuery(propertyId ? { propertyId, buildingType: buildingEntityType } : skipToken);

  return (
    <ManageConnectionsCommonTab
      columns={documentsColumns}
      loading={isBuildingsFetching || loading}
      rows={buildings}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManageBuildingsTab;
