import React, {
  FC,
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import {
  Card,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  CircleOutlined,
  CheckRounded,
  FileDownloadOutlined,
  EditOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import {
  useDeletePhotosMutation,
  useGetAllPhotosQuery,
} from '../api/api.photo';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import ListEntitiesActions from '../../../components/actions/ListEntitiesActions';
import ContextMenu from '../../../../../../../components/contextMenu/ContextMenu';
import HIDLink from '../../../../../../../components/HIDLink';
import { Photo } from '../types.photo';
import ListEntitiesToolbarActions, { SelectionModeType } from '../../../components/actions/ListEntitiesToolbarActions';
import HIDImageViewer, { HIDImageViewerRef } from '../../../../../../../components/image/HIDImageViewer/HIDImageViewer';
import {
  getCreatePhotosPath,
  getUpdatePhotosPath,
} from '../navigation.photo';
import PhotosList from '../components/PhotosList';
import { downloadBlobs } from '../../../../../../../utils/download';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import UpdatePhotoDialog from '../components/UpdatePhotoDialog';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../constants/columns';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import {
  formatDate,
  DateTimeFormats,
} from '../../../../../../../utils/date';
import { EMPTY_VALUE } from '../../../../../../../utils/string';
import ContentImage from '../../../components/ContentImage';
import ManageConnections from '../../../../../../../components/icons/ManageConnections';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import { useRouteQueryParams } from '../../../../../../../utils/routes';
import ImageGalleryLoaderSkeleton from '../components/ImageGalleryLoaderSkeleton';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import useSearch from '../../../../../../../hooks/useSearch';
import useGetPropertyPermissions from '../../../../../hooks/useGetPropertyPermissions';
import { ManageContentConnectionsMode } from '../../../components/dialogs/manageContentConnectionsDialog/ManageContentConnectionsDialog';

type UseGetPhotosDataGridColumns = (props: {
  isSelectionMode: boolean,
  classificationVisible: boolean,
  categoryVisible: boolean
}) => Array<GridColDef>;

export const useGetPhotosDataGridColumns: UseGetPhotosDataGridColumns = ({
  isSelectionMode,
}) => {
  const { t } = useTranslation(['common', 'photos', 'documents']);
  const theme = useTheme();
  const { isDownMd } = useBreakPointsSizes();

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 0.4,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name, blobs } = params?.row as Photo;
        return (
          <Stack
            alignItems="center"
            direction="row"
            sx={{ minWidth: 0 }}
          >
            <ContentImage
              blobs={blobs}
              imageSx={{ maxHeight: theme.spacing(8) }}
              sx={{ marginRight: theme.spacing(1.5) }}
            />
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ minWidth: 0 }}
            >
              <Stack direction="row" sx={{ minWidth: 0 }}>
                <Typography noWrap variant="subtitle1">
                  {name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
    !isDownMd && {
      field: 'place',
      headerName: t('photos:photo_location'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { place } = params?.row as Photo;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {place || EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isDownMd && {
      field: 'date',
      headerName: t('photos:enter_date'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { date } = params?.row as Photo;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {date ? formatDate(new Date(date), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: t('documents:documents_documents_updated_at_column_name'),
      flex: 0.15,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { updatedAt } = params?.row as Photo;
        return (
          <Typography noWrap sx={{ color: theme.palette.grey[500] }} variant="body2">
            {updatedAt ? formatDate(new Date(updatedAt), DateTimeFormats.DATE_ONLY) || EMPTY_VALUE : EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

enum SortingOrder {
  NAME_ASCENDING = 'NAME_ASCENDING',
  NAME_DESCENDING = 'NAME_DESCENDING',
  LATEST = 'LATEST',
  OLDEST = 'OLDEST',
}

const SortingOrderComparatorMap: Record<SortingOrder, (a: Photo, b: Photo) => R.Ordering> = {
  [SortingOrder.NAME_ASCENDING]: R.ascend<Photo>(R.prop('name')),
  [SortingOrder.NAME_DESCENDING]: R.descend<Photo>(R.prop('name')),
  [SortingOrder.LATEST]: R.descend<Photo>(R.prop('updatedAt')),
  [SortingOrder.OLDEST]: R.ascend<Photo>(R.prop('updatedAt')),
};

const Photos: FC = () => {
  const { t } = useTranslation(['common', 'photos']);
  const theme = useTheme();
  const navigate = useNavigate();
  const searchParams = useRouteQueryParams<{ photoId?: string }>();
  const { photoId } = searchParams;

  const [sortingOrder, setSortingOrder] = useState(SortingOrder.LATEST);
  const [selectionModeType, setSelectionModeType] = useState<SelectionModeType | undefined>();
  const [isGalleryMode, setIsGalleryMode] = useState(false);
  const [selectedPhotoIds, setSelectedPhotoIds] = useState<Array<string>>([]);
  const [updatePhoto, setUpdatePhoto] = useState<Photo | undefined>();

  const { data: { canCreate, canUpdate } = {} } = useGetPropertyPermissions();

  const { displaySearch } = useSearch(EntityType.PHOTO);

  const imageGalleryRef = useRef<HIDImageViewerRef | null>(null);

  const isSelectionMode = Boolean(selectionModeType);

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: photos = [], isFetching, isLoading } = useGetAllPhotosQuery(propertyId ? { propertyId } : skipToken);

  const [deletePhotos, { isLoading: isDeletingPhotos }] = useDeletePhotosMutation();

  const sortedPhotos = useMemo(
    () => R.pipe(
      R.filter((photo: Photo) => photo.blobs?.length > 0),
      R.sortWith([SortingOrderComparatorMap[sortingOrder]]),
    )(photos),
    [sortingOrder, photos],
  );

  const handleOpen = (index: number) => imageGalleryRef.current?.fullScreen(index);

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreatePhotosPath, { propertyId }));

  const handleEnterSelectionMode = (selectionModeType: SelectionModeType) => {
    setSelectionModeType(selectionModeType);
    setSelectedPhotoIds([]);
  };

  const handleDelete = async () => {
    setSelectionModeType(undefined);

    if (propertyId) {
      await deletePhotos({ propertyId, ids: selectedPhotoIds });
    }

    setSelectedPhotoIds([]);
  };

  const handleUpdate = () => {
    setSelectionModeType(undefined);

    if (propertyId) {
      navigate(getUpdatePhotosPath({ propertyId, ids: selectedPhotoIds }));
    }

    setSelectedPhotoIds([]);
  };

  const handleDownload = () => {
    const blobs = photos
      .filter((photo) => selectedPhotoIds.includes(photo.id))
      .map((photo) => R.head(photo.blobs))
      .filter(Boolean);
    downloadBlobs(blobs);

    setSelectionModeType(undefined);
    setSelectedPhotoIds([]);
  };

  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);
  const handleManageConnections = (photo: Photo) => {
    openManageConnectionsDialog({
      entityType: EntityType.PHOTO,
      mode: {
        type: ManageContentConnectionsMode.ADD,
        entity: photo,
      },
    });
  };

  const toggleSelection = (selected: boolean, photoId: string) =>
    setSelectedPhotoIds(
      selected
        ? R.without([photoId], selectedPhotoIds)
        : R.append(photoId, selectedPhotoIds),
    );

  const isPhotoDeleting = (photo: Photo) => isDeletingPhotos && selectedPhotoIds.includes(photo.id);

  const galleryDisplay = isGalleryMode ? 'unset' : 'none';

  useEffect(() => {
    if (photoId && photos.length) {
      const photoIndex = photos.findLastIndex(({ id }) => id === photoId);
      if (photoIndex !== -1) {
        handleOpen(photoIndex);
      }
    }
  }, [photoId, photos]);

  const customActions = [
    {
      id: 'download',
      Icon: FileDownloadOutlined,
      label: t('common:download_label'),
      disabled: isSelectionMode,
      onClick: () => handleEnterSelectionMode(SelectionModeType.DOWNLOAD),
    },
  ];

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ListEntitiesActions
            customActions={customActions}
            disabled={isSelectionMode}
            onAdd={handleAdd}
            onDelete={() => handleEnterSelectionMode(SelectionModeType.DELETE)}
            onSearch={displaySearch}
            onUpdate={() => handleEnterSelectionMode(SelectionModeType.UPDATE)}
          />
        </Card>
      }
      isLoading={isLoading}
      sideDrawerElements={[
        <ListEntitiesActions
          customActions={customActions}
          disabled={isSelectionMode}
          key={ListEntitiesActions.name}
          onAdd={handleAdd}
          onDelete={() => handleEnterSelectionMode(SelectionModeType.DELETE)}
          onSearch={displaySearch}
          onUpdate={() => handleEnterSelectionMode(SelectionModeType.UPDATE)}
        />,
      ]}
      title={t('photos:image_gallery')}
    >
      <ListEntitiesToolbarActions
        SortingComponent={
          <ContextMenu
            Button={({ open, onClick }) => (
              <HIDLink
                Icon={open ? ExpandLess : ExpandMore}
                label={t(`common:sorting_order_${sortingOrder}`)}
                sx={{ color: theme.palette.common.black }}
                underline="none"
                onClick={onClick}
              />
            )}
            listItems={
              Object.values(SortingOrder)
                .map((sortingOrderItem) => ({
                  id: sortingOrderItem,
                  label: t(`common:sorting_order_${sortingOrderItem}`),
                  selected: sortingOrderItem === sortingOrder,
                  onClick: () => setSortingOrder(sortingOrderItem),
                }))
            }
          />
        }
        count={sortedPhotos.length}
        entity={EntityType.PHOTO}
        isFetching={isFetching}
        isLoading={isLoading}
        selectedCount={selectedPhotoIds.length}
        selectionModeType={selectionModeType}
        onCancel={() => setSelectionModeType(undefined)}
        onDelete={handleDelete}
        onDownload={handleDownload}
        onUpdate={handleUpdate}
      />
      <HIDImageViewer
        isFullScreen
        filePickerSx={{ display: galleryDisplay }}
        images={sortedPhotos.flatMap((photo) => photo.blobs)}
        key={sortedPhotos.length}
        ref={imageGalleryRef}
        sx={{ display: galleryDisplay }}
        onScreenChange={setIsGalleryMode}
      />
      {updatePhoto !== undefined && (
        <UpdatePhotoDialog
          open={Boolean(updatePhoto)}
          photo={updatePhoto}
          onClose={() => setUpdatePhoto(undefined)}
        />
      )}
      <ImageGalleryLoaderSkeleton isLoading={isLoading}>
        <PhotosList
          photos={sortedPhotos}
          renderIcon={(photo) => {
            const selected = selectedPhotoIds.includes(photo.id);
            const Icon = selected ? CheckRounded : CircleOutlined;
            return isSelectionMode
              ? (
                <Icon
                  sx={{
                    position: 'absolute',
                    top: theme.spacing(1),
                    right: theme.spacing(1),
                    borderRadius: theme.spacing(1.5),
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: selected ? theme.palette.primary.main : theme.palette.grey[400],
                    color: theme.palette.common.white,
                    backgroundColor: selected ? theme.palette.primary.main : theme.palette.common.white,
                    pointerEvents: 'none',
                  }}
                />
              )
              : isPhotoDeleting(photo)
                ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: theme.spacing(1),
                      right: theme.spacing(1),
                    }}
                  />
                )
                : (
                  <Stack
                    spacing={0.5}
                    sx={{
                      position: 'absolute',
                      top: theme.spacing(0.5),
                      right: theme.spacing(0.5),
                    }}
                  >
                    {canUpdate && (
                      <HIDIconButton
                        Icon={EditOutlined}
                        color="alternate"
                        size="small"
                        onClick={(event) => {
                          event.nativeEvent.stopImmediatePropagation();
                          setUpdatePhoto(photo);
                        }}
                      />
                    )}
                    {canCreate && canUpdate && (
                      <HIDIconButton
                        Icon={() => <ManageConnections iconColor={theme.palette.common.white} size={20} />}
                        color="alternate"
                        size="small"
                        onClick={(event) => {
                          event.nativeEvent.stopImmediatePropagation();
                          handleManageConnections(photo);
                        }}
                      />
                    )}
                  </Stack>
                );
          }}
          onClick={
            (photo, index) => isSelectionMode
              ? toggleSelection(selectedPhotoIds.includes(photo.id), photo.id)
              : handleOpen(index)
          }
        />
      </ImageGalleryLoaderSkeleton>
    </HomeLayout>
  );
};

export default Photos;
