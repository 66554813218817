import React, { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';

import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { useGetAllNotesQuery } from '../../../../modules/Note/api/api.note';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';
import { useGetNotesDataGridColumns } from '../../../../modules/Note/pages/Notes';
import ManageConnectionsCommonTab from './ManageConnectionsCommonTab';

const ManageNotesTab: FC<ManageContentConnectionsTabProps> = ({
  loading,
  selectedItems,
  onSelect,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const notesColumns = useGetNotesDataGridColumns({
    isSelectionMode: true,
    annotationVisible: true,
    linkVisible: true,
  });

  const {
    data: notes = [],
    isLoading: isNotesFetching,
  } = useGetAllNotesQuery(propertyId ? { propertyId } : skipToken);

  return (
    <ManageConnectionsCommonTab
      columns={notesColumns}
      loading={isNotesFetching || loading}
      rows={notes}
      selectedItems={selectedItems}
      onSelect={onSelect}
    />
  );
};

export default ManageNotesTab;
