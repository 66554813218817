import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import SalesDocuments from '../../../../../../../../../assets/images/sales_documents.png';
import { FCC } from '../../../../../../../../../types/common';
import HIDImage from '../../../../../../../../../components/image/HIDImage';

const SkattioDeclarationBanner: FCC = ({
  sx,
  style,
}) => {
  const theme = useTheme();

  return (
    <Stack
      style={{
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        background: theme.palette.common.skattio,
        width: 130,
        height: 130,
        overflow: 'hidden',
        ...style,
      }}
      sx={sx}
    >
      <HIDImage
        externalUrl={SalesDocuments}
        style={{
          width: 76,
          height: 144,
          objectFit: 'cover',
          objectPosition: '100% 0',
          marginTop: 72,
        }}
      />
    </Stack>
  );
};

export default SkattioDeclarationBanner;
