import {
  SubscriptionPlanId,
  SubscriptionPlanPeriodOption,
  SubscriptionPlanPeriodOptionOffer,
  SubscriptionPlanOptionProviderData,
  SubscriptionPlanDuration,
  SubscriptionPlatform,
  SubscriptionType,
} from '@house-id/houseid-types/dist/subscriptionPlans';

export enum SubscriptionPlanOfferPeriodType {
  YEAR = 'year',
  MONTH = 'month',
}

export type SubscriptionPlanOfferByPeriod = {
  planId: SubscriptionPlanId;
  periods: Record<SubscriptionPlanOfferPeriodType, SubscriptionPlanPeriodOption>;
};

export type SubscriptionPlanOptionPeriodOfferPrices = {
  planId: SubscriptionPlanId;
  period: SubscriptionPlanOfferPeriodType;
  offer?: SubscriptionPlanPeriodOptionOffer;
  basePricePerMonth: number;
  currentPricePerMonth: number;
  basePricePerYear: number;
  currentPricePerYear: number;
  providerData?: SubscriptionPlanOptionProviderData;
  canActivate: boolean;
};

export type SubscriptionHistoryItem = {
  id: string;
  planId?: SubscriptionPlanId;
  amount?: number;
  type: SubscriptionType;
  platform: SubscriptionPlatform;
  productId: string;
  purchaseDate: string;
  subscriptionId: string;
  purchaseType: SubscriptionPurchaseType;
  duration: SubscriptionPlanDuration;
};

enum SubscriptionPurchaseType {
  RENEW = 'renew',
  NEW = 'new',
}
