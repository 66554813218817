import {
  FeatureStatus,
  SubscriptionFeature,
  UserFeatureValue,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import { useGetCurrentSubscriptionPlanQuery } from '../../../../../../../../SubscriptionPlans/api/api.subscriptionPlans';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { QueryHook } from '../../../../../../../../../types/common';

type UseGetPropertyDeclarationHelpFeatureAvailable = QueryHook<UserFeatureValue | undefined>;
const useGetPropertyDeclarationHelpFeatureAvailable: UseGetPropertyDeclarationHelpFeatureAvailable = () => {
  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: subscriptionPlan, ...rest } = useGetCurrentSubscriptionPlanQuery();

  const propertyFeature = subscriptionPlan?.properties.find(({ id }) => id === propertyId)?.declarationHelp;
  const availableFeature = subscriptionPlan?.me?.[SubscriptionFeature.NOT_SPENT_DECLARATION_HELP_CONSUMABLE];
  const result = propertyFeature
    ? {
      status: propertyFeature.status === FeatureStatus.ENABLED ? FeatureStatus.ENABLED : availableFeature?.status || FeatureStatus.DISABLED,
      unlock: availableFeature?.unlock,
    } : undefined;

  return {
    data: result,
    ...rest,
  };
};
export default useGetPropertyDeclarationHelpFeatureAvailable;
