import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import {
  FCC,
  Icon,
} from '../types/common';
import { ICON_SIZE } from '../constants/layout';

type CircleIconProps = {
  size?: number;
  iconSize?: number;
  backgroundColor?: string;
  iconColor?: string;
  Icon: Icon
};

const CircleIcon: FCC<CircleIconProps> = ({
  size = ICON_SIZE * 2,
  iconSize = ICON_SIZE,
  backgroundColor: backgroundColorProp,
  iconColor,
  Icon,
  sx,
}) => {
  const theme = useTheme();

  const backgroundColor = backgroundColorProp || theme.palette.primary.lighter;
  return (
    <Stack
      sx={
        {
          height: size,
          width: size,
          borderRadius: size / 2,
          backgroundColor,
          justifyContent: 'center',
          alignItems: 'center',
          ...sx,
        }
      }
    >
      <Icon iconColor={iconColor} size={iconSize} />
    </Stack>
  );
};

export default CircleIcon;
