import React from 'react';
import {
  useTheme,
  Card,
  Stack,
} from '@mui/material';

import { FCC } from '../../../../../../../../../../../types/common';
import { BorderRadius } from '../../../../../../../../../../../constants/borderRadius';

type DeductionCardContainerProps = {
  // Define your props here
};

const DeductionCardContainer: FCC<DeductionCardContainerProps> = ({ children, sx }) => {
  const theme = useTheme();

  return (
    <Card>
      <Stack
        gap={2.5}
        sx={{
          borderRadius: BorderRadius.md,
          borderWidth: 1,
          borderColor: theme.palette.grey[200],
          ...sx,
        }}
      >
        {children}
      </Stack>
    </Card>
  );
};

export default DeductionCardContainer;
