import { DeductionAmountType } from '@house-id/houseid-types/dist/deductions';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

const useGetDeductionAmountTypeLabel = () => {
  const { t } = useTranslation(['declarations', 'deductions']);

  const getDeductionResultTypeLabel = (type: DeductionAmountType) => match(type)
    .with(DeductionAmountType.PURCHASE_EXPENSE, () => t('declarations:declarations_deduction_amount'))
    .with(DeductionAmountType.SALES_EXPENSE, () => t('declarations:declarations_deduction_amount'))
    .with(DeductionAmountType.IMPROVEMENT, () => t('deductions:basic_improvement'))
    .with(DeductionAmountType.REPAIR_AND_MAINTENANCE, () => t('declarations:declarations_declaration_your_deductions_repair_maintenance'))
    .exhaustive();

  return getDeductionResultTypeLabel;
};

export default useGetDeductionAmountTypeLabel;
