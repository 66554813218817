import React, { FC } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import HIDDataGrid from '../../../../../../pages/Home/components/HIDDataGrid';
import { ManageContentConnectionsTabProps } from '../types.manageContentConnectionsDialog';

type ManageConnectionsCommonTabProps = ManageContentConnectionsTabProps & {
  columns: GridColDef[];
  rows: GridValidRowModel[];
  loading?: boolean;
  getRowId?: (row: any) => string;
};

const ManageConnectionsCommonTab: FC<ManageConnectionsCommonTabProps> = ({
  columns,
  rows,
  loading,
  selectedItems,
  getRowId,
  onSelect,
}) => {
  const handleSelect = (rowSelectionModel: GridRowSelectionModel) => {
    if (!loading) {
      onSelect(rowSelectionModel as Array<string>);
    }
  };

  const sortedBySelection: Array<GridColDef> = [
    {
      field: 'selected',
      type: 'string',
      headerName: '',
      valueGetter: (params: GridRenderCellParams) => selectedItems.includes((params?.row as HIDEntityId).id).toString(),
      renderCell: () => null,
    },
    ...columns,
  ];

  return (
    <HIDDataGrid
      isSelectionMode
      columnVisibilityModel={{
        selected: false,
      }}
      columns={sortedBySelection}
      getRowId={getRowId}
      initialState={{
        columns: {
          columnVisibilityModel: {
            selected: false,
          },
        },
        sorting: {
          sortModel: [
            {
              field: 'selected',
              sort: 'desc',
            },
            {
              field: 'updatedAt',
              sort: 'desc',
            },
          ],
        },
      }}
      isLoading={loading}
      rowSelectionModel={selectedItems}
      rows={rows}
      onRowSelectionModelChange={handleSelect}
    />
  );
};

export default ManageConnectionsCommonTab;
