import React from 'react';
import {
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { CustomTimelineEvent } from '../types.timeline';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import { LIST_CHEVRON_COLUMN_CONFIG } from '../../../../../../../constants/columns';
import { EMPTY_VALUE } from '../../../../../../../utils/string';

type UseGetTimelineEntriesDataGridColumns = (props: {
  isSelectionMode: boolean,
}) => Array<GridColDef>;

const useGetTimelineEntriesDataGridColumns: UseGetTimelineEntriesDataGridColumns = ({
  isSelectionMode,
}) => {
  const { t } = useTranslation(['common', 'timeline', 'notes']);
  const { isDownSm } = useBreakPointsSizes();

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: t('common:name'),
      flex: isDownSm ? 0.6 : 0.3,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { title } = params?.row as CustomTimelineEvent;
        return (
          <Typography noWrap variant="body2">
            {title || EMPTY_VALUE}
          </Typography>
        );
      },
    },
    !isDownSm && {
      field: 'annotation',
      headerName: t('common:annotation'),
      flex: 0.5,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { annotation } = params?.row as CustomTimelineEvent;
        return (
          <Typography noWrap variant="body2">
            {annotation || EMPTY_VALUE}
          </Typography>
        );
      },
    },
    {
      field: 'date',
      headerName: t('notes:notes_last_updated_at'),
      flex: isDownSm ? 0.4 : 0.2,
      type: 'string',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { start } = params?.row as CustomTimelineEvent;
        return (
          <Typography noWrap variant="body2">
            {formatDate(new Date(start), DateTimeFormats.DATE_ONLY)}
          </Typography>
        );
      },
    },
    !isSelectionMode && LIST_CHEVRON_COLUMN_CONFIG,
  ].filter(Boolean);

  return columns;
};

export default useGetTimelineEntriesDataGridColumns;
